import dataProvider from '../core/dataProvider';
import { actorGetActionValue, actorSetActionValue } from '../type/actor-setup';
import { showNotification } from './general-function-helper';

import type { ActionPrintInterface, MetaData, SimplePrintInterface } from './Types';
import { getValue, SESSION_ID, SUB_DOMAIN } from '../core/configProvider';
import { isEmpty } from './data-helper';
import { apiUrl, GET_META } from '../core/data-Provider.helper';

/**
 * it will create a new promise and pass resolve and reject functions to get meta in meta context
 * then execute one of them when `getMeta` function completed (success or failure)
 * @function getPrintMeta
 * @param {Object} print
 * @returns {Promise<Object>}
 */
const getPrintMeta = async (printResource: string): Promise<void | string> => {
  return new Promise((resolve, reject) => {
    const prevMetaData = actorGetActionValue('metaData')?.[printResource];

    if (prevMetaData) resolve();

    dataProvider(GET_META, printResource, {})
      .then((metaData: [MetaData]) => {
        if (!metaData?.[0]) reject('meta data not found');
        actorSetActionValue('metaData', metaData[0], {
          path: printResource,
        });

        resolve();
      })
      .catch((error: string) => {
        reject(error);
      });
  });
};

/**
 * get print meta data then call get print data function
 * @function openPrintProcess
 * @param { SimplePrintInterface | ActionPrintInterface } print
 * @param { string | string[] } filters
 * @returns {void} void
 */
export const openPrintProcess = (
  print: SimplePrintInterface | ActionPrintInterface,
  filters?: Record<string, unknown>[],
): void => {
  let printId: string | undefined;
  let additionalQueryString: string | undefined;

  // FIXME: We have to check normal report(i.e. non-service reports, we don't have this test case already)
  // if ('id' in print || 'reportId' in print) {
  //   // prettier-ignore
  //   printId = (print as SimplePrintInterface).id ?? (print as SimplePrintInterface).reportId;
  // }

  if ('printUniqueId' in print) {
    printId = (print as ActionPrintInterface).printUniqueId;

    if (!isEmpty((print as ActionPrintInterface).report)) {
      // print.report: "/report/34efa142-f92b-4eef-868f-12755fe289f0?recordId=137&putcaptioninheader=true"
      const reportDataSections = print.report.split('?');
      if (Array.isArray(reportDataSections) && reportDataSections.length === 2) {
        additionalQueryString = reportDataSections[1]; // recordId=137&putcaptioninheader=true
      }
    }
  }

  if ('withPreview' in print) {
    const withPreview = (print as ActionPrintInterface).withPreview;
    additionalQueryString += `&withPreview=${withPreview}`;
  }

  if (!printId) {
    console.warn('`openPrintPreview` Invalid print data => ', { print, filters });
    return;
  }

  const printResource = `print/${printId}`;

  getPrintMeta(printResource)
    .then(() => {
      openPrintInPopup(printId!, filters, additionalQueryString);
    })
    .catch(printMetaError => {
      showNotification(printMetaError, 'error', { forceSnackbar: true });
    });
};

/**
 * get print meta data then call get print data function
 * @function openPrintInPopup
 * @param { string } printResource
 * @param { Record<string, unknown> } filterValues
 * @returns {void}
 */
export const openPrintInPopup = (
  printResource: string,
  filterValues?: Record<string, unknown>[],
  additionalQueryString?: string,
): void => {
  const userSessionId = getValue(SESSION_ID);
  const company = getValue(SUB_DOMAIN);

  let src = `${apiUrl}/oauth2/${userSessionId}/v2/print/${printResource}/report`;

  if (Array.isArray(filterValues)) {
    src += `?filters=${JSON.stringify(filterValues)}`;
  }

  if (!isEmpty(additionalQueryString)) {
    if (src.includes('/report?')) {
      src += `&${additionalQueryString}`;
    } else {
      src += `?${additionalQueryString}`;
    }
  }

  // add company
  if (!isEmpty(company)) {
    if (src.includes('/report?')) {
      src += `&company=${company}`;
    } else {
      src += `?company=${company}`;
    }
  }

  if (!isEmpty(additionalQueryString)) {
    src += `&${additionalQueryString}`;
  }

  const newTab = window.open(
    src,
    '_blank',
    'width=800,height=600,scrollbars=1,resizable=1',
  );

  if (!newTab) {
    alert('print.pleaseGiveBrowserAccessToOpenPopup');
  }
};
