import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    padding: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },

  tabParent: {
    margin: '0 10px 0',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.appPrimaryBackgroundColor,
    alignSelf: 'center',
    margin: '5px 7px',
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  iconInGrid: { cursor: 'pointer' },
  containerAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',

    '& > fieldset': {
      flexBasis: '40%',
    },
  },
  advanceText: {
    fontWeight: 700,
    fontSize: 12,
  },
  styleButtonAdvancePermission: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionPermissions: {
    height: '5%',
    marginBottom: 10,
    zIndex: 9999,
  },
  filterContainer: {
    height: '90%',
    maxHeight: '90%',
  },
}));
