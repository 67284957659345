import { ReactElement, useCallback } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import { useTheme } from '@material-ui/core';
import lodashGet from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import appVersion from '../../../core/version';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkspacesOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';

import ImportantDashboard from '../important-dashboard/important-dashboard.controller';
import clx from 'classnames';
import SpeedIcon from '@material-ui/icons/Speed';

import { useStyles } from './sidebar-menu.style';
import useWidth from '../../useWidth';
import { ModuleItem } from '../module-item';
import { MenuAccordion } from '../menu-accordion';
import MenuSearchList from '../MenuSearchList';
import { MenuHeader } from '../menu-header';
import { TODO } from '../../../core/configRouteConstant';
import { getTodoListPatternInfo } from '../../../helper/PatternMetaHelper';
import { actorDispatch, actorGetActionValue } from '../../../type/actor-setup';
import { customSort, isEmptyObject } from '../../../helper/data-helper';

import {
  ShowMenuInListInterface,
  SidebarMenuViewInterface,
} from './sidebar-menu.type';

const SidebarMenuView = (props: SidebarMenuViewInterface): ReactElement => {
  const {
    isDrawerOpen,
    getSelectedMenuLabel,
    backClickHandler,
    selectedList,
    searchInMenu,
    menuIsLoading,
    isActiveItem,
    menuList,
    searchMenuWord,
    childMenuSelectHandler,
    favoriteItems,
    parentMenuSelectHandler,
    recents,
    onDeleteFromFavoriteItemsHandler,
    isInfavoriteItems,
    isModuleSelected,
    onSelectParentHandler,
    onSelectModuleItemHandler,
    onToggleFavoriteItemsHandler,
    onFavoriteClicked,
    onRecentClicked,
    selectedMenuChildren,
    closeHandleSelect,
    userLogout,
    isMenuScrolling,
    handleMenuScroll,
    handleMenuLeaveScroll,
    dashboards,
  } = props;
  const classes = useStyles({ isDrawerOpen, isMenuScrolling });
  const width = useWidth();
  const locale = useLocale();
  const theme = useTheme();
  const translate = useTranslate();

  const ForwardArrow =
    theme.direction === 'rtl' ? KeyboardArrowLeft : KeyboardArrowRight;

  const showMenuInList = useCallback<ShowMenuInListInterface>(
    (
      menu,
      clickFunc,
      className = undefined,
      linkClassName = undefined,
      Icon = undefined,
    ) => {
      const title = lodashGet(menu, ['translatedTitle', locale], menu.title);
      const { moduleName, moduleTableName, reportId, url, pageName, id } = menu;
      if (menu && menu.type === 'dashboard') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/menu/${id}`}
            target={menu?.['linkTarget'] ? menu['linkTarget'] : '_self'}
            rel="noopener noreferrer"
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="urlSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu.type === 'tableQuickAccess') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/menu/${id}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="menuLinkSideBar"
          >
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu && pageName === 'Tree') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/tree/${moduleName}/${moduleTableName}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="treeSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu && pageName === 'SingleRecord') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/single-record/${moduleName}/${moduleTableName}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="singleSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu && pageName === 'ParentReport') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/multi-report/${reportId}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="parentReportSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (moduleName && moduleTableName) {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/${moduleName}/${moduleTableName}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="moduleSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (reportId) {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/report/${reportId}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="reportSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (url) {
        if (menu.type === 'iFrame') {
          return (
            <a
              className={className ? className : classes.linkTitle}
              href={`#/menu/${id}`}
              onClick={closeHandleSelect}
              key={`${id}_link`}
              id="menuTypeSideBar"
            >
              {Icon && Icon}
              <span
                className={linkClassName ? linkClassName : ''}
                key={`${id}_title`}
              >
                {title}
              </span>
            </a>
          );
        }
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={url}
            target={menu?.['linkTarget'] ? menu['linkTarget'] : '_self'}
            rel="noopener noreferrer"
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="urlSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu.type === 'page' && pageName && pageName === 'menuPage') {
        return (
          <a
            className={className ? className : classes.linkTitle}
            href={`#/menu/${id}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="menuTypeSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else if (menu.type === 'page' && pageName && pageName === 'todo') {
        const { reportTaskDetailsResource } = getTodoListPatternInfo('task/list');
        return (
          <a
            data-test-todo-menu-link
            className={className ? className : classes.linkTitle}
            href={`#/${TODO}/task/list?detail=${reportTaskDetailsResource}`}
            onClick={closeHandleSelect}
            key={`${id}_link`}
            id="menuLinkSideBar"
          >
            {Icon && Icon}
            <span className={linkClassName ? linkClassName : ''} key={`${id}_title`}>
              {title}
            </span>
          </a>
        );
      } else {
        if (!menu.children || !menu.children.length) {
          return null;
        }

        return (
          <div
            className={classes.linkTitle}
            onClick={() => {
              if (menu.children && menu.children.length) {
                clickFunc(id);
              }
            }}
            key={`${id}_title`}
          >
            {title}
          </div>
        );
      }
    },
    [locale, classes],
  );

  if (menuIsLoading) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  if (!menuIsLoading && !menuList) {
    return <div className={classes.spinner}>{translate('menu.menuNotFound')}</div>;
  }

  return (
    <div className={classes.maincontainer}>
      <MenuHeader
        selectedMenuLabel={getSelectedMenuLabel()}
        backClickHandler={backClickHandler}
        selectedList={selectedList}
        searchInMenu={searchInMenu}
        width={width}
        isDrawerOpen={isDrawerOpen}
      />
      {searchMenuWord.length > 0 ? (
        <MenuSearchList
          menuArray={menuList}
          searchMenuWord={searchMenuWord}
          showMenuInList={showMenuInList}
          childMenuSelectHandler={childMenuSelectHandler}
        />
      ) : (
        <div className={classes.innerparent}>
          <div className={classes.menucart}>
            <ul
              className={classes.menulist}
              onMouseEnter={handleMenuScroll}
              onMouseLeave={handleMenuLeaveScroll}
              id="mainSideBar"
            >
              <MenuAccordion
                // key="fav"
                containerClassName={classes.accordionlistLiContainer}
                iconClassName={classes.menuIcon}
                titleClassName={classes.menucontent}
                title={translate('menu.favorites')}
                Icon={<StarOutlineIcon className={classes.menuIcon} />}
                isArrowShow={isDrawerOpen}
                onClick={onFavoriteClicked}
              >
                {isDrawerOpen && (
                  <div style={{ padding: '.5rem 3rem' }}>
                    {favoriteItems &&
                      customSort(favoriteItems, 'title').map(menu => {
                        const menuLink = showMenuInList(
                          menu,
                          parentMenuSelectHandler,
                        );
                        if (!menuLink) {
                          return null;
                        }

                        return (
                          <ModuleItem
                            dataTest="fav-item"
                            key={`${menu.id}_fav`}
                            title={menuLink}
                            isFavorite={true}
                            onTitleClick={() =>
                              actorDispatch('isChildMenuOpen', false)
                            }
                            onStarClick={() =>
                              onDeleteFromFavoriteItemsHandler(menu.id)
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </MenuAccordion>
              {!actorGetActionValue('pinedDashboard')?.hasError && (
                <MenuAccordion
                  // key="fav"
                  containerClassName={classes.accordionlistLiContainer}
                  iconClassName={classes.menuIcon}
                  titleClassName={classes.menucontent}
                  title={translate('menu.importantDashboards')}
                  Icon={<SpeedIcon className={classes.menuIcon} />}
                  isArrowShow={isDrawerOpen}
                >
                  {isDrawerOpen && (
                    <div className={classes.layerInMenu}>
                      {Array.isArray(dashboards) &&
                        dashboards.map(data => {
                          return (
                            <ImportantDashboard
                              title={data.dashname}
                              id={data.uniqueid}
                              url={data?.url}
                            />
                          );
                        })}
                    </div>
                  )}
                </MenuAccordion>
              )}
              <MenuAccordion
                key="recent"
                containerClassName={classes.accordionlistLiContainer}
                iconClassName={classes.menuIcon}
                titleClassName={classes.menucontent}
                title={translate('menu.recent')}
                Icon={<AccessTimeIcon className={classes.menuIcon} />}
                isArrowShow={isDrawerOpen}
                onClick={onRecentClicked}
              >
                {isDrawerOpen && (
                  <div style={{ padding: '.5rem 3rem' }}>
                    {recents &&
                      recents.map(menu => {
                        const menuLink = showMenuInList(
                          menu,
                          parentMenuSelectHandler,
                        );
                        if (!menuLink) {
                          return null;
                        }
                        return (
                          <ModuleItem
                            key={`${menu.id}_recent`}
                            dataTest="recent-item"
                            isFavorite={isInfavoriteItems(menu.id)}
                            title={menuLink}
                            onTitleClick={() =>
                              actorDispatch('isChildMenuOpen', false)
                            }
                            onStarClick={() =>
                              onToggleFavoriteItemsHandler(undefined, menu)
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </MenuAccordion>
              {!isDrawerOpen ? (
                <Tooltip
                  title={translate('menu.module')}
                  aria-label={translate('menu.module')}
                  placement="left-start"
                  arrow
                >
                  <li
                    key="module"
                    data-test-menu-id="module"
                    className={clx(
                      classes.modulelistLiContainer,
                      isModuleSelected && 'active',
                    )}
                    onClick={onSelectModuleItemHandler}
                    data-style-drawer-cloes="drawerCloes"
                  >
                    <WorkspacesOutlinedIcon className={classes.menuIcon} />
                    <span className={classes.menucontent}>
                      {"translate('menu.module')"}
                    </span>
                  </li>
                </Tooltip>
              ) : (
                <li
                  key="module"
                  data-test-menu-id="module"
                  className={clx(
                    classes.modulelistLiContainer,
                    isModuleSelected && 'active',
                  )}
                  onClick={onSelectModuleItemHandler}
                  data-style-drawer-open="drawerOpen"
                >
                  <WorkspacesOutlinedIcon className={classes.menuIcon} />
                  <span className={classes.menucontent}>
                    {translate('menu.module')}
                  </span>
                </li>
              )}

              {menuList.map(menu => {
                const menuLink = showMenuInList(
                  menu,
                  parentMenuSelectHandler,
                  //@ts-ignore
                  clx(
                    classes.modulelistLiContainer,
                    isActiveItem(menu.id) && 'active',
                  ),
                  classes.menucontent,
                  <Icon className={classes.menuIcon} key={`${menu.id}_layerIcon`}>
                    {menu.icon ? menu.icon : 'layers'}
                  </Icon>,
                );
                if (!menuLink) {
                  return null;
                }
                return !isDrawerOpen ? (
                  <Tooltip
                    title={lodashGet(menu, ['translatedTitle', locale], menu.title)}
                    aria-label={lodashGet(
                      menu,
                      ['translatedTitle', locale],
                      menu.title,
                    )}
                    placement="left-start"
                    arrow
                    key={`${menu.id}_tooltip`}
                  >
                    {!isEmptyObject(menu.children) &&
                    menu.pageName !== 'todo' &&
                    menu.pageName !== 'menuPage' ? (
                      <li
                        onClick={() => onSelectParentHandler(menu)}
                        data-test-menu-id={menu.id ? String(menu.id) : ''}
                        className={clx(
                          classes.menulistLiContainer,
                          isActiveItem(menu.id) && 'active',
                        )}
                        key={`${menu.id}_li`}
                        data-style-cloes-menu="cloesMenu"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {' '}
                          <Icon className={classes.menuIcon}>
                            {menu.icon ? menu.icon : 'layers'}
                          </Icon>
                          <span className={classes.menucontent}>
                            {lodashGet(
                              menu,
                              ['translatedTitle', locale],
                              menu.title,
                            )}
                          </span>
                          {menu.isOk &&
                            menu.currentLevel !== 0 &&
                            menu.children.length > 0 &&
                            width === 'xs' && (
                              <ForwardArrow className={classes.childrenIcon} />
                            )}{' '}
                        </div>
                      </li>
                    ) : (
                      menuLink
                    )}
                  </Tooltip>
                ) : !isEmptyObject(menu.children) &&
                  menu.pageName !== 'todo' &&
                  menu.pageName !== 'menuPage' ? (
                  <li
                    key={`${menu.id}_li`}
                    onClick={() => onSelectParentHandler(menu)}
                    data-test-menu-id={menu.id ? String(menu.id) : ''}
                    data-style-parent-menu="parentMenu"
                    className={clx(
                      classes.menulistLiContainer,
                      isActiveItem(menu.id) && 'active',
                    )}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {' '}
                      <Icon className={classes.menuIcon}>
                        {menu.icon ? menu.icon : 'layers'}
                      </Icon>
                      <span className={classes.menucontent}>
                        {lodashGet(menu, ['translatedTitle', locale], menu.title)}
                      </span>
                      {menu.isOk &&
                        menu.currentLevel !== 0 &&
                        menu.children.length > 0 &&
                        width === 'xs' && (
                          <ForwardArrow className={classes.childrenIcon} />
                        )}{' '}
                    </div>
                  </li>
                ) : (
                  menuLink
                );
              })}

              {width === 'xs' && (
                <li className={classes.menulistLiContainer} data-test-logout="xs">
                  <Icon className={classes.menuIcon}>power_settings_new</Icon>
                  <a className={classes.linkTitle} onClick={userLogout}>
                    {translate('ra.auth.logout')}
                  </a>
                </li>
              )}
            </ul>
          </div>

          {width === 'xs' && (
            <Slide
              direction="right"
              in={!!selectedMenuChildren.length}
              mountOnEnter
              unmountOnExit
            >
              <Grid className={classes.subMenu}>
                <div className={classes.menucart}>
                  <ul className={classes.menulist}>
                    {selectedMenuChildren.map(menu => (
                      <li
                        key={`${menu.id}_xs_li`}
                        className={classes.menulistLiContainer}
                        data-test-sub-menu-id={menu.id ? String(menu.id) : ''}
                      >
                        {showMenuInList(menu, childMenuSelectHandler)}
                        {menu.isOk &&
                          menu.currentLevel !== 0 &&
                          menu.children.length > 0 && (
                            <ForwardArrow
                              className={classes.childrenIcon}
                              onClick={() => childMenuSelectHandler(menu.id)}
                            />
                          )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
            </Slide>
          )}
        </div>
      )}
      {isDrawerOpen && (
        <div className={classes.container}>
          <a href="https://samiansoft.com/">
            <img
              className={classes.samianLogo}
              src="../assets/image/samian-logo.png"
            />
          </a>
          <Typography
            className={
              localStorage.getItem('customApiUrl')
                ? classes.appVersionWithChangeApi
                : classes.appVersion
            }
          >
            v-{appVersion}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SidebarMenuView;
