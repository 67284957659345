/* eslint-disable no-use-before-define */
import { Button, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { FC } from 'react';
import { CustomTheme } from '../../core/themeProvider';
import { isEmpty } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';
import { useAutoLimitTags } from '../dynamic-input/auto-complete-input/auto-complete-input.hook';
import { openNewTab } from '../../helper/QuickAccessHelper';
import { parseJSON } from '../../core/configProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  rootDropdown: {
    width: '100%',
    position: 'relative',
    height: '100%',
  },

  rootTooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.common.black,
  },

  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    minHeight: 27,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  button: {
    margin: '3px',
    borderRadius: 6,
    maxWidth: 100,
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
    '&:hover': {
      background: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
    '& .MuiButton-endIcon': {
      flexShrink: '1 !important',
    },
  },

  clearIcon: {
    color: theme.palette.common.white,
    width: 16,
    height: 16,
    fill: 'currentColor',
    background: theme.palette.primary.appPrimaryIconColor,
    borderRadius: '50%',
    pointerEvents: 'auto',
  },

  rootTags: {
    display: 'flex',
    padding: '0px',
    flexWrap: 'wrap',
    height: '100%',
  },

  padding: {
    padding: 0,
  },

  mouseOver: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    margin: 0,
    lineHeight: 0,
  },

  typography: {
    margin: '0px 8px',
    flexGrow: 2,
  },

  arrowTooltip: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.secondary.main,
    },
  },
  spanItemButton: {
    flexGrow: 2,
  },
}));

interface AutocompleteInputInterface {
  record: {
    isNewRecord: boolean;
  };
  label: string;
  source: string;
  field: FieldType;
  isShowMode?: boolean;
}

type JSONConfigType = {
  StringMultiSelectMaxWidth: string;
};

const AutocompleteField: FC<AutocompleteInputInterface> = props => {
  const { record, source, field, isShowMode = false } = props;
  const { disabled, customOption } = field;
  const classes = useStyles();
  const jsonConfig = parseJSON<null | JSONConfigType>(field.jsonConfig);
  let preparedValue = [];

  if (record[source] && typeof record[source] === 'string') {
    preparedValue = record[source]
      .split(',')
      .filter((item: string) => !isEmpty(item));
  } else if (Array.isArray(record[source])) {
    preparedValue = record[source].filter((item: string) => !isEmpty(item));
  }

  const { limitTagCount, refTags } = useAutoLimitTags();

  /**
   * open dropdown's item main record with dropdown resource and id in new tab
   * @function openDropdownItemRecord
   * @returns {void}
   */
  const openDropdownItemRecord = (selectedItemIndex: number) => (): void => {
    const selectedItemValue = record?.[field.name]
      ?.split(',')
      .filter(item => item)
      .map(item => item?.trim())[selectedItemIndex];

    if (
      field.dropdown?.moduleName &&
      field.dropdown?.tableName &&
      !isEmpty(selectedItemValue)
    ) {
      const address = `${field.dropdown.moduleName}/${field.dropdown.tableName}/${selectedItemValue}`;
      openNewTab(address);
    }
  };

  return (
    <div className={classes.rootDropdown}>
      <div
        className={classes.root}
        ref={refTags}
        style={customOption.customStyleAdditionalDataField}
      >
        {preparedValue.length ? (
          <div
            className={classes.rootTags}
            style={customOption.customStyleAdditionalDataField}
          >
            {preparedValue
              .slice(0, limitTagCount)
              .map((item: string, index: number) => (

                <Tooltip
                  classes={{
                    tooltip: classes.rootTooltip,
                    arrow: classes.arrowTooltip,
                  }}
                  arrow
                  style={{
                    maxWidth: jsonConfig?.StringMultiSelectMaxWidth,
                  }}
                  placement="bottom-start"
                  title={item}
                >
                <Button
                  onClick={openDropdownItemRecord(index)}
                  classes={{ root: classes.button }}
                  endIcon={
                    !isShowMode ? <ClearIcon className={classes.clearIcon} /> : <></>
                  }
                >
                  <Button
                    disabled={disabled}
                    classes={{ root: classes.button }}
                    endIcon={
                      !isShowMode ? (
                        <ClearIcon className={classes.clearIcon} />
                      ) : (
                        <></>
                      )
                    }
                  >
                    <Typography
                      component="span"
                      noWrap
                      className={classes.spanItemButton}
                    >
                      {item}
                    </Typography>
                  </Button>
                </Button>
                </Tooltip>
              ))}
          </div>
        ) : null}

        {preparedValue.slice(limitTagCount).length ? (
          <Tooltip
            classes={{
              tooltip: classes.rootTooltip,
              arrow: classes.arrowTooltip,
            }}
            arrow
            placement="left-start"
            title={
              <>
                {preparedValue
                  .slice(limitTagCount)
                  .map((item: string, index: number) => (
                    <p
                      data-test-input-name="popover-more-test"
                      onClick={openDropdownItemRecord(index)}
                    >
                      {item}
                    </p>
                  ))}
              </>
            }
          >
            <p
              data-test-input-name="mouse-over-more-test"
              className={classes.mouseOver}
            >
              ...
            </p>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default AutocompleteField;
