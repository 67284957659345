import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import querystring from 'qs';
import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { ui } from '../../../helper/InputHelper';
import { getParamFromUrl } from '../../../helper/UrlHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  ResourceInterface,
} from '../../../type/actor-setup';

import type {
  FieldType,
  GeneralMetaData,
  MetaData,
  MetaDataBase,
  MetaDataProcessTask,
  ValidationError,
} from '../../../helper/Types';
import type {
  FormData,
  InitialData,
  InputRefContent,
  UiEnableInputActionInterface,
  UiVisibleInputActionInterface,
} from '../form.type';
import { getInputsInitialAppearanceCharacteristics } from '../../../helper/meta-helper';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../../helper/settings-helper';
import { TabInterface } from '../../show-record-with-relation';
import { HandleHiddenGroups, OnInputStateChange } from './form-with-tab.type';

/**
 * @function setDefaultFormValues
 * @param { FORM?: Record<string, unknown>; PARENT_RECORD?: Record<string, unknown>; FULL?: Record<string, unknown> } record
 * @param { Record<string, unknown> } defaultValues
 * @param { ResourceInterface } currentResource
 * @param { boolean } isCreateMode
 * @returns void
 */
export const setDefaultFormValues = (
  record: {
    FORM?: Record<string, unknown>;
    PARENT_RECORD?: Record<string, unknown>;
    FULL?: Record<string, unknown>;
  } | null,
  defaultValues: Record<string, unknown>,
  currentResource: ResourceInterface,
): void => {
  const currentFormData =
    actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    ) ?? {};

  const quickCreateSupplementaryData = actorGetActionValue(
    'quickCreateSupplementaryData',
    `${currentResource.value}.${currentResource.type}`,
  );

  actorSetActionValue('initialData', defaultValues, {
    replaceAll: true,
    path: `${currentResource.value}.${currentResource.type}`,
  });

  actorSetActionValue(
    'formData',
    {
      ...currentFormData,
      ...defaultValues,
      ...quickCreateSupplementaryData,
      ...(record?.[RecordKeyMode.FULL] ?? {}),
    },
    {
      path: `${currentResource.value}.${currentResource.type}`,
      replaceAll: true,
    },
  );

  actorDispatch('signal', 'formDefaultValuesSet');
};

// FIXME: Think about the performance of the following function
/**
 * It sets all data about `uiVisible` and `uiEnable` on any `input`s ref
 * @function updateInputsUiSpecs
 * @param { Record<string, InputRefContent> } inputsRef
 * @param { FieldType[] } allRawFields
 * @returns void
 */
const fieldsNotInCurrentLayout: Record<
  string,
  {
    uiVisibleInputsAction: UiVisibleInputActionInterface[];
    uiEnableInputsAction: UiEnableInputActionInterface[];
  }
> = {};
export const updateInputsUiSpecs = (
  inputsRef: Record<string, InputRefContent>,
  allRawFields: FieldType[],
  currentProcessFields?: Record<string | number, FieldType>,
): void => {
  const inputsNameList = Object.keys(inputsRef);
  const inputsNameListLen = inputsNameList.length;

  if (inputsNameListLen === 0) return;

  for (let i = 0; i < inputsNameListLen; i++) {
    for (let j = 0; j < inputsNameListLen; j++) {
      const uiVisibleList = inputsRef[inputsNameList[j]].field?.uiVisible;
      if (
        uiVisibleList &&
        Array.isArray(uiVisibleList) &&
        uiVisibleList.length > 0
      ) {
        if (i === j) {
          for (const uiVisibleItem of uiVisibleList) {
            const [visibleFieldName, column] = uiVisibleItem;

            const indexOfFieldNameUiVisibleInAllRawFields = allRawFields.findIndex(
              item => item.name === visibleFieldName,
            );

            if (indexOfFieldNameUiVisibleInAllRawFields > -1) {
              if (fieldsNotInCurrentLayout[visibleFieldName] == null) {
                fieldsNotInCurrentLayout[visibleFieldName] = {
                  uiVisibleInputsAction: [],
                  uiEnableInputsAction: [],
                };
              }

              fieldsNotInCurrentLayout[visibleFieldName].uiVisibleInputsAction!.push(
                {
                  inputNameToBeVisible: inputsNameList[i],
                  dropColumnName: column,
                  javaScriptUiVisible:
                    inputsRef[inputsNameList[i]].field.javaScriptUiVisible,
                },
              );
            }
          }
        } else {
          for (const uiVisibleItem of uiVisibleList) {
            const [visibleFieldName, column] = uiVisibleItem;

            if (visibleFieldName !== inputsNameList[i]) continue;

            inputsRef[inputsNameList[i]].uiVisibleInputsAction.push({
              inputNameToBeVisible: inputsNameList[j],
              dropColumnName: column,
              javaScriptUiVisible:
                inputsRef[inputsNameList[j]].field.javaScriptUiVisible,
            });
          }
        }
      }

      const field = inputsRef[inputsNameList[j]].field;
      // prettier-ignore
      const isFieldDisabledInCurrentProcess = currentProcessFields?.[field.id]?.disabled === true;
      const uiEnableList = inputsRef[inputsNameList[j]].field?.uiEnable;
      if (
        !isFieldDisabledInCurrentProcess &&
        uiEnableList &&
        Array.isArray(uiEnableList) &&
        uiEnableList.length > 0
      ) {
        if (i === j) {
          for (const uiEnableItem of uiEnableList) {
            const [enableFieldName, column] = uiEnableItem;

            const indexOfFieldNameUiEnableInAllRawFields = allRawFields.findIndex(
              item => item.name === enableFieldName,
            );

            if (indexOfFieldNameUiEnableInAllRawFields > -1) {
              if (fieldsNotInCurrentLayout[enableFieldName] == null) {
                fieldsNotInCurrentLayout[enableFieldName] = {
                  uiVisibleInputsAction: [],
                  uiEnableInputsAction: [],
                };
              }

              fieldsNotInCurrentLayout[enableFieldName].uiEnableInputsAction!.push({
                inputNameToBeEnable: inputsNameList[i],
                dropColumnName: column,
                javaScriptUiEnable:
                  inputsRef[inputsNameList[i]].field.javaScriptUiEnable,
              });
            }
          }
        } else {
          for (const uiEnableItem of uiEnableList) {
            const [enableFieldName, column] = uiEnableItem;

            if (enableFieldName !== inputsNameList[i]) continue;

            inputsRef[inputsNameList[i]].uiEnableInputsAction.push({
              inputNameToBeEnable: inputsNameList[j],
              dropColumnName: column,
              javaScriptUiEnable:
                inputsRef[inputsNameList[j]].field.javaScriptUiEnable,
            });
          }
        }
      }
    }
  }

  for (const key in fieldsNotInCurrentLayout) {
    checkUiControl(key);
  }
};

/**
 * It will look and find `uiVisible` and `uiEnable` info of any input
 * @function checkUiControl
 * @param { string } inputName
 * @param { ResourceInterface } resource
 * @returns void
 */
export const checkUiControl = (
  inputName: string,
  resource?: ResourceInterface,
): void => {
  if (resource == null) {
    resource = actorGetActionValue('resources')?.current;
  }

  if (resource == null) return;

  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${resource.value}.${resource.type}`,
  ) as Record<string, InputRefContent> | null;

  if (isEmptyObject(inputsRef)) return;

  const formData = actorGetActionValue(
    'formData',
    `${resource.value}.${resource.type}`,
  ) as FormData | null;

  if (isEmptyObject(formData)) return;

  let uiVisibleInfo: UiVisibleInputActionInterface[] = [];
  let uiEnableInfo: UiEnableInputActionInterface[] = [];

  if (inputsRef[inputName] == null) {
    uiVisibleInfo = fieldsNotInCurrentLayout[inputName]?.uiVisibleInputsAction ?? [];
    uiEnableInfo = fieldsNotInCurrentLayout[inputName]?.uiEnableInputsAction ?? [];
  } else {
    uiVisibleInfo = inputsRef[inputName]?.uiVisibleInputsAction ?? [];
    uiEnableInfo = inputsRef[inputName]?.uiEnableInputsAction ?? [];
  }

  for (const uiVisibleItem of uiVisibleInfo) {
    if (uiVisibleItem.javaScriptUiVisible?.indexOf('return') === -1) continue;

    try {
      if (!isEmpty(uiVisibleItem.dropColumnName)) {
        const relatedDropDownValue = getRelatedDropdownValueToUiEnableVisible(
          inputsRef[uiVisibleItem.inputNameToBeVisible].field,
          'uiVisible',
        );

        if (!isEmptyObject(relatedDropDownValue)) {
          const uiVisibleFunction = new Function(
            'relatedDropDownValue',
            uiVisibleItem.javaScriptUiVisible!,
          );

          inputsRef[uiVisibleItem.inputNameToBeVisible].setIsUiVisible(
            !!uiVisibleFunction(clone(relatedDropDownValue)),
          );
        } else {
          inputsRef?.[uiVisibleItem.inputNameToBeVisible].setIsUiVisible(false);
        }
      } else {
        const uiVisibleFunction = new Function(
          'formdata',
          `${uiVisibleItem.javaScriptUiVisible?.toLowerCase()}`,
        );

        inputsRef[uiVisibleItem.inputNameToBeVisible].setIsUiVisible(
          !!uiVisibleFunction(clone(formData)),
        );
      }
    } catch (error) {
      console.log('`checkUiControl`: javaScriptUiVisible error ', {
        inputName,
        error,
      });
    }
  }

  for (const uiEnableItem of uiEnableInfo) {
    if (uiEnableItem.javaScriptUiEnable?.indexOf('return') === -1) continue;

    try {
      if (!isEmpty(uiEnableItem.dropColumnName)) {
        const relatedDropDownValue = getRelatedDropdownValueToUiEnableVisible(
          inputsRef[uiEnableItem.inputNameToBeEnable].field,
          'uiEnable',
        );

        if (!isEmptyObject(relatedDropDownValue)) {
          const uiEnableFunction = new Function(
            'relatedDropDownValue',
            uiEnableItem.javaScriptUiEnable!,
          );

          inputsRef?.[uiEnableItem.inputNameToBeEnable].setIsUiEnabled(
            !!uiEnableFunction(clone(relatedDropDownValue)),
          );
        } else {
          inputsRef?.[uiEnableItem.inputNameToBeEnable].setIsUiEnabled(false);
        }
      } else {
        const uiEnableFunction = new Function(
          'formdata',
          `${uiEnableItem.javaScriptUiEnable?.toLowerCase()}`,
        );

        inputsRef[uiEnableItem.inputNameToBeEnable].setIsUiEnabled(
          !!uiEnableFunction(clone(formData)),
        );
      }
    } catch (error) {
      console.log('`checkUiControl`: javaScriptUiEnable error ', {
        inputName,
        error,
      });
    }
  }
};

// TODO: uiVisibleList?.[0] is the first formula in uiVisibleList array, currently web and api dose not support multiple formula
/**
 * It checks a `field` is visible or not
 * @function checkUiVisible
 * @param { FieldType } field
 * @param { FormData } formData
 * @returns boolean
 */
export const checkUiVisible = (field: FieldType, formData: FormData): boolean => {
  if (isEmpty(field.javaScriptUiVisible?.trim())) {
    return true;
  }

  let visible = false;
  try {
    const uiVisibleList = field.uiVisible;

    if (!(Array.isArray(uiVisibleList?.[0]) && uiVisibleList![0].length)) {
      throw new Error("uiVisible's first item is not an array");
    }

    const uiVisibleItem = uiVisibleList![0];

    const [, column] = uiVisibleItem;

    if (!isEmpty(column)) {
      const relatedDropDownValue = getRelatedDropdownValueToUiEnableVisible(
        field,
        'uiVisible',
      );

      if (!isEmptyObject(relatedDropDownValue)) {
        const uiVisibleFunction = new Function(
          'relatedDropDownValue,formData',
          `${field.javaScriptUiVisible}`,
        );
        visible = !!uiVisibleFunction(clone(relatedDropDownValue), clone(formData));
      }
    } else {
      const uiVisibleFunction = new Function(
        'formData',
        `${field.javaScriptUiVisible}`,
      );

      visible = !!uiVisibleFunction(clone(formData));
    }
  } catch (error) {
    throw new Error(`checkUiVisible: field: ${field.name}, error: ${error}`);
  }

  return visible;
};

// TODO: uiEnableList?.[0] is the first formula in uiEnableList array, currently web and api dose not support multiple formula
/**
 * It checks a `field` is enabled or not
 * @function checkUiEnable
 * @param { FieldType } field
 * @param { FormData } formData
 * @returns boolean
 */
export const checkUiEnable = (field: FieldType, formData: FormData): boolean => {
  let enabled = false;

  try {
    const uiEnableList = field.uiEnable;

    if (!(Array.isArray(uiEnableList?.[0]) && uiEnableList![0].length)) {
      throw new Error("uiEnable's first item is not an array");
    }

    const uiEnableItem = uiEnableList![0];
    const [, column] = uiEnableItem;

    if (!isEmpty(column)) {
      const relatedDropDownValue = getRelatedDropdownValueToUiEnableVisible(
        field,
        'uiEnable',
      );

      if (!isEmptyObject(relatedDropDownValue)) {
        const uiEnableFunction = new Function(
          'relatedDropDownValue,formData',
          `${field.javaScriptUiEnable}`,
        );

        enabled = !!uiEnableFunction(clone(relatedDropDownValue), clone(formData));
      }
    } else {
      const uiEnableFunction = new Function(
        'formData',
        `${field.javaScriptUiEnable}`,
      );

      enabled = !!uiEnableFunction(clone(formData));
    }
  } catch (error) {
    throw new Error(`checkUiEnable: field: ${field.name}, error: ${error}`);
  }

  return enabled;
};

/**
 * check field and get dropdown data in actor
 * @function getRelatedDropdownValueToUiEnableVisible
 * @param { FieldType } field
 * @param { string } uiCheckType
 * @returns { void } void
 */
const getRelatedDropdownValueToUiEnableVisible = (
  field: FieldType,
  uiCheckType: string,
): Record<string, unknown> | null => {
  const currentResource = actorGetActionValue('resources')?.current;

  if (currentResource == null) {
    console.warn('`getRelatedDropdownValueToUiEnableVisible`: the resource not set');
    return null;
  }

  const formData =
    (actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    ) as FormData | null) ?? {};

  const fieldName: string = lodashGet(field, [uiCheckType, 0, ui.fieldName], '');
  const allFields = actorGetActionValue('allFields', [
    currentResource.value,
    currentResource.type,
  ])! as unknown as Array<FieldType>;

  const dropDownData = actorGetActionValue('dropDownData');

  const relatedDropDownField = allFields?.filter(
    (field: FieldType) => field.name === fieldName,
  );

  const relatedDropDownId = relatedDropDownField?.[0]?.id;
  const relatedDropDownUniqueId = relatedDropDownField?.[0]?.dropdown?.uniqueId;

  const relatedDropDownData =
    dropDownData?.[relatedDropDownUniqueId]?.['DATA'] ??
    dropDownData?.[`${relatedDropDownUniqueId}-${relatedDropDownId}`]?.['DATA'];

  const dropName: string = lodashGet(field, [uiCheckType, 0, ui.column], '');

  const selectedDropColId =
    formData[fieldName] != null ? +(formData[fieldName] as string) : null;

  let dropData: Record<string, unknown> | null = null;
  if (selectedDropColId != null) {
    const activeDropColumn: Record<string, unknown> = lodashFind(
      relatedDropDownData,
      {
        id: selectedDropColId,
      },
    );

    dropData = {};
    if (activeDropColumn == null) {
      dropData[dropName] = selectedDropColId;
    } else {
      dropData[dropName] = activeDropColumn[dropName];
    }
  }

  return dropData;
};

/**
 * get current process info from url
 * @function getProcessInfoFromUrl
 * @returns {Record<string, unknown>} process info
 */
export const getProcessInfoFromUrl = (): Record<string, unknown> => {
  const processInfo = {};
  const currentUrl = actorGetActionValue('urlInfo')?.location.href.split('?')[1];
  const urlParameters =
    querystring.parse(currentUrl, { ignoreQueryPrefix: true }) ?? {};

  if (
    urlParameters.__processuniqueid &&
    urlParameters.stateid &&
    urlParameters.positionid
  ) {
    processInfo['__processuniqueid'] = urlParameters.__processuniqueid;
    processInfo['stateid'] = urlParameters.stateid;
    processInfo['positionid'] = urlParameters.positionid;
  }

  return processInfo;
};

/**
 * Calculate `uiVisible` and `uiEnable` by `updateInputsUiSpecs` function after tab changes
 *   we need updated `inputsRef`
 * @function updateLayoutUiSpecs
 * @param resource
 * @returns { void } void
 */
export const updateLayoutUiSpecs = (
  resource: ResourceInterface,
  currentProcessInfo: MetaDataProcessTask | null,
): void => {
  const metaData = actorGetActionValue('metaData', resource.value) as unknown as
    | MetaData
    | undefined;

  const inputsRef = actorGetActionValue('inputsRef', [
    resource.value,
    resource.type,
  ]) as Record<string, InputRefContent> | null;

  if (metaData && inputsRef) {
    const allRawFields = Object.values(
      (metaData as unknown as MetaDataBase).fields ?? {},
    );

    updateInputsUiSpecs(inputsRef, allRawFields, currentProcessInfo?.fields);
  }
};

/**
 * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
 * @function updateInputsState
 * @param {object} inputsRef
 * @param {object} formErrors
 * @param {object} formData
 * @returns {void} void
 */
export const updateInputsState = (
  inputsRef: Record<string, InputRefContent> | null | undefined,
  formErrors: Record<string, ValidationError> | undefined,
  formData: FormData | InitialData,
): void => {
  if (isEmptyObject(inputsRef)) {
    return;
  }

  for (const inputName in inputsRef) {
    if (formErrors?.[inputName]) {
      inputsRef[inputName].setInputMessage?.({
        message: formErrors[inputName].message,
        messageType: formErrors[inputName].errorMessageType,
      });
      inputsRef[inputName].setInputValue?.(formErrors![inputName].value ?? '');
      continue;
    }

    inputsRef[inputName].setInputMessage?.(undefined);

    if (typeof formData![inputName] === 'boolean') {
      inputsRef[inputName].setInputValue?.(
        Boolean(Number(formData?.[inputName] ?? 0)),
      );
    } else {
      inputsRef[inputName].setInputValue?.(formData?.[inputName] ?? '');
    }
  }
};

/**
 * @function checkTabErrors
 * @param { ResourceInterface } resource
 * @param { React.MutableRefObject<Record<number, HTMLParagraphElement | null>> } tabsTitlesRef
 * @param { Record<string, ValidationError> | null } formErrors
 * @returns {void} void
 */
export const checkTabErrors = (
  resource: ResourceInterface,
  tabsTitlesRef: React.MutableRefObject<Record<number, HTMLParagraphElement | null>>,
  formErrors?: Record<string, ValidationError> | null,
): void => {
  if (formErrors == null) {
    formErrors =
      (actorGetActionValue('formMessages', [
        resource.value,
        resource.type,
      ]) as Record<string, ValidationError> | null) ?? {};
  }

  if (isEmptyObject(formErrors)) {
    for (const tabKey in tabsTitlesRef.current) {
      if (tabsTitlesRef.current[tabKey] == null) continue;

      // We used `!` because we sure that `tabsTitlesRef.current[tabKey]` isn't null or undefined
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.removeAttribute(
        'has-error',
      );
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.removeAttribute(
        'data-test-has-error',
      );
    }
    return;
  }

  const errors = Object.values(formErrors);
  for (const tabKey in tabsTitlesRef.current) {
    if (tabsTitlesRef.current[tabKey] == null) continue;

    // We used `!` because we sure that `tabsTitlesRef.current[tabKey]` isn't null or undefined
    if (errors.some(error => error.tabId == tabKey)) {
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.setAttribute(
        'has-error',
        '',
      );
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.setAttribute(
        'data-test-has-error',
        'true',
      );
    } else {
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.removeAttribute(
        'has-error',
      );
      tabsTitlesRef.current[tabKey]!.parentElement!.parentElement!.removeAttribute(
        'data-test-has-error',
      );
    }
  }
};

/**
 * extract parent resource from current record
 * @function getParentResource
 * @param {Record<string, unknown>} record
 * @returns { string | null}
 */
export const getParentResource = (
  record: Record<string, unknown>,
): string | null => {
  const parentRoute = (record?.['__parentInfo'] as Record<string, unknown>)?.[
    'route'
  ] as string | undefined;

  const { stack: resourceStack } = actorGetActionValue('resources')!;

  if (parentRoute) {
    const [, moduleName, moduleTableName] = parentRoute.split('/');

    return getParamFromUrl(location.search, 'parentResource') ??
      resourceStack[resourceStack.length - 2]?.value ??
      (moduleName && moduleTableName)
      ? `${moduleName}/${moduleTableName}`
      : null;
  }

  return (
    getParamFromUrl(location.search, 'parentResource') ??
    resourceStack[resourceStack.length - 2]?.value ??
    null
  );
};

/**
 * @function prepareFormInputsCharacteristics
 * @param { string | null } parentResource
 * @param { MetaData } metaData
 * @param { Record<string, unknown> } currentRecord
 * @param { ResourceInterface } currentResource
 * @returns {void} void
 */
export const prepareFormInputsCharacteristics = (
  parentResource: string | null,
  metaData: GeneralMetaData,
  currentRecord: Record<string, unknown>,
  currentResource: ResourceInterface,
  currentProcessInfo?: MetaDataProcessTask | null,
): void => {
  let parentMetaData: MetaData | undefined;
  let parentRecord: Record<string, unknown> | undefined;

  if (parentResource) {
    parentMetaData = actorGetActionValue('metaData')?.[parentResource];
    parentRecord =
      actorGetActionValue('record')?.[parentResource]?.[FormKeyMode.ROOT]?.[
        RecordKeyMode.FULL
      ];
  }

  const inputsInitialAppearanceCharacteristics =
    getInputsInitialAppearanceCharacteristics({
      currentMetaData: metaData as GeneralMetaData,
      currentRecord: currentRecord,
      parentMetaData: parentMetaData as GeneralMetaData,
      parentRecord: parentRecord,
      currentProcessInfo,
    }) ?? {};

  actorSetActionValue(
    'inputsInitialAppearanceCharacteristics',
    inputsInitialAppearanceCharacteristics,
    {
      path: `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
    },
  );
};

export const getInitialGroupsForEachTab = (
  tabs: TabInterface[],
): Record<string, Record<string, null>> => {
  const preparedObject: Record<string, Record<string, null>> = {};

  tabs.forEach(tab => {
    const initialGroups = Object.fromEntries(
      tab.groupList.map(group => {
        return [String(group.id), null];
      }),
    );

    preparedObject[String(tab.id)] = initialGroups;
  });

  return preparedObject;
};

/**
 * create an instance object for each tab , change that and call callback function when change
 * @function handleHiddenGroups
 * @param {TabInterface} tab
 * @param {function} onHiddenGroupsChange
 * @returns {void}
 */
export const handleHiddenGroups = (
  tab: TabInterface,
  onHiddenGroupsChange: (...args) => void,
): HandleHiddenGroups => {
  const initialGroups = Object.fromEntries(
    tab.groupList.map(group => {
      return [String(group.id), {}];
    }),
  );

  const inputsStates = clone(initialGroups);
  const groupsStates = clone(initialGroups);

  const onInputStateChange: OnInputStateChange = (groupId, inputName, isVisible) => {
    if (inputsStates[groupId][inputName] === isVisible || !inputName) return;

    inputsStates[groupId][inputName] = isVisible;

    for (const [groupName, groupInputs] of Object.entries(inputsStates)) {
      groupsStates[groupName] = !Object.values(groupInputs).some(
        visibleField => visibleField,
      );
    }

    onHiddenGroupsChange?.(groupsStates, String(tab.id));
  };

  const getTabGroupsState = (): Record<string, boolean> => {
    return groupsStates as Record<string, boolean>;
  };

  return { onInputStateChange, getTabGroupsState };
};
