import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { translate } from 'react-admin';

import LanguageContainer from '../container/LanguageContainer';
import ChangeCalendarContainer from '../container/ChangeCalendarContainer';
import appVersion from '../core/version';
import { NotificationApp } from '../component/notification-app';
import Notification from '../component/Notification';
import { LoginForm } from '../component/login';
import { showNotificationForUnknownError } from '../helper/general-function-helper';
import { isValidUrl } from '../helper/UrlHelper';
import { LOGIN_LOGO_PATH_NAME, getValue } from '../core/configProvider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CUSTOM_GET, apiUrl, apiVersion } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { parseJSON } from '../core/configProvider';
import defaultImage1 from '../images/default-background-images/defaultImage1.jpg';
import defaultImage2 from '../images/default-background-images/defaultImage2.jpg';
import { prepareOverrideParams } from '../component/form/form.helper';

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  card: {
    minWidth: 300,
    paddingBottom: 12,
    border: '1px solid #c3c3c3',
    borderRadius: '0px',
  },

  Languages: {
    width: 300,
  },
  avatar: {
    margin: '1.5em 1em 1em 1em',
    textAlign: 'center',
  },
  ImasterIcon: {
    width: 130,
  },
  samianLogoImg: {
    width: '120px',
  },
  samianLogoDiv: {
    flex: '1',
    position: 'absolute',
    bottom: '0px',
    margin: '10px',
  },

  icon: {
    backgroundColor: theme.palette.secondary[500],
  },

  versionContainer: {
    textAlign: 'center',
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    flex: '1',
    margin: '10px',
  },

  versionText: {
    color: theme.palette.secondary.contrastText,
  },

  icon: {
    margin: '5px 0px 0px 5px',
  },

  backwardIcon: {
    '&:hover': {
      opacity: '90%',
    },
    backgroundColor: theme.palette.primary.loginPageBackgroundColor,
    padding: '0px',
    opacity: '70%',
    border: 'none',
    position: 'fixed',
    left: 0,
    top: '80%',
    transform: 'translateY(-50%)',
  },

  forwardIcon: {
    '&:hover': {
      opacity: '90%',
    },
    backgroundColor: theme.palette.primary.loginPageBackgroundColor,
    padding: '0px',
    opacity: '70%',
    border: 'none',
    position: 'fixed',
    right: 0,
    top: '80%',
    transform: 'translateY(-50%)',
  },

  footer: {
    height: '100px',
    width: '100%',
    position : 'absolute',
    bottom: "0px",
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,1), transparent 100%)',
    display: 'flex',
    justifyContent: 'center',
  }

});

const sanitizeRestProps = ({
  array,
  backgroundImage,
  classes,
  className,
  location,
  staticContext,
  theme,
  title,
  ...rest
}) => rest;

const backgroundImageUrl = `${apiVersion}/setting/loginbackground`;
class LoginPage extends Component {
  counter;
  constructor(props) {
    super(props);
    this.state = {
      backgroundImageUrls: [],
      defaultBackgroundImages: [defaultImage1, defaultImage2],
      currentImageIndex: 0,
      backgroundImage: null,
      isMobile: false,
      startX: 0,
      startY: 0,
      deltaX: 0,
      deltaY: 0,

    };
    this.handlePreviousImage = this.handlePreviousImage.bind(this),
    this.handleResize = this.handleResize.bind(this);
    this.handleNextImage = this.handleNextImage.bind(this);
    this.theme = createTheme(props.theme);
    this.containerRef = createRef();
    this.backgroundImageLoaded = false;
    this.counter = 0;
    this.isTouching = false;
  }

  updateBackgroundImage = (lastTry = false) => {
    const { backgroundImageUrls, defaultBackgroundImages, currentImageIndex } = this.state;
    let imageUrl;

    if (backgroundImageUrls.length > 0 && backgroundImageUrls[currentImageIndex]) {
      imageUrl = backgroundImageUrls[currentImageIndex];
    }
    else {
      imageUrl = defaultBackgroundImages[currentImageIndex];
    }

    this.containerRef.current.style.backgroundImage = `url(${imageUrl})`;
    this.backgroundImageLoaded = true;
  };

  changeApi = () => {
    if (this.counter < 5) {
      this.counter++;
      return;
    }

    let apiUrl = prompt(
      '',
      localStorage.getItem('customApiUrl')
        ? localStorage.getItem('customApiUrl')
        : window.configFile?.API_URL,
    );
    if (apiUrl) {
      if (isValidUrl(apiUrl)) {
        if (apiUrl.slice(-1).includes('/')) {
          localStorage.setItem('customApiUrl', apiUrl.slice(0, -1));
        } else {
          localStorage.setItem('customApiUrl', apiUrl);
        }
        window.location.reload();
      } else {
        showNotificationForUnknownError(
          this.props.translate('ra.message.not_found'),
        );
      }
    }
  };

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImageUrls, defaultBackgroundImages } = this.state;

    const img = new Image();
    if (backgroundImageUrls.length > 0) {
      img.src = backgroundImageUrls[0];
    } else {
      img.src = defaultBackgroundImages[0];
    }
    img.onload = this.updateBackgroundImage;
  }

  componentDidMount = async () => {
    try {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      const response = await dataProvider(CUSTOM_GET, backgroundImageUrl);

      const _backgroundImageUrls = [];

      for (const item of response.data.data) {
        const imagefs = parseJSON(item.imagefs);

        if (imagefs === null) {
          console.warn('invalid imagefs value:', item);
          continue;
        }

        const url = apiUrl + '/' + imagefs.filePath;
        _backgroundImageUrls.push(url);
      }

      this.setState(prev => ({
        ...prev,
        backgroundImageUrls: _backgroundImageUrls,
      }), this.lazyLoadBackgroundImage);
    } catch (error) {
      console.error('invalid response value:', error);
      this.lazyLoadBackgroundImage();
    }

    const parsedUrl = prepareOverrideParams()?.apiurl;
    const oldUrl= localStorage.getItem('customApiUrl');

    if (isValidUrl(parsedUrl)) {
      localStorage.setItem('customApiUrl', parsedUrl??oldUrl);
      if(parsedUrl !== oldUrl && parsedUrl && oldUrl) {
       window.location.reload()
      }
    }
  }

  handleResize() {
    const isMobile = window.matchMedia("(max-width: 600px)").matches;
    this.setState(prev => ({
      ...prev, isMobile: !isMobile
    }));
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage(true);
    }

    const searchParams = new URLSearchParams(this.props.location.search);

  }

  handlePreviousImage(){
    const { currentImageIndex, backgroundImageUrls, defaultBackgroundImages } = this.state;
    let newIndex;

    if(backgroundImageUrls.length > 0){
      newIndex = currentImageIndex === 0 ? backgroundImageUrls.length - 1 : currentImageIndex - 1;
    }
    else {
      newIndex = currentImageIndex === 0 ? defaultBackgroundImages.length - 1 : currentImageIndex - 1;
    }
    this.setState(prev => ({
      ...prev, currentImageIndex: newIndex
    }), this.updateBackgroundImage);

  };

  handleNextImage(){
    const { currentImageIndex, backgroundImageUrls, defaultBackgroundImages } = this.state;
    let newIndex;

    if(backgroundImageUrls.length > 0){
      newIndex = currentImageIndex === backgroundImageUrls.length - 1 ? 0 : currentImageIndex + 1;
    }
    else {
      newIndex = currentImageIndex === defaultBackgroundImages.length - 1 ? 0 : currentImageIndex + 1;
    }
    this.setState(prev => ({
      ...prev, currentImageIndex: newIndex
    }), this.updateBackgroundImage);

  };

  handleTouchStart = (event) => {
    this.setState(prev => ({
        ...prev, startX: event.touches[0].pageX,
        startY: event.touches[0].pageY,
    }));
  };

  handleTouchMove = (event) => {
    this.isTouching = true;
    const sensitivity = 0.35;
    this.setState(prev => ({
      ...prev,
      deltaX: (event.touches[0].pageX - this.state.startX) * sensitivity,
      deltaY: (event.touches[0].pageY - this.state.startY) * sensitivity,
    }));
  };

  handleTouchEnd = () => {
    if (!this.isTouching) {
      return;
    }
    this.isTouching = false;
    const { deltaX, deltaY } = this.state;
    const { backgroundImageUrls, currentImageIndex, defaultBackgroundImages } = this.state;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    const direction = Math.sign(deltaX);
    let newIndex;
    if (distance > 50 && direction !== 0 && backgroundImageUrls.length > 0) {
      if (deltaX < 0) {
        newIndex = (currentImageIndex + 1) % backgroundImageUrls.length;
      } else {
        newIndex = (currentImageIndex - 1 + backgroundImageUrls.length) % backgroundImageUrls.length;
      }
    }
    else{
      if (deltaX < 0) {
        newIndex = (currentImageIndex + 1) % defaultBackgroundImages.length;
      } else {
        newIndex = (currentImageIndex - 1 + defaultBackgroundImages.length) % defaultBackgroundImages.length;
      }
    }
    this.setState(prev => ({
      ...prev, currentImageIndex: newIndex
    }), this.updateBackgroundImage);
  };

  render() {
    const { classes, className, ...rest } = this.props;
    const { isMobile } = this.state;

    return (
        <MuiThemeProvider theme={this.theme}>
          <Notification />
            <div
              className={classNames(classes.main, className)}
              {...sanitizeRestProps(rest)}
              ref={this.containerRef}
              onTouchStart={this.handleTouchStart}
              onTouchMove={this.handleTouchMove}
              onTouchEnd={this.handleTouchEnd}
            >
              {isMobile && (
                <>
                <button onClick={this.handlePreviousImage} className={classes.backwardIcon}> <ArrowBackIosIcon className={classes.icon}/> </button>
                <button onClick={this.handleNextImage} className={classes.forwardIcon}> <ArrowForwardIosIcon className={classes.icon}/> </button>
                </>
              )}
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <img
                    onClick={this.changeApi}
                    className={classes.ImasterIcon}
                    src={`/assets/image/loginImages/${getValue(LOGIN_LOGO_PATH_NAME)}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = '/assets/image/loginImages/en-imaster.png';
                    }}
                    alt="ImasterLogo"
                  />
                </div>
                <LoginForm />
              </Card>
              <div className={classes.Languages}>
                <LanguageContainer />
                <NotificationApp />
                <ChangeCalendarContainer />
              </div>
              <div className={classes.footer}>
                <div className={classes.samianLogoDiv}>
                  <a href="https://samiansoft.com/">
                    <img
                      className={classes.samianLogoImg}
                      src="/assets/image/loginImages/white-samian-logo.png"
                      alt="samianSoftT&L"
                    />
                  </a>
                </div>
                <div className={classes.versionContainer} onClick={this.changeApi}>
                  <Typography className={classes.versionText} gutterBottom color="textSecondary" variant="caption">
                    v-{appVersion}
                  </Typography>
                </div>
              </div>
            </div>
        </MuiThemeProvider>
    );
  }
}

LoginPage.propTypes = {
  authProvider: PropTypes.func,
  backgroundImage: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  previousRoute: PropTypes.string,
};

LoginPage.defaultProps = {
  backgroundImage: '',
};

export default withStyles(styles)(translate(LoginPage));
