import { FC, memo, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import lodashMap from 'lodash/map';

import MapView from './map.view';
import { getGridColumns } from '../../helper/MetaHelper';
import LoadingBox from '../LoadingBox';
import { isEmptyObject } from '../../helper/data-helper';
import {
  actorOnDispatch,
  actorDispatch,
  actorSetActionValue,
  GridDataInterface,
} from '../../type/actor-setup';
import { apiRequestResultHandler } from '../../helper/crud-api.helper';
import { prepareActionBarProps, requestListData } from '../list/list.helper';

import type { GeneralMetaData } from '../../helper/Types';
import type {
  MapGridDataInterface,
  MapConfig,
  MapControllerPropsInterface,
} from './map.type';
import type { MapOptionsInterface } from './leaflet-map';
import type { FinalFiltersType } from '../filter-form';
import { tehranLatLong } from './leaflet-map/leaflet-map.controller';

const MapController: FC<MapControllerPropsInterface> = props => {
  const { metaData, resource, isMultiTab } = props;

  const [apiData, setApiData] = useState<Record<string, unknown>[] | undefined>(
    undefined,
  );
  const [gridData, setGridData] = useState<MapGridDataInterface | undefined>(
    undefined,
  );

  const [hideGrid, setHideGrid] = useState<boolean>(true);
  const [clusterMode, setClusterMode] = useState<boolean>(false);
  const currentLatAndLng: { lat: number; lng: number } = { lat: 0, lng: 0 };

  apiData?.forEach(item => {
    if (
      item[metaData?.mapConfig?.latitude + ''] &&
      item[metaData?.mapConfig?.longitude + '']
    ) {
      currentLatAndLng.lat = Number(item[metaData?.mapConfig?.latitude + '']) ?? 0;
      currentLatAndLng.lng = Number(item[metaData?.mapConfig?.longitude + '']) ?? 0;
    }
  });

  const lat = currentLatAndLng.lat == 0 ? tehranLatLong[0] : currentLatAndLng.lat;
  const lng = currentLatAndLng.lng == 0 ? tehranLatLong[1] : currentLatAndLng.lng;

  const mapOptions = useRef<MapOptionsInterface>({
    zoom: 16,
    center: {
      lat: lat,
      lng: lng,
    },
  });

  useEffect(() => {
    mapOptions.current = {
      zoom: 16,
      center: {
        lat: lat,
        lng: lng,
      },
    };
  }, [currentLatAndLng]);

  const listRequestIdAccessRef = useRef<string | null>(null);

  const onFetchDataFailure = () => {
    // TODO: should handle error and show error message or dispatch error and handle in on dispatch with relation failure handler
  };

  /**
   * get fresh list data and call success or failure handlers
   * @function refreshList
   * @returns {void}
   */
  const refreshMap = () => {
    if (!resource) return;

    requestListData(
      resource,
      'Map', // <= listType
      null, // <= parentIdentifier
      null, // <= childFieldName
      null, // <= parentFieldName
      metaData as GeneralMetaData,
      {
        pagination: {
          page: 1,
          perPage: 9999,
        },
      }, // <= params
      // undefined, // <= params
      apiRequestResultHandler, // <= onSuccess
      onFetchDataFailure, // <= onFailure
      true, // <= isListMode
    );
  };

  useEffect(() => {
    refreshMap();

    actorOnDispatch(
      'gridData',
      gridData => {
        if (!resource) return;

        const _listData: GridDataInterface = gridData?.[resource];

        const prevRequestId = listRequestIdAccessRef.current;
        const nextRequestId = _listData.lastRequestId;

        if (prevRequestId !== nextRequestId) {
          setApiData(_listData?.data);
          listRequestIdAccessRef.current = _listData.lastRequestId ?? null;
        }
      },
      { callerScopeName: 'MapController' },
    );

    actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>) => {
        const filter = finalFilters?.[resource!];
        if (filter) {
          actorSetActionValue('gridData', filter, {
            path: `${resource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });

          refreshMap();
        }
      },
      { preserve: false },
    );
  }, []);

  useEffect(() => {
    prepareDataForGrid(apiData);
  }, [apiData]);

  /**
   * @function prepareDataForGrid
   * @param {Record<string, unknown>[]} data
   * @returns { void }
   */
  const prepareDataForGrid = (data: Record<string, unknown>[] | undefined): void => {
    const preparedData = {};
    const preparedIds: Array<number> = [];

    lodashMap(data, row => {
      const id = row?.id ?? Math.round(Math.random() * 100000);
      preparedData[id] = row;
      preparedIds.push(id);
    });

    setGridData({
      dataForGrid: preparedData,
      dataMapIds: preparedIds,
    });
  };

  /**
   * @function mapConfigColumns
   * @returns { mapConfig }
   */
  const mapConfigColumns = (): MapConfig => {
    return _.mapValues(metaData?.mapConfig, _.method('toLowerCase'));
  };

  /**
   * @function prepareMapGridColumns
   * @returns { Record<string, unknown>[] }
   */
  const prepareMapGridColumns = (): Record<string, unknown>[] => {
    if (isEmptyObject(metaData)) {
      return [{}];
    }

    const columns = getGridColumns({ metaData });
    const { markerToolTip } = mapConfigColumns();

    return columns?.filter(column => column.id != markerToolTip);
  };

  /**
   * @function handleGridRowClick
   * @params { Record<string, unknown> }row
   * @returns { void }
   */
  const handleGridRowClick = (row: Record<string, unknown>): void => {
    const { latitude, longitude } = mapConfigColumns();

    mapOptions.current = {
      zoom: 20,
      center: {
        lat: row?.[latitude] as number,
        lng: row?.[longitude] as number,
      },
    };

    actorDispatch('signal', 'reloadMap');
  };

  /**
   * toggle show grid in map view
   * @function onShowGridToggleClick
   * @returns {void} void
   */
  const onShowGridToggleClick = (): void => {
    setHideGrid(prevState => !prevState);
  };

  /**
   * toggle show grid in map view
   * @function onChangeClusterModeClick
   * @returns {void} void
   */
  const onChangeClusterModeClick = (): void => {
    setClusterMode(prevState => !prevState);
  };

  const customFunctions: Record<string, () => void> = { onChangeClusterModeClick };

  if (!!metaData?.mapConfig?.showInGrid) {
    customFunctions.onShowGridToggleClick = onShowGridToggleClick;
  }

  const actionBarProps = {
    ...prepareActionBarProps('Map', resource, metaData, refreshMap, customFunctions),
    hideGrid,
    clusterMode,
  };

  if (!metaData) {
    return <LoadingBox />;
  }

  return (
    <MapView
      metaData={metaData}
      data={apiData}
      resource={resource}
      mapOptions={mapOptions}
      handleGridRowClick={handleGridRowClick}
      gridColumns={prepareMapGridColumns()}
      gridData={gridData}
      actionBarProps={actionBarProps}
      hideGrid={hideGrid}
      clusterMode={clusterMode}
      isMultiTab={isMultiTab}
    />
  );
};

export default memo(MapController);
