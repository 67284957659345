import { clone, isEmpty, isEmptyAndNotEqualToNull } from '../../helper/data-helper';
import {
  actorGetActionValue,
  FilterFormFieldInterface,
} from '../../type/actor-setup';

import { getFilterColumns } from '../../helper/MetaHelper';
import { getTypeByField, DATE_FIELD } from '../../helper/InputHelper';

import {
  dateWithOptionalTimeRegexString,
  prepareDateTimeFilterValue,
} from '../../helper/FilterHelper';
import { InputRefContent } from '../form';

import type { FieldType, GeneralMetaData } from '../../helper/Types';
import type {
  FilterDataInterface,
  FilterItemBaseType,
  FilterItemFinalFormatType,
  FinalFiltersType,
} from './filter-form.type';

export enum FilterValueStructureEnum {
  KEY = 0,
  OPERATOR = 1,
  VALUE = 2,
  SECOND_VALUE = 3,
}
export const MAX_SAFE_FILTERS_COUNT = 11;

/**
 * @function getFilterFormFields
 * @param { string } resource
 * @returns { Record<string, FilterFormFieldInterface> | null } Object or null
 */
export const getRequiredFilters = (
  resource: string,
  locale: string,
): {
  fields: FieldType[];
  formattedFields: Record<string, FilterFormFieldInterface> | null;
} => {
  const currentMetaData = actorGetActionValue('metaData', resource);

  if (currentMetaData == null) {
    return {
      fields: [],
      formattedFields: null,
    };
  }

  const fields: FieldType[] = [];
  const requiredFilterFields: Record<string, FilterFormFieldInterface> = {};

  const _currentMetaData = currentMetaData as unknown as GeneralMetaData;

  const filterFields = getFilterColumns(_currentMetaData);
  // The simple table does not have any required filter
  if (filterFields == null || isEmpty(_currentMetaData.reportId)) {
    return {
      fields: [],
      formattedFields: {},
    };
  }

  for (const field of filterFields) {
    if (!field.required) continue;

    const clonedField = clone(field);

    clonedField.source = field.resource ?? field.name;
    clonedField.defaultOperator =
      field.defaultOperator ?? field.dataType.defaultOperator ?? '';
    clonedField.onlyEqualCondition = field.onlyEqualCondition;
    clonedField.disabled = false;
    clonedField.readOnly = false;
    // clonedField.key = parameter.key;
    clonedField.label =
      clonedField['translatedCaption']?.[locale] ?? clonedField.caption;

    fields.push(clonedField);

    let finalOperator = clonedField.defaultOperator.toLowerCase();
    if (clonedField.onlyEqualCondition || clonedField.defaultOperator === 'Empty') {
      finalOperator = 'equals';
    }

    requiredFilterFields[clonedField.key ?? clonedField.name] = {
      fieldData: clonedField,
      value: [clonedField.key ?? clonedField.name, finalOperator, ''],
    };
  }

  return {
    fields,
    formattedFields: requiredFilterFields,
  };
};

/**
 * @function getFinalSanitizedFilters
 * @param { (FilterItemBaseType[] | FilterItemBaseType)[] } filters
 * @returns { FinalFiltersType } Array of filter values
 */
export const getFinalSanitizedFilters = (
  filters: ((string | FilterItemBaseType)[] | FilterItemBaseType)[],
): FinalFiltersType => {
  const finalFilters: FinalFiltersType = [];

  for (const filter of filters) {
    const _filter = filter as FilterItemBaseType;
    const filterValue = _filter[FilterValueStructureEnum.VALUE];
    const filterOperator = _filter[FilterValueStructureEnum.OPERATOR];
    const filterKey = _filter[FilterValueStructureEnum.KEY];

    if (isEmptyAndNotEqualToNull(filterValue)) continue;

    if (filterOperator === 'between' || filterOperator === 'notbetween') {
      const values = (filterValue as string).split(',');
      if (
        isEmptyAndNotEqualToNull(values[0]) ||
        isEmptyAndNotEqualToNull(values[1])
      ) {
        continue;
      }

      finalFilters.push([filterKey, filterOperator, values[0], values[1]]);
    } else if (filterOperator === 'noValue') {
      _filter[FilterValueStructureEnum.OPERATOR] = 'equals';
      finalFilters.push(filter);
    } else {
      finalFilters.push(filter);
    }

    finalFilters.push('and');
  }

  finalFilters.pop(); // remove last extra `and`

  return finalFilters;
};

/**
 * @function generateFilterKeyForAppSetting
 * @param { resource } resource
 * @param { string } name
 * @returns { string } string
 */
export const generateFilterKeyForAppSetting = (
  resource: string,
  name: string,
): string => {
  return `new-filter-format_${name}_${resource}`;
};

/**
 * get field info from meta and assign it to fieldData property
 * @function updateFilterFieldDataByLatestFields
 * @param {FieldType[]} fields
 * @param {Record<string, FilterFormFieldInterface>} filterData
 * @returns {Record<string, FilterFormFieldInterface>} An object contains `fieldData`s, updated by data of current fields
 */
export const updateFilterFieldDataByLatestFields = (
  fields: FieldType[],
  filters: Record<string, FilterFormFieldInterface>,
): Record<string, FilterFormFieldInterface> => {
  const updatedFilters = { ...filters };

  for (const filterKey in filters) {
    const targetFieldIndexInClonedOriginalFields = fields.findIndex(
      _field => _field.name === filterKey || _field.key === filterKey,
    );
    if (targetFieldIndexInClonedOriginalFields === -1) {
      // Means the field isn't exist in the current fields, shouldn't remain in the filter data anyway
      delete updatedFilters[filterKey];
      continue;
    }

    const targetField = fields[targetFieldIndexInClonedOriginalFields];
    const finalKey = targetField.key ?? targetField.name;

    /**
     * In `report`s we work by `parameterKey` or `key`
     * To support previous saved settings we have to set incoming filters by `parameterKey`s
     */
    updatedFilters[finalKey] = {
      value: filters[filterKey].value,
      fieldData: {
        ...targetField,
        ...filters[filterKey].fieldData, // To keep `key` or `name` saved by settings
      },
    };

    if (filters[filterKey].fullDropdownItem) {
      // prettier-ignore
      updatedFilters[finalKey].fullDropdownItem = filters[filterKey].fullDropdownItem;
    }
  }

  return updatedFilters;
};

/**
 * generateFilterValueForAppSetting : remove unused property from filter object
 * @param {Record<string, FilterFormFieldInterface>} filterData
 * @returns Record<string, FilterFormFieldInterface>
 */
export const generateFilterValueForAppSetting = (
  filterData: Record<string, FilterFormFieldInterface>,
): Record<string, FilterFormFieldInterface> => {
  // prettier-ignore
  const finalFilterSetting: Record<string, FilterFormFieldInterface> = clone(filterData); // Without cloning, the 'filterDataRef.current' will change settings in `actor` by mistake
  const filterDataKeys = Object.keys(finalFilterSetting);

  for (const filterKey of filterDataKeys) {
    finalFilterSetting[filterKey].fieldData = {
      name: filterData[filterKey].fieldData.name,
      id: filterData[filterKey].fieldData.id,
    };
  }

  return finalFilterSetting;
};

/**
 * @function getFormattedFilters
 * @param { FilterDataInterface } filterData
 * @returns { FinalFiltersType } an array of strings
 */
export const getFormattedFilters = (
  filterData: FilterDataInterface,
): FinalFiltersType => {
  const finalFilters: FinalFiltersType = [];

  const filterDataKeys = Object.keys(filterData);
  for (const filterKey of filterDataKeys) {
    const [filterNameOrKey, filterOperator, filterValue] =
      filterData[filterKey].value;

    if (isEmptyAndNotEqualToNull(filterValue)) continue; // We want to send `null` value as a valid value

    if (filterOperator === 'between' || filterOperator === 'notbetween') {
      finalFilters.push(
        ...sanitizeInRangeFilters(
          filterData[filterKey].fieldData as FieldType,
          filterOperator,
          filterValue as string,
        ),
        'and',
      );
      continue;
    }

    if (filterOperator === 'noValue') {
      finalFilters.push([filterNameOrKey, 'equals', filterValue], 'and');
      continue;
    }

    finalFilters.push(filterData[filterKey].value, 'and');
  }

  if (finalFilters.at(-1) === 'and') {
    finalFilters.pop(); // Remove last `and`
  }

  return finalFilters;
};

/**
 * @function createMultiselectInputFilters
 * @param filterData
 * @param isReport
 * @returns { (FilterItemBaseType | string)[] } an array
 */
export const createMultiselectInputFilters = (
  filterData: [string, string, unknown],
  isReport = false,
): (FilterItemBaseType | string)[] => {
  const multiselectFilter: (FilterItemBaseType | string)[] = [];

  const filterValues = String(filterData[FilterValueStructureEnum.VALUE]).split(',');
  const filterOperator = filterData[FilterValueStructureEnum.OPERATOR];
  const filterName = filterData[FilterValueStructureEnum.KEY];

  if (isReport) {
    // TODO: complete `report` type, check API
  } else {
    for (const value of filterValues) {
      multiselectFilter.push([filterName, filterOperator, value]);
      multiselectFilter.push('or');
    }
  }

  multiselectFilter.pop(); // remove last extra `or`
  return multiselectFilter;
};

/**
 * @function getFormattedDateFilterData
 * @param {string} filterKey A key to check is it a `date` field or not
 * @param {Record<string, FilterFormFieldInterface>} filterData An object of current filters
 * @returns {FilterItemBaseType} An array of formatted filters
 */
export const getFormattedDateFilterData = (
  filterKey: string,
  filterData: Record<string, FilterFormFieldInterface>,
): FilterItemBaseType => {
  const key = filterData[filterKey].value[FilterValueStructureEnum.KEY];
  const operator = filterData[filterKey].value[FilterValueStructureEnum.OPERATOR];
  const value = filterData[filterKey].value[FilterValueStructureEnum.VALUE];

  /**
   * `prepareDateTimeFilterValue` Result => [filterKey, filterOperator, filterValue]
   * ### Pay attention: In some situation such as `filterOperator` is `between`, we have: [filterKey, filterOperator, filterValue, filterValue2],
   *                    In other situations: [filterKey, filterOperator, filterValue]
   */
  let formattedFilterData: string[] = [];

  const prepareDateTimeFilterArg: string[] = [key, operator];
  if (operator === 'between' || operator === 'notbetween') {
    const values = (value as string | null)?.split(',') ?? [];
    if (values.length === 1) {
      prepareDateTimeFilterArg.push(values[0], values[0]); // To convert `2023-8-12` to `2023-8-12 00:00:00, 2023-8-12 23:59:59`
    } else if (values.length === 2) {
      prepareDateTimeFilterArg.push(values[0], values[1]);
    }
  } else {
    prepareDateTimeFilterArg.push(value as string);
  }

  formattedFilterData = prepareDateTimeFilterValue(prepareDateTimeFilterArg);

  if (formattedFilterData[FilterValueStructureEnum.SECOND_VALUE] === undefined) {
    return [
      key,
      formattedFilterData[FilterValueStructureEnum.OPERATOR],
      formattedFilterData[FilterValueStructureEnum.VALUE],
    ];
  }

  return [
    key,
    formattedFilterData[FilterValueStructureEnum.OPERATOR],
    `${formattedFilterData[FilterValueStructureEnum.VALUE]},${
      formattedFilterData[FilterValueStructureEnum.SECOND_VALUE]
    }`,
  ];
};

/**
 * It only for merging a request parameters used by `requestListData` function (e.g. { pagination: {...}, filter: [...] })
 * Always we have `filter` as array format
 * This function merges nested filters correctly
 * @function customizeMergingObjects
 * @param {unknown} objValue
 * @param {unknown} value
 * @returns {unknown} return a value with `unknown` type
 */
export const customizeMergingRequestParameters = (
  srcValue: unknown,
  newValue: unknown,
): unknown => {
  if (!Array.isArray(srcValue)) {
    return newValue;
  }

  if (srcValue.length > 0) {
    srcValue.push('and');
  }

  const _newValue = newValue as FilterItemFinalFormatType[];
  const _srcValue = srcValue as FilterItemFinalFormatType[];
  for (let index = 0; index < _newValue.length; index++) {
    if (_newValue[index] === 'and') continue;

    const targetIndex = _srcValue.findIndex(
      filter =>
        filter[FilterValueStructureEnum.KEY] ===
        _newValue[index][FilterValueStructureEnum.KEY],
    );

    if (targetIndex > -1) {
      _srcValue[targetIndex] = _newValue[index];
    } else {
      _srcValue.push(_newValue[index], 'and');
    }
  }

  if (_srcValue.at(-1) === 'and') {
    _srcValue.pop(); // Remove last extra 'and'
  }

  return _srcValue;
};

/**
 * @function emptyFilterInputValue
 * @returns {void} void
 */
export const emptyFilterInputValue = (inputName: string, resource: string): void => {
  const inputsRef = actorGetActionValue('inputsRef', resource) as Record<
    string,
    InputRefContent
  > | null;
  if (inputsRef?.[inputName] == null) return;

  (inputsRef[inputName].setFirstInputValue as (value: unknown) => void)('');
  (inputsRef[inputName].setSecondInputValue as (value: unknown) => void)('');
};

/**
 * @function emptyFilterAllInputsValues
 * @returns {void} void
 */
export const emptyAllFilterInputsValues = (resource: string): void => {
  const inputsRef = actorGetActionValue('inputsRef', resource) as
    | Record<string, InputRefContent>
    | undefined;

  if (inputsRef) {
    const inputsRefKeys = Object.keys(inputsRef);
    for (const inputsRefKey of inputsRefKeys) {
      (inputsRef[inputsRefKey].setFirstInputValue as (value: unknown) => void)('');
      (inputsRef[inputsRefKey].setSecondInputValue as (value: unknown) => void)('');
    }
  }
};

/**
 * @function sanitizeInRangeFilters
 * @param {FieldType} field
 * @param {string} operator
 * @param {string | null} value
 * @returns {FinalFiltersType} an array of formatted filters
 */
export const sanitizeInRangeFilters = (
  field: FieldType,
  operator: string,
  value: string | null,
): FinalFiltersType => {
  if (value == null) return []; // Invalid value
  const _value = sanitizeInRangeFilterValue(value);

  const fieldKey = field.key ?? field.name;
  const fieldDataType = getTypeByField(field);

  if (fieldDataType === DATE_FIELD) {
    const formattedDateFilterData = getFormattedDateFilterData(fieldKey, {
      [fieldKey]: {
        fieldData: field,
        value: [fieldKey, operator, _value],
      },
    });

    const _formattedOperator =
      formattedDateFilterData[FilterValueStructureEnum.OPERATOR];

    const _formattedValue = formattedDateFilterData[FilterValueStructureEnum.VALUE];

    const values = (_formattedValue as string).split(',');

    return [[fieldKey, _formattedOperator, values[0], values[1]]];
  }

  const values = _value.split(',');
  return [[fieldKey, operator, values[0], values[1]]];
};

/**
 * @function sanitizeInRangeFilterValue
 * @param {string | null} value
 * @returns {string} a string
 */
export const sanitizeInRangeFilterValue = (value: string | null): string => {
  if (value == null) return ''; // Invalid value

  if (new RegExp(dateWithOptionalTimeRegexString, 'g').test(value)) {
    const _dateValueArray = value.split(' '); // To get `2023-08-28` from `2023-08-28 00:00:000`
    return _dateValueArray[0];
  }

  let _value = '';

  const valueArray = value.split(',');
  if (valueArray.length === 2) {
    if (!isEmpty(valueArray[0])) _value += valueArray[0];
    if (!isEmpty(valueArray[1])) _value += `,${valueArray[1]}`;
    return _value;
  }

  return _value;
};

/**
 * Creates a key/value pair object of `filterKey` and `filterValue`
 * @function formatFilterFormData
 * @param {Record<string, FilterFormFieldInterface>} _filterData
 * @returns {Record<string, unknown>} an object
 */
export const formatFilterFormData = (
  _filterData: Record<string, FilterFormFieldInterface>,
): Record<string, unknown> => {
  const formattedFilterData = {};
  for (const [filterKey, filterInfo] of Object.entries(_filterData)) {
    // prettier-ignore
    formattedFilterData[_filterData[filterKey].fieldData.name as string] = filterInfo.value[FilterValueStructureEnum.VALUE];
  }

  return formattedFilterData;
};
