import { GET_MANY_REFERENCE, GET_LIST } from 'react-admin';
import querystring from 'qs';
import lodashMergeWith from 'lodash/mergeWith';

import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import {
  CONFIG_LIST_LAST_FILTER,
  CONFIG_LIST_PER_PAGE,
  CONFIG_LIST_SORT,
  parseJSON,
} from '../../core/configProvider';
import {
  getDefaultSort,
  getFieldsById,
  getFilterColumns,
  getGridColumns,
  prepareReportFilter,
} from '../../helper/MetaHelper';
import { getProcessList, getRequiredFields } from '../../helper/meta-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  FilterFormFieldInterface,
  FormKeyMode,
  GetListRequestParametersInterface,
  GridDataInterface,
  RecordKeyMode,
  RequestParametersInterface,
} from '../../type/actor-setup';
import { getRelationFieldsForDisplay } from '../relation-panel/relation-panel.helper';
import {
  clone,
  isEmptyAndNotEqualToNull,
  isEmptyObject,
} from '../../helper/data-helper';
import {
  customizeMergingRequestParameters,
  FilterValueStructureEnum,
  generateFilterKeyForAppSetting,
  getFormattedFilters,
  updateFilterFieldDataByLatestFields,
} from '../filter-form/filter-form.helper';

import { findRowStateColorField } from '../../helper/RowColorHelper';
import { updateUrlQueryParams } from '../../helper/general-function-helper';
import { getCurrentResource } from '../../helper/ActorHelper';

import type { ActionBarControllerProps } from '../list-toolbar/action-bar';
import type { FieldType, GeneralMetaData } from '../../helper/Types';
import type {
  GetDefaultListRequestParameters,
  PrepareActionBarProps,
  PreparePaginationProps,
  PrepareSettingBarProps,
  RequestListData,
} from './list.type';
import type {
  CrudGetListApiPayload,
  CrudGetManyReferenceApiPayload,
} from '../../type/data-provider';
import type { FilterItemFinalFormatType, FinalFiltersType } from '../filter-form';
import type { PaginationControllerInterface } from '../list-toolbar/pagination';
import type { ShowImageDialogControllerInterface } from '../show-image-dialog';

export const prepareActionBarProps: PrepareActionBarProps = (
  listType,
  resource,
  metaData,
  getListDataAndRefresh,
  customFunctions,
  rootResource, // It's so important, we need main source to find filters in `action-bar`s
) => {
  const hasAccessPath = !(
    listType === 'Calendar' ||
    listType === 'Map' ||
    listType === 'Pivot' ||
    listType === 'Print'
  );

  const metaDataConfig = (metaData as GeneralMetaData)?.config ?? {
    allowAdd: false,
    allowDelete: false,
  };

  const processList = getProcessList(metaData as GeneralMetaData);

  const isSelectedItemsCount = listType === 'Calendar';

  const tableRowColors = [];

  const actionBarProps: ActionBarControllerProps = {
    hasAccessPath,
    resource,
    metaData,
    quickCreateMustRefresh: true, // TODO: its disable in dropdown but check if its necessary or could be deleted
    hasCreate: metaDataConfig.allowAdd,
    hasDelete: metaDataConfig.allowDelete,
    processList,
    isSelectedItemsCount,
    tableRowColors,
    getListDataAndRefresh,
    rootResource,
    ...customFunctions,
  };
  return actionBarProps;
};

export const prepareSettingBarProps: PrepareSettingBarProps = (
  resource,
  metaData,
  fields,
  rootResource,
) => {
  return { resource, metaData, fields, rootResource };
};

export const preparePaginationProps: PreparePaginationProps = (
  resource,
  setPage,
  setPerPage,
  isLoading,
  isCompactMode,
  isRelation,
) => {
  const listParameters = actorGetActionValue('gridData')?.[resource];

  const paginationData: PaginationControllerInterface = {
    total: 0,
    page: 1,
    perPage: 25,
    setPage,
    setPerPage,
    isLoading,
    isCompactMode,
    isRelation,
  };

  if (!isEmptyObject(listParameters)) {
    paginationData.total = listParameters!.totalCount ?? 0;

    if (!isEmptyObject(listParameters!.requestParameters?.pagination)) {
      paginationData.page = listParameters!.requestParameters!.pagination.page;
      paginationData.perPage = listParameters!.requestParameters!.pagination.perPage;
    }
  }

  return paginationData;
};

/**
 * change sort in grid data object in actor, set it on webSetting and run refreshCallback to request data with new parameters
 * @function setSort
 * @param {string} field
 * @returns {void} void
 */
export const setListSort = (
  fieldName: string,
  resource: string,
  refreshCallback: () => void,
): void => {
  let newSort = {};

  const prevSort = actorGetActionValue(
    'gridData',
    `${resource}.requestParameters.sort`,
  ) as unknown as RequestParametersInterface['sort'];

  if (prevSort?.field === fieldName) {
    newSort = {
      field: fieldName,
      order: prevSort.order === 'asc' ? 'desc' : 'asc',
    };
  } else {
    newSort = {
      field: fieldName,
      order: 'desc',
    };
  }

  actorSetActionValue('gridData', newSort, {
    path: `${resource}.requestParameters.sort`,
  });

  setAppSettings({
    key: `${CONFIG_LIST_SORT}_${resource}`,
    value: newSort,
    forUser: true,
  });

  refreshCallback();
};

/**
 * @function isGadgetList
 * @param {string} resource
 * @returns {boolean}
 */
export const isGadgetList = (resource: string): boolean => {
  if (resource.indexOf('gadgets') > -1 || resource.indexOf('dashinfo') > -1) {
    return true;
  }
  return false;
};

/**
 * change page in grid data object in actor and run refreshCallback to request data with new parameters
 * @function setPage
 * @param {string} page
 * @returns {function}
 */
export const setListPage = (
  page: number,
  resource: string,
  refreshCallback: () => void,
): void => {
  const prevPage = actorGetActionValue(
    'gridData',
    `${resource}.requestParameters.pagination.page`,
  ) as unknown as number;
  if (prevPage === page) return;

  actorSetActionValue('gridData', page, {
    path: `${resource}.requestParameters.pagination.page`,
  });

  refreshCallback();
};

/**
 * change perPage in grid data object in actor, set it on webSetting and run refreshCallback to request data with new parameters
 * @function serPerPage
 * @param {number} perPage
 * @returns {function}
 */
export const setListPerPage = (
  perPage: number,
  resource: string,
  refreshCallback: () => void,
): void => {
  const prevPerPage = actorGetActionValue(
    'gridData',
    `${resource}.requestParameters.pagination.perPage`,
  ) as unknown as number;
  if (prevPerPage === perPage) return;

  actorSetActionValue('gridData', 1, {
    path: `${resource}.requestParameters.pagination.page`,
  });
  actorSetActionValue('gridData', perPage, {
    path: `${resource}.requestParameters.pagination.perPage`,
  });

  if (perPage !== -1) {
    setAppSettings({
      key: `${CONFIG_LIST_PER_PAGE}_${resource}`,
      value: perPage,
      forUser: true,
    });
  }

  refreshCallback();
};

/**
 * prepare default parameters for get list request
 * @function getDefaultListRequestParameters
 * @param {string} resource
 * @param {RelationType | MetaDataViewTypes} listType
 * @param {object} metaData
 * @returns {object}
 */
const getDefaultListRequestParameters: GetDefaultListRequestParameters = (
  resource,
  listType,
  metaData,
) => {
  let defaultRequestParameters:
    | RequestParametersInterface
    | GetListRequestParametersInterface;

  // get user config
  const userChooseSort = getAppSettings<{
    field: string;
    order: 'desc' | 'asc';
  }>(`${CONFIG_LIST_SORT}_${resource}`, true).value;

  const perPagePagination = getAppSettings<number>(
    `${CONFIG_LIST_PER_PAGE}_${resource}`,
    true,
  ).value;

  if (listType === 'report' || listType === 'multiReport') {
    // get parent resource
    const currentResource = actorGetActionValue('resources')?.current.value;

    // get parent record from parent resource
    const parentRecord =
      actorGetActionValue('record', currentResource)?.[FormKeyMode.ROOT]?.[
        RecordKeyMode.FULL
      ] ?? {};
    // prepare report parameters
    defaultRequestParameters = {
      pagination:
        perPagePagination === null || Number.isNaN(perPagePagination)
          ? { page: 1, perPage: 25 } // default
          : { page: 1, perPage: perPagePagination }, // from setting
      sort:
        userChooseSort ??
        (getDefaultSort(metaData) || {
          field: 'id',
          order: 'desc',
        }),

      filter: prepareReportFilter({
        meta: metaData,
        record: parentRecord,
        formatByAnd: true,
      }),
    };
  } else {
    defaultRequestParameters = {
      pagination:
        perPagePagination === null || Number.isNaN(perPagePagination)
          ? { page: 1, perPage: 100 } // default
          : { page: 1, perPage: perPagePagination }, // from setting
      sort: userChooseSort ?? {
        field: metaData?.config?.sort?.fieldName ?? 'id',
        order: metaData?.config?.sort?.type ?? 'desc',
      },
      filter: [],
    };
  }

  return defaultRequestParameters;
};

/**
 * get list data and put it in actor
 * @function requestRelationData
 * @param {string} resource
 * @param {string} parentIdentifier
 * @param {string} childFieldName
 * @param {GeneralMetaData | null} metaData
 * @param { Partial<RequestParametersInterface>} params
 * @param { function } onSuccess
 * @param { function } onFailure
 * @param { boolean} isListMode
 * @returns {void} void
 */
export const requestListData: RequestListData = (
  resource,
  listType,
  parentIdentifier,
  childFieldName,
  parentFieldName,
  metaData,
  params = {},
  onSuccess = undefined,
  onFailure = undefined,
  isListMode = false,
  isMultiResult = false,
  rootResource = undefined, // In `multiResult`s is parent resource
): void => {
  if (!resource || !metaData) return;

  if (isMultiResult && !rootResource) {
    // prettier-ignore
    console.warn('`requestListData`: `isMultiResult` is `true` but `rootResource` has not been set');
    return;
  }

  const filterResource = isMultiResult ? rootResource! : resource;

  const requestParameters = actorGetActionValue(
    'gridData',
    `${resource}.requestParameters`,
  ) as RequestParametersInterface | GetListRequestParametersInterface | null;

  const allRequiredFiltersAreValid = checkAllRequiredFiltersAreValid(filterResource);
  if (!allRequiredFiltersAreValid) {
    actorDispatch('loading', false, { path: `${resource}` });
    return;
  }

  const defaultListRequestParameters = getDefaultListRequestParameters(
    resource,
    listType,
    metaData,
  );
  const searchFiltersRequestParameters = (actorGetActionValue(
    'gridData',
    `${filterResource}.requestParameters.searchFilters`,
  ) ?? []) as (FilterItemFinalFormatType | FilterItemFinalFormatType[])[];

  let finalRequestParameters = lodashMergeWith(
    defaultListRequestParameters,
    {
      ...requestParameters,
      ...params,
      searchFilters: searchFiltersRequestParameters,
    },
    customizeMergingRequestParameters,
  );

  actorSetActionValue('gridData', clone(finalRequestParameters), {
    path: `${resource}.requestParameters`,
  });

  //Get filter from rootResource in multi tab reports
  if (isMultiResult) {
    const filterRequestParameters = (actorGetActionValue(
      'gridData',
      `${filterResource}.requestParameters`,
    )?.filter ?? []) as (FilterItemFinalFormatType | FilterItemFinalFormatType[])[];

    finalRequestParameters = lodashMergeWith(
      finalRequestParameters,
      {
        filter: filterRequestParameters,
        searchFilters: searchFiltersRequestParameters,
      },
      customizeMergingRequestParameters,
    );
  }

  if (isListMode && Array.isArray(finalRequestParameters?.filter)) {
    updateUrlQueryParams({
      filter: finalRequestParameters!.filter,
    });
  }

  if (isListMode || listType === 'report' || listType === 'multiReport') {
    actorDispatch('loading', true, { path: resource, disableDebounce: true });
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: isListMode ? 'list' : 'relation',
        resource,
        requestParameters: finalRequestParameters,
        onSuccess,
        onFailure,
      } as CrudGetListApiPayload,
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'requestListData',
      },
    );
  } else {
    if (!parentIdentifier || !childFieldName) {
      console.log(
        'ERROR: parentIdentifier or childFieldName is empty in relation mode',
      );
    }
    actorDispatch(
      'crudAction',
      {
        type: GET_MANY_REFERENCE,
        entity: 'relation',
        requestParameters: finalRequestParameters,
        resource,
        id: parentIdentifier,
        target: childFieldName,
        parentFieldName,
        onSuccess,
        onFailure,
      } as CrudGetManyReferenceApiPayload,
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'requestListData(2)',
      },
    );
  }
};

/**
 * call show image dialog action with redux
 * @function showImageDialog
 * @param {object} detail
 * @returns {void} void
 */
export const showImageDialog = (
  imageDialogData: ShowImageDialogControllerInterface,
): void => {
  actorDispatch(
    'imageDialog',
    { ...imageDialogData, isOpenImageDialog: true },
    { replaceAll: true },
  );
};

/**
 * Prepare list grid columns
 * @function getFieldsForDisplay
 * @param {number[] | null} defaultSelected default columns
 * @param {number[] | null} userSelected user selected columns in setting
 * @param {GeneralMetaData} metaData
 * @param {number[] | null} disabledFieldList disabled columns
 * @returns {FieldType[]}
 */
export const getFieldsForDisplay = (
  defaultSelected: number[] | null,
  userSelected: number[] | null,
  metaData: GeneralMetaData,
  disabledFieldList?: { [x: number]: boolean } | null,
  isListMode = false,
  isTodoMode = false,
): Array<FieldType> => {
  if (isListMode && isTodoMode) {
    return getGridColumns(metaData);
  }

  // if user has selected column order
  if (userSelected && userSelected.length) {
    return getFieldsById(metaData, userSelected);
  }

  // or admin as selected default order
  if (defaultSelected && defaultSelected.length) {
    return getFieldsById(metaData, defaultSelected);
  }

  if (isListMode) {
    return getGridColumns({ metaData, filterFirstFive: true });
  } else {
    return getRelationFieldsForDisplay(metaData, disabledFieldList);
  }
};

/**
 * @function checkAllRequiredFiltersAreValid
 * @param { string } resource
 * @returns boolean
 */
export const checkAllRequiredFiltersAreValid = (resource: string): boolean => {
  const metaData = actorGetActionValue(
    'metaData',
    resource,
  )! as unknown as GeneralMetaData;

  if (isEmptyObject(metaData)) {
    console.warn(
      `checkAllRequiredFiltersAreValid: metaData not found in ${resource}`,
    );
    return false;
  }

  const gridData = actorGetActionValue(
    'gridData',
    resource,
  ) as GridDataInterface | null;

  const filters = gridData?.requestParameters?.filter ?? [];
  const requiredFields = getRequiredFields(metaData);
  let allRequiredFiltersAreValid = true;

  for (const field of requiredFields) {
    const filterData = filters.find(
      item => Array.isArray(item) && (item[0] == field.key ?? field.name),
    );
    if (
      filterData == null ||
      (Array.isArray(filterData) &&
        isEmptyAndNotEqualToNull(filterData[FilterValueStructureEnum.VALUE])) // `null` is a valid value
    ) {
      allRequiredFiltersAreValid = false;
      break;
    }
  }

  return allRequiredFiltersAreValid;
};

/**
 * @function initializeLastFilters
 * @param { GeneralMetaData } metaData
 * @returns void
 */
export const initializeLastFilters = (metaData: GeneralMetaData): void => {
  const resource = getCurrentResource()!.value;
  const rootResource = actorGetActionValue('resources')?.stack[0].value ?? '';

  const isMultiReport =
    metaData.reportType === 'ParentChild' || metaData.reportType === 'MultiResult';

  let finalResource = resource;
  if (isMultiReport) {
    finalResource = rootResource;
  }

  const finalFilter: FinalFiltersType = [];

  const urlSearchSection =
    actorGetActionValue('urlInfo')?.location.href.split('?')[1];
  const urlParameters = querystring.parse(urlSearchSection, {
    ignoreQueryPrefix: true,
  });

  /**
   * `{url}?filters=[...]` means we have to set incoming filters,
   *  otherwise we have to set filters from settings if there isn't any `filters` in `url`
   */

  const _urlFilter = parseJSON<FinalFiltersType>(urlParameters['filter']) ?? [];
  if (Array.isArray(_urlFilter) && _urlFilter.length > 0) {
    finalFilter.push(..._urlFilter);
  } else {
    const filterFormFieldsFromSettings = getAppSettings<
      Record<string, FilterFormFieldInterface>
    >(
      generateFilterKeyForAppSetting(finalResource, CONFIG_LIST_LAST_FILTER),
      true,
    ).value;

    if (filterFormFieldsFromSettings) {
      const updatedFormFields = updateFilterFieldDataByLatestFields(
        getFilterColumns(metaData) ?? [],
        filterFormFieldsFromSettings,
      );

      const formattedFilters = getFormattedFilters(updatedFormFields);
      finalFilter.push(...formattedFilters);
    }
  }

  actorSetActionValue('gridData', finalFilter, {
    path: `${finalResource}.requestParameters.filter`,
    replaceAll: true,
    callerScopeName: 'list.helper => initializeLastFilters',
  });
};
