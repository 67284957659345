import { ReactElement } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@web/editor/build/ckeditor';
import { RichTextEditorInputViewInterface } from './rich-text-editor-input.type';
import InputBase from '../../input-base';
import { useStyles } from './rich-text-editor-input.style';
import { useTranslate } from 'react-admin';

import {
  getValue,
  RICH_TEXT_EDITOR_DEFAULT_FONT,
} from '../../../core/configProvider';
import { isEmpty } from '../../../helper/data-helper';

const RichTextEditorInputView = (
  props: RichTextEditorInputViewInterface,
): ReactElement => {
  const {
    value,
    label,
    inputMessage,
    required,
    disabled,
    config,
    visibleClass,
    inputContainerClass,
    handleChange,
    handleBlur,
    field,
    defaultFont,
  } = props;
  let prevEditor: Editor = null;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <InputBase
      label={label}
      inputMessage={inputMessage}
      required={required}
      field={field}
      className={`${visibleClass} ${inputContainerClass}`}
    >
      <div className={classes.container}>
        <CKEditor
          onReady={editor => {
            // You can store the "editor" and use when it is needed.
            // Insert the toolbar before the editable area.
            editor &&
              editor.ui
                ?.getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view?.toolbar.element,
                  editor.ui.getEditableElement(),
                );

            editor.execute('fontFamily', { value: defaultFont });
            prevEditor = editor;
          }}
          onError={({ willEditorRestart }) => {
            // If the editor is restarted, the toolbar element will be created once again.
            // The `onReady` callback will be called again and the new toolbar will be added.
            // This is why you need to remove the older toolbar.
            if (willEditorRestart) {
              prevEditor?.ui.view.toolbar.element.remove();
            }
          }}
          editor={Editor}
          config={config}
          disabled={disabled}
          data={value ?? translate('dynamicInput.emptyValue')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </InputBase>
  );
};

export default RichTextEditorInputView;
