import { memo, ReactElement } from 'react';
import { MapViewPropsInterface } from './map.type';
import { LeafletMap } from './leaflet-map';
import DevExGrid from '../DevExGrid';
import { isEmbeddedPage } from '../../helper/UrlHelper';
import { useStyles } from './map.style';
import { ListToolbar } from '../list-toolbar';

const MapView = (props: MapViewPropsInterface): ReactElement => {
  const {
    handleGridRowClick,
    mapOptions,
    metaData,
    data,
    resource,
    gridColumns,
    gridData,
    actionBarProps,
    hideGrid,
    clusterMode,
    isMultiTab,
  } = props;

  const classes = useStyles();
  const isEmbedMode = isEmbeddedPage();

  return (
    <div className={classes.mainContainer}>
      {isEmbedMode || isMultiTab ? null : (
        <ListToolbar actionBarProps={actionBarProps} />
      )}
      <div className={classes.gridContainer}>
        <LeafletMap
          metaData={metaData}
          data={data}
          mapOptions={mapOptions}
          hideGrid={hideGrid}
          clusterMode={clusterMode}
        />

        {!!metaData?.mapConfig?.showInGrid && !hideGrid && (
          <DevExGrid
            isDropDown={false}
            hasList={true}
            hasShow={false}
            hasEdit={false}
            resource={resource}
            data={gridData?.dataForGrid ?? []}
            ids={gridData?.dataMapIds ?? []}
            fields={gridColumns}
            relationMode={false}
            isTopFilterOpen={false}
            isGroupingOpen={false}
            metaData={metaData}
            enableSelection={false}
            isRemoteFiltering
            onRowClick={handleGridRowClick}
            enableClientExportExcel={true}
            hasColumnChooser={true}
            isMap={true}
          />
        )}
      </div>
    </div>
  );
};

export default memo(MapView);
