import { ReactElement } from 'react';
import { Icon, IconButton, Typography, Chip } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { FileStreamInputViewInterface } from './multi-file-stream-input.type';
import { useStyles } from './multi-file-stream-input.style';
import InputBaseLabel from '../../input-base';
import FileStreamCustomChip from './multi-file-stream-custom-chip';
import Box from '@material-ui/core/Box';
import { openPreviewImage } from './multi-file-stream-input.helper';

const FileStreamInputView = (props: FileStreamInputViewInterface): ReactElement => {
  const {
    disabled,
    label,
    hint,
    required,
    name,
    onFileChange,
    inputFileRef,
    handleClick,
    value,
    inputMessage,
    visibleClass,
    inputContainerClass,
    fieldsetRef,
    onDelete,
    field,
  } = props;

  const translate = useTranslate();
  const classes = useStyles({ disabled, messageType: inputMessage?.messageType });

  return (
    <InputBaseLabel
      className={`${visibleClass} ${inputContainerClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
      field={field}
    >
      <fieldset
        className={`${classes.root} ${
          inputMessage?.message ? classes.fieldsetMessage : ''
        } ${disabled && classes.disabled}`}
        disabled={disabled}
        ref={fieldsetRef}
      >
        <input
          name={name}
          id={name}
          onChange={onFileChange}
          type="file"
          className={classes.customFileInput}
          disabled={disabled}
          required={required}
          ref={inputFileRef}
          multiple
          value={''}
        />
        <div className={classes.wrapper} onClick={handleClick}>
          <div className={classes.chipsSection}>
            {Array.isArray(value) && value.length ? (
              // the value can be equal to `null`
              value.map((file, index) => {
                return (
                  <FileStreamCustomChip
                    realFileName={file.realFileName}
                    filePath={file.filePath}
                    onDeleteButtonClick={onDelete}
                    index={index}
                    disabled={disabled}
                    size={file.size}
                    maximumHeight={field?.maximumHeight}
                  />
                );
              })
            ) : (
              <Typography className={classes.caption} noWrap variant="caption">
                {translate('ra.input.selectFile')}
              </Typography>
            )}
          </div>
          <div className={classes.action}>
            <IconButton onClick={handleClick} disabled={disabled}>
              <Icon fontSize="small">add</Icon>{' '}
            </IconButton>

            {value && value.length !== 0 && (
              <IconButton onClick={openPreviewImage(value)}>
                <Icon fontSize="small">remove_red_eye</Icon>
              </IconButton>
            )}
          </div>
        </div>
      </fieldset>
    </InputBaseLabel>
  );
};

export default FileStreamInputView;
