import { RELATION_PANEL_SORT } from '../../core/configProvider';

import { getAppSettings } from '../../helper/settings-helper';
import {
  RelationPanelControllerAllRelationInterface,
  RelationPanelControllerInterface,
} from './relation-panel.type';

/**
 * @function getSubPanelSortKeySetting
 * @param resource
 * @returns {string}
 */
export const getSubPanelSortKeySetting = (resource: string): string =>
  `${RELATION_PANEL_SORT}_${resource}`;

/**
 * @function applyUserSortOnSubPanels
 * @param {string} resource
 * @param {RelationPanelControllerInterface[]} relations
 * @returns {RelationPanelControllerInterface[]}
 */
export const applyUserSortOnSubPanels = (
  resource: string,
  relations: RelationPanelControllerInterface[],
): RelationPanelControllerAllRelationInterface => {
  //if user set sort for sub panel,get it from setting

  const sortKeyInConfig = getSubPanelSortKeySetting(resource);
  const userSort = getAppSettings<string[]>(sortKeyInConfig, true).value ?? [];

  const isCheckedRelations: RelationPanelControllerInterface[] = [];
  const unCheckedRelations: RelationPanelControllerInterface[] = [];

  if (userSort && userSort.length > 0) {
    userSort.map(item => {
      const findRelation = relations.find(data => data.id === item);
      if (findRelation) {
        isCheckedRelations.push(findRelation);
      }
    });

    relations.map(relation => {
      const relationResource =
        ['multiReport', 'report'].indexOf(relation.relationType) > 0
          ? `report/${relation.relationItemInMetaData.id}`
          : `${relation.relationItemInMetaData.moduleName}/${relation.relationItemInMetaData.moduleTableName}`;

      const sort = userSort.indexOf(relationResource);
      if (sort === -1) {
        unCheckedRelations.push(relation);
      }
    });

    return { isCheckedRelations, unCheckedRelations };
  }

  return { isCheckedRelations: relations, unCheckedRelations: [] };
};
