export default {
  meta: {
    resourceIsNotDefined: 'منبع داده در متا به درستی تعریف نشده است!',
    getMetaFailure: 'خطا در دریافت متا',
    errorInParentChildsMeta: 'خطا در محاسبه گزارش های پدر فرزندی در متا '
  },
  general: {
    download: 'دانلود',
    requestId: 'شناسه درخواست',
    copied: 'کپی شد',
    noRecordFound: 'داده ای یافت نشد!',
    back: 'بازگشت',
    more: 'بیشتر',
    apply: 'ثبت',
    decline: 'لغو',
    search: 'جستجو ...',
    delete: 'حذف',
    edit: 'ویرایش',
    start: 'شروع',
    pause: 'مکث',
    reset: 'ریست',
    noData: 'دیتایی برای نمایش وجود ندارد',
    pin: 'پین کردن پیام',
    unpin: 'خارج کردن پین پیام',
    setting: 'تنظیمات',
    logout: 'خروج',
    all: 'همه',
    enable: 'فعال',
    disable: 'غیرفعال',
    theMenusAreNotAvailable: 'منوها در دسترس نیستند.',
    tableRowColorFilter: 'فیلتر رنگ سطرها',
    defaultTableRowColorFilterText: 'انتخاب رنگ',
    defaultTextInSelectableOptions: 'انتخاب کنید',
    nothing: 'هیچکدام',
    add: 'افزودن',
    invalidToken: 'توکن معتبر نمیباشد',
    fullScreen: 'نمایش تمام صفحه',
    exitFullScreen: 'خارج از نمایش تمام صفحه',
    sortRelationPanel: 'تغییر ترتیب نمایش ریلیشن ها',
  },
  dashboard: {
    nameDashboard: 'نام داشبورد',
    createGadget: 'ایجاد گجت',
    addGadget: 'افزودن گجت',
    editGadget: 'ویرایش گجت',
    editLayout: 'ویرایش لایه',
    layoutName: 'نام لایه',
    layoutDescription: 'توضیحات لایه',
    layoutType: 'نوع لایه',
    createLayout: 'ایجاد لایه',
    add: 'افزودن',
    delete: 'حذف',
    manageDashboards: 'مدیریت داشبوردها',
    confirmFilters: 'تایید فیلترها',
  },
  formBuilder: {
    formName: 'نام فرم',
    basicTitle: 'فیلدهای اصلی سامیان',
    customTitle: 'فیلدهای محلی سامیان',
    advancedTitle: 'پیشرفته',
    layoutTitle: 'طرح بندی',
    dataTitle: 'داده',
    premiumTitle: 'پرمیوم',
    submitButton: 'ارسال',
    zoomScaling: 'بزرگنمایی',
  },
  form: {
    thereIsNoReturnInTheFormula: 'در فرمول "return" وجود ندارد',
    thereIsNoRecordOrFormula: 'رکورد یا فرمول وجود ندارد',
    recordNotExist: 'رکورد وجود ندارد',
    formulaIsNotValid: 'فرمول محاسباتی نامعتبر می باشد',
    errorInComputing: 'خطا در فرمول محاسبه',
    showLess: 'نمایش کمتر',
    showMore: 'نمایش بیشتر',
    showFull: 'نمایش کامل',
    showData: 'در حال انجام عملیات لطفا شکیبا باشید ',
    chooseFile: 'انتخاب فایل',
    sendingData: 'در حال ارسال داده',
    createAndNew: 'ذخیره و جدید',
    createAndView: 'ذخیره و مشاهده',
    saveAsDefault: 'ذخیره به عنوان پیش فرض',
    restoreDefault: 'برگرداندن پیش فرض',
    newTab: 'تب جدید',
    editTabInfo: 'ویرایش تب',
    editGroupInfo: 'ویرایش گروه',
    editFieldInfo: 'ویرایش فیلد',
    translatedTitle: {
      fa: 'ترجمه فارسی',
      en: 'ترجمه انگلیسی',
      ar: 'ترجمه عربی',
    },
    translatedCaption: {
      fa: 'ترجمه فارسی',
      en: 'ترجمه انگلیسی',
      ar: 'ترجمه عربی',
    },
    columnCount: 'تعداد ستون',
    submitWasAlmostSuccessfulPleaseCheckRelationsAndFixErrors:
      'ارسال داده موفقیت آمیز بود ولی بعضی ریلیشن ها نیاز به اصلاح دارند. لطفا خطاها را برطرف نمایید و مجدد ذحیره کنید.',
    areYouSureAboutThisOperation: 'آیا از این عملیات اطمینان دارید؟',
    download: 'دانلود کنید.',
    processSelection: 'انتخاب فرایند',
    noFieldsInLayout: 'هیچ فیلدی در metadata برای این layout تعریف نشده است',
    willCreateRawRecord: 'در صورت ذخیره رکورد خام ایجاد خواهد شد',
    description: 'توضیحات',
    completedFrom: 'لطفا فرم زیر را تکمیل کنید',
    pleaseFillTheDescription: 'لطفا دلیل اقدام را وارد فرمایید',
  },
  grid: {
    groupByColumn: 'هدر ستون را برای گروه بندی بر اساس آن ستون، بکشید',
    filterPlaceholder: '',
    services: 'سرویس ها',
    shiftProcess: 'تغییر فرآیند',
    serviceIsRunning: 'سرویس در حال اجرا است، لطفا اندکی صبر بفرمایید ...',
    notes: 'یادداشت ها',
    columnFilters: 'فیلتر های ستونها',
    grouping: 'گروه بندی',
    quickCreate: 'ایجاد سریع',
    filter: 'فیلتر',
    sum: 'مجموع',
    resourceIsDisabled: 'منبع غیر فعال است',
    print: 'چاپ',
    quickEdit: 'ویرایش سریع',
    refresh: 'بروز‌رسانی',
    inlineEdit: 'برای ویرایش کلیک کنید',
    noColumns: 'ستونی برای نمایش وجود ندارد',
    groupingEmptyText: 'برای گروه بندی ستون را بکشید',
    hideGrid: 'مخفی کردن گرید',
    showGrid: 'نمایش گرید',
    columnChooser: 'انتخاب ستون',
    total: 'همه',
    fixColumn: 'سنجاق کردن',
    unfixColumn: 'حذف سنجاق',
    fixColumnLeft: 'سنجاق کردن سمت چپ',
    fixColumnRight: 'سنجاق کردن سمت راست',
    tableFilter:'فیلتر جدول'
  },
  noteStream: {
    fetch: 'نمایش بیشتر',
    newNote: 'نوشته جدید',
    invalidNote: 'یادداشت بیش از 50 کاراکتر بدون کاراکتر فاصله غیرمعتبر است.',
  },
  process: {
    name: 'فرآیند',
  },
  menu: {
    home: 'خانه',
    uploadProfilePicture: 'آپلود عکس جدید برای پروفایل',
    menuNotFound: 'منو پیدا نشد',
    expand: 'باز شدن',
    shrink: 'جمع شدن',
    close: 'بستن',
    favorites: 'مورد علاقه ها',
    recent: 'اخیرا',
    module: 'ماژول',
    changePassword: 'تغییر کلمه عبور',
    importantDashboards: 'داشبورد های مهم',
  },
  css: {
    drawer: 'داینامیک استایل',
    save: 'ذخیره',
    success: 'با موفقیت انجام شد',
  },
  dropdown: {
    placeholder: 'لطفا انتخاب کنید',
    noOptionsMessage: 'مقداری پیدا نشد',
    error: 'خطا در دریافت اطلاعات دراپ داون',
    loading: 'در حال دریافت اطلاعات',
    searchPlaceholder: 'جستجو کنید',
    noneLabel: 'هیچ کدام',
    maxSelection: 'حداکثر %{maxSelection} آیتم قابل انتخاب است',
  },
  filter: {
    list: 'فیلتر های ذخیره شده',
    showEveryFilterInList: 'نمایش همه فیلتر های موجود',
    clearOnlyFilterValuesFromList: 'خالی کردن همه مقادیر از فیلتر های گرید',
    clearList: 'حذف فیلتر های گرید',
    saveFilter: 'ذخیره فیلتر',
    pleaseGiveANameToYourNewFilter: 'لطفا برای فیلتر جدید خود، نامی مناسب برگزینید.',
    name: 'نام',
    nameIsNotValid: 'لطفا نام بهتری وارد کنید.',
    clearFilterValue: 'خالی کردن',
    mode: {
      onDate: 'در تاریخ',
      fromDate: 'از تاریخ',
      untilDate: 'تا تاریخ',
      between: 'در بازه',
      contains: 'دارای',
      equals: 'دقیقا برابر',
      greaterThanOrEquals: 'بیشتر مساوی',
      lessThanOrEquals: 'کمتر مساوی',
      opposite: 'مخالف',
      noValue: 'بدون مقدار',
      startsWith: 'شروع با',
      notContains: 'شامل نشود'
    },
    disabledBecauseOfLength: '( به علت بیشتر بودن از حد مجاز غیر فعال است )',
    apply: 'اعمال فیلترها',
  },
  listColumnChoice: {
    columnConfig: 'تنظیمات ستون ها',
    selectColumns: 'انتخاب ستون ها',
    selectColumnsThatYouWantToShow:
      'لطفا ستون هایی که می خواهید برای شما به نمایش دربیاید را انتخاب کنید',
    changeAll: 'انتخاب همه',
    currentChoicesAreByDefault: 'انتخاب های جاری بر اساس انتخاب پیش فرض هستند.',
    currentChoicesAreByUser: 'انتخاب های جاری بر اساس انتخاب کاربر هستند.',
    noColumnsToSelect: 'هیچ ستونی برای انتخاب نیست.',
    relation:'لطفا ساب پانل هایی که میخواهید برای شما به نمایش درآید را انتخاب کنید'
  },
  quickCreate: {
    fullForm: 'فرم کامل',
  },
  confirm: {
    areYouSureYouWantToDeleteThisNote: 'آیا از حذف این یادداشت مطمئن هستید؟',
    areYouSureYouWantToDeleteThisItem: 'آیا از حذف این مورد مطمئن هستید؟',
    areYouSureYouWantToDeleteWholeTable: 'آیا از حذف کامل این جدول مطمئن هستید؟',
    areYouSureYouWantToUnPinWholeTable: 'آیا از برداشتن سنجاق مطمئن هستید؟',
    areYouSureYouWantToPinWholeTable: 'آیا از سنجاق کردن مطمئن هستید؟',
    areYouSureYouWantToDeleteThisCountOfItem:
      'آیا از حذف %{smart_count} مورد مطمئن هستید؟',
    yes: 'بله',
    no: 'خیر',
    ok: 'باشه',
    confirm: 'تایید',
    cancel: 'انصراف',
  },
  puzzle: {
    reset: 'بازنشانی',
  },
  profile: {
    profileSettings: 'تنظیمات پروفایل',
    editProfile: 'ویرایش پروفایل',
    firstName: 'نام',
    lastName: 'نام خانوادگی',
    address: 'آدرس',
    email: 'ایمیل',
    nationalCode: 'کد ملی',
    phoneNumber: 'شماره تلفن ثابت',
    mobileNumber: 'شماره تلفن همراه',
    accountingSoftware: 'نرم افزار حسابداری',
  },
  formLayout: {
    addNewSection: 'اضافه کردن بخش جدید',
  },
  print: {
    pleaseGiveBrowserAccessToOpenPopup:
      'لطفا به مرورگر اجازه باز کردن پاپ آپ بدهید!',
    ErrorInDetectingOpenedWindow: 'خطا در تشخیص پنجره باز شده!',
    printIdNotFoundInMeta: 'شناسه پرینت در متا وجود ندارد',
  },
  tree: {
    tree: 'درخت',
    noDataToDisplay: 'داده ای برای نمایش وجود ندارد',
    itIsNotPossibleToSelectFromThisLevel: 'انتخاب از این سطح امکان پذیر نیست.',
  },
  pos: {
    discount: 'تخفیف',
    subtotal: 'مجموع',
    tax: 'مالیات',
    total: 'قابل پرداخت',
    addCustomer: 'اضافه کردن مشتری',
    note: 'یادداشت...',
    discard: 'لغو سفارش',
    park: 'پارک',
    pay: 'پرداخت',
    searchForProductNameOrBarcode: 'اسم یا بارکد محصول را جست‌وجو کنید',
    giftCard: 'کارت هدیه',
    customSale: 'فروش سفارشی',
  },
  todo: {
    file: 'اظافه کردن فایل',
    thereIsNothingTapNewTaskToGetStarted:
      'کاری وجود ندارد. برای شروع "کار جدید" را بزنید.',
    newTask: 'کار جدید',
    newStep: 'مرحله جدید',
    add: 'ایجاد',
    tasks: 'لیست کارها',
    newList: 'لیست جدید',
    createNewList: 'ایجاد لیست جدید',
    newGroup: 'گروه جدید',
    deleteGroup: 'حذف گروه',
    ungroup: 'جداسازی گروه',
    renameGroup: 'تغییر نام گروه',
    IsAddedToMyDay: 'روز من',
    IsImportant: 'مهم ها',
    DueDate: 'برنامه ریزی شده ها',
    assignedtome: 'سپرده شده ها',
    sysitem: 'وظایف سیستمی',
    deleteTodoList: 'حذف لیست',
    renameTodoList: 'ویرایش نام لیست',
    renamedSuccessfully: 'ویرایش نام لیست انجام شد!',
    areYouSureYouWantToDeleteThisTask: 'آیا از حذف این کار مطمئن هستید؟',
    areYouSureYouWantToDeleteThisStep: 'آیا از حذف این مرحله مطمئن هستید؟',
    areYouSureYouWantToDeleteThisList: 'آیا از حذف این لیست مطمئن هستید؟',
    note: 'یادداشت',
    isDone: 'انجام شده',
    hideCompleted: 'مخفی کردن انجام‌شده‌ها',
    showCompleted: 'نمایش انجام‌شده‌ها',
    title: 'نام',
    createDate: 'تاریخ ایجاد',
    options: 'گزینه ها',
    sort: 'مرتب سازی',
    sortBy: 'مرتب سازی بر اساس',
    asc: 'صعودی',
    desc: 'نزولی',
    today: 'امروز',
    tomorrow: 'فردا',
    overmorrow: 'پس‌فردا',
    nextWeek: 'هفته بعد',
    customDateTime: 'انتخاب تاریخ و ساعت',
    customDate: 'انتخاب تاریخ',
    remindMe: 'به من یادآوری کن',
    dueDate: 'تاریخ تمام شدن کار',
    addToMyDay: 'به روز من اضافه کن',
    addedToMyDay: 'به روز من اضافه شد',
    daily: 'روز',
    weekly: 'هفته‌',
    monthly: 'ماه',
    yearly: 'سال',
    repeat: 'تکرار',
    CustomRepeat: 'تکرار هر ...',
    noOptionsMessage: 'قابلیت تکرار تعریف نشده',
    areYouSureYouWantToDeleteThisFile: 'آیا از حذف این فایل مطمئن هستید؟',
    myDay: 'روز من',
    duplicateList: 'کپی لیست',
    shareList: 'اشتراک لیست',
    assignTo: 'محول کردن',
    dragListToEmptyGroup: 'لیستی را بکشید',
    listIsAlreadyShared: 'این لیست قبلا با این شخص به اشتراک گذاشته شده است.',
    selectAPerson: 'لطفا یک شخص را انتخاب کنید.',
    notYourself: 'شما نمی‌توانید لیست خود را با خودتان به اشتراک بگذارید.',
    assingedToMe: 'کارهای من',
    assingToMe: 'بسپار به من',
    quickFilters: 'فیلترها',
    invalidDate: 'تاریخ نامعتبر',
    link: 'لینک',
    repeatCount: 'تعداد تکرار',
    completedTasks: 'کارهای انجام شده',
    uncompletedTasks: 'کارهای انجام نشده',
    label: 'اظافه کردن لیبل',
    sortTask: 'مرتب سازی',
    sortByTask: 'مرتب سازی بر اساس',
    nameTask: 'نام تسک',
    share: 'اشتراک گذاری',
    assign: 'اختصاص به شخص',
  },
  shop: {
    basket: {
      payableAmount: 'مبلغ قابل پرداخت',
      finalizeBasket: 'نهایی کردن خرید',
      currency: 'ریال',
      unitPrice: 'قیمت واحد',
      totalPrice: 'قیمت کل',
      discount: 'تخفیف',
      finalPrice: 'قیمت نهایی',
    },
  },
  login: {
    mobile: 'موبایل',
    confirmation: 'کد تایید',
    send_mobile: 'ارسال تلفن همراه',
    send_confirmation: 'ارسال کد تایید',
  },
  sellin: {
    id: 'شناسه',
    createDate: 'تاریخ ایجاد',
    itemCount: 'تعداد',
    totalItemCount: 'تعداد کل',
    totalDiscountPrice: 'تخفیف کل',
    totalWholePrice: 'قیمت کل',
    finalPrice: 'قیمت نهایی',
    settlementTypeTitle: 'تسویه',
    orderStatusTitle: 'وضعیت',
    successfulOnlinePayment: 'پرداخت آنلاین موفق',
    orderInformation: 'اطلاعات سفارش',
    orderStatusDescription: 'وضعیت سفارش',
    detail: 'جزئیات',
    showProduct: 'نمایش محصول',
    price: 'قیمت',
    tags: 'برچسب ها',
    description: 'توضیحات',
    addToCart: 'افزودن به سبد خرید',
    priceDetails: 'جزئیات قیمت',
    settlementType: 'نوع تسویه',
    submitOrder: 'ثبت سفارش',
    areYouConfidentInFinalizingYourOrder:
      'آیا برای نهایی کردن سفارش خود اطمینان دارید؟',
    yourOrderHasBeenRegistered: 'سفارش شما ثبت شد.',
    addedToBasketSuccessfully: 'به سبد خرید با موفقیت اضافه شد',
    sortBy: 'مرتب‌سازی براساس',
    filterBy: 'فیلتر کردن براساس',
    sort: 'مرتب‌سازی',
    filter: 'فیلتر کردن',
    pleaseEnterTheNameOfTheDesiredProductInTheBoxAbove:
      'لطفا نام کالای مورد نظر را در کادر بالا وارد کنید.',
    noProductFound: 'کالایی پیدا نشد!',
    inventory: 'موجودی',
    ordering: 'مرتب سازی',
    barcodes: 'بارکدها',
  },
  customValidation: {
    validationErrorOnTab: 'خطای اعتبارسنجی فیلد ها در پنجره %{tabName}',
    separator: 'و',
    valueOfField: 'مقدار فیلد ',
    valueOfFields: 'مقدار فیلد های ',
    notValid: ' باید اصلاح شود',
    areNotValid: ' باید اصلاح شوند',
    invalidValue: 'مقدار وارد شده نامعتبر میباشد',
    unknownTab: 'تب نامشخص',
  },
  service: {
    success: 'سرویس با موفقیت انجام شد.',
  },
  toolbar: {
    importComponent: {
      import: 'وارد کردن',
      importExcelFile: 'وارد کردن فایل اکسل',
      downloadSampleFile: 'دانلود فایل نمونه',
      uploadExcelFile: 'بارگذاری',
      chooseAfile: 'پرونده ای را انتخاب کنید',
    },
    relationMovement: 'اعمال جابجایی',
  },
  quickAccess: {
    quickAccess: 'دسترسی سریع',
    actionDescription: {
      quickAccess: 'میانبر مورد نظر خود را در کادر بنویسید و دکمه اینتر را بزنید.',
    },
  },
  imageDialog: {
    dataNotFound: 'دیتایی برای نمایش وجود ندارد',
  },
  editor: {
    placeholder: 'محتوایی را ایجاد کنید!',
    staticVariables: 'متغیر های نامه',
  },
  fileManager: {
    properties: 'مشخصات',
    propertiesError: 'جهت نمایش مشخصات لطفا آیتمی را انتخاب کنید',
  },
  notification: {
    changePassword: {
      success: 'تغییر کلمه عبور با موفقیت انجام شد',
    },
    messages: 'پیام ها',
    showAllMessages: 'نمایش همه',
    showUnreadMessage: 'فقط خوانده نشده ها',
    title: 'عنوان',
    seenAllMessages: 'همه را خواندم',
  },
  blog: {
    recentNotes: 'اعلانات',
    continueReading: 'ادامه مطالب ...',
  },
  file: {
    fileUploadLimitMB: 'حجم فایل بیشتر از %{fileUploadLimitMB} مگابایت می باشد.',
    file: 'فایل',
    youCanNotChooseMoreThanOneFile: 'شما مجاز به اپلود فقط یک فایل می باشید',
    files: 'فایل های ',
    and: 'و',
    couldNotSendFileBecauseItsLargeSize:
      'به دلیل حجم بالا تر از مقدار مجاز ارسال نشد',
    couldNotSendFilesBecauseItsLargeSize:
      'به دلیل حجم بالا تر از مقدار مجاز ارسال نشدند',
  },
  calendar: {
    items: 'آتیم ها',
    today: 'امروز',
    back: 'قبل',
    next: 'بعد',
    month: 'ماه',
    week: 'هفته',
    day: 'روز',
    require: 'اجباری',
    calendar: 'تقویم ',
    saturday: 'شنبه',
    sunday: 'یکشنبه',
    monday: 'دوشنبه',
    tuesday: 'سه شنبه',
    wednesday: 'چهارشنبه',
    thursday: 'پنج شنبه',
    friday: 'جمعه',
  },
  chat: {
    startChat: 'کاربری را برای شروع گپ انتخاب کنید',
    sayHi: 'سلام کنید به',
    messages: 'گفتگوها',
    contacts: 'مخاطبین',
    search: 'جستجو ...',
    reply: 'پاسخ دادن',
    deleteMessage: 'حذف پیام',
    deleteConfirmMessage: 'آیا از حذف این پیام اطمینان دارید؟',
    edit: 'ویرایش',
    messagePlaceHolder: 'تایپ کنید ...',
    noUserFound: 'مخاطبی یافت نشد!',
    noMessagesFound: 'گفتگویی یافت نشد!',
    createNewGroup: 'ایجاد گروه جدید',
    selectedFiles: ' %{count} فایل انتخاب شده',
    next: 'بعد',
    groupName: 'نام گروه',
    create: 'ایجاد',
    addMembers: 'افزودن اعضا',
    deleteGroup: 'حذف',
    deleteChat: 'حذف',
    groupInfo: 'اطلاعات گروه',
    member: 'عضو',
    manageGroup: 'مدیریت گروه',
    editGroup: 'ویرایش گروه',
    members: 'اعضا',
    admins: 'مدیران',
    remove: 'حذف',
    pinMessage: 'سنجاق کردن پیام',
    unpinMessage: 'حذف سنجاق پیام',
    pinnedMessage: 'پیام سنجاق شده',
    createChannel: 'ایجاد کانال',
    channelName: 'نام کانال',
    addAdmin: 'افزودن مدیر',
    addAdmins: 'افزودن مدیران',
    forward: 'فرستاده شده ',
    forwardFrom: 'فرستاده شده از',

    chooseLocationManually:
      'لوکیشن شما به صورت خودکار پیدا نشد. لطفا لوکیشن مورد نظر را از روی نقشه انتخاب نمایید',
    needLocationPermission:
      'برای پیدا کردن لوکیشن شما نیاز به دسترسی به اطلاعات موقعیت مکانی شماست',
    findLocationFailed: 'موقعیت مکانی شما پیدا نشد',
    errorInSavingLocation:
      'خطایی در ذخیره موقعیت مکانی پیش آمده، لطفا دوباره تلاش کنید',
    savingLocation: 'در حال ذخیره سازی لوکیشن',
    sendAsAlbum: 'ارسال به صورت آلبوم',
    messageInfo: 'جزئیات پیام',
    peopleWhoHaveSeenYourMessage: 'افرادی که پیام شما را دیده اند',
    getDataError: 'خطا در دریافت اطلاعات',
    noOneSeenYourMessageYet: 'پیام شما تا کنون توسط هیچ فردی دیده نشده',
    location: 'مکان',
    album: 'آلبوم',
    isTyping: 'در حال تایپ',
    commonGroups: 'گروه های مشترک',
    contactInformation: 'اطلاعات مخاطب',
  },
  mail: {
    message: 'پیام ها',
    automation: 'کارتابل',
    newMessage: 'پیام داخلی',
    downloadAll: 'دانلود همه',
    newMail: 'نامه صادره',
    inMail: 'نامه وارده',
    folders: 'پیام ها',
    labels: 'برچسب ها',
    send: 'ارسال',
    saveAsDraft: 'ذخیره به عنوان پیشنویس',
    reply: 'پاسخ دادن',
    replySubject: 'پاسخ',
    replyAll: 'پاسخ به همه',
    attachedFiles: 'فایل های ضمیمه',
    successReply: 'پاسخ پیام با موفقیت ارسال شد',
    successArchive: 'عملیات ارجاع و آرشیو با موفقیت انجام شد',
    successRecall: 'عملیات ریکال با موفقیت انجام شد',
    recallConfirmMessage: 'از اجرای سرویس ریکال اطمینان دارید؟',
    confirmDeleteFolderMessage: 'از حذف پوشه : %{folderName} اطمینان دارید ؟',
    mailNotFound: 'پیام یافت نشد!',
    forward: 'ارسال به دیگران',
    sender: 'فرستنده',
    toStar: 'ستاره دار کردن',
    toArchive: 'آرشیو',
    toUnArchive: 'حذف از آرشیو',
    toReference: 'ارجاع',
    markAsRead: 'علامت گذاری به عنوان خوانده شده',
    markAsUnRead: 'علامت گذاری به عنوان خوانده نشده',
    markAsImportant: 'علامت گذاری به عنوان مهم',
    markAsStarred: 'ستاره دار کردن',
    removeStarred: 'حذف ستاره',
    removeImportant: 'حذف مهم',
    applyLabel: 'تخصیص برچسب',
    newLabel: 'برچسب جدید',
    labelTitle: 'عنوان برچسب',
    colorCode: 'کد رنگ',
    parentLabel: 'برچسب والد',
    template: 'الگو های پیام',
    noTemplateData: 'الگوی پیام یافت نشد',
    followUp: 'پیگیری',
    messageText: 'متن پیام',
    references: 'ارجاعات',
    cycle: 'گردش',
    recall: 'ریکال',
    personalFolders: 'پوشه های شخصی',
    createFolder: 'ایجاد پوشه جدید',
    moveTo: 'انتقال به',
    cc: 'رونوشت',
    bcc: 'رونوشت پنهان',
    from: 'از',
    to: 'به',
    mailSubject: 'موضوع نامه',
    notSeen: 'مشاهده نشده',
    delegation: 'تفویض',
    seeAll: 'مشاهده',
    docType: {
      allMail: 'تمام نامه ها',
      inbox: 'صندوق ورودی',
      outbox: 'صندوق خروجی',
      unread: 'خوانده نشده',
      archived: 'آرشیو شده',
      starred: 'ستاره دار',
      important: 'مهم',
      innerMail: 'نامه داخلی',
      systemTasks: 'وظایف سیستمی',
      message: 'پیام ها',
      draft: 'پیش نویس',
    },
    referenceListHeader: {
      date: 'تاریخ',
      sender: 'فرستنده',
      receiver: 'گیرنده',
      referenceParaph: 'متن ارجاع',
      seenDate: 'تاریخ مشاهده',
      kind: 'نوع',
    },
    header: {
      filter: {
        messageType: 'نوع پیام',
        delegationType: 'نوع تفویض',
        hasBeenDelegate: 'تفویض شده',
        hasNotBeenDelegate: 'تفویض نشده',
        hasBeenRead: 'خوانده شده',
        hasNotBeenRead: 'خوانده نشده',
      },
    },
  },
  pivot: {
    fieldChooserTitle: 'تنظیمات جدول',
    allFields: 'همه ستون ها',
    filterFields: 'فیلد های جستجو',
    dataFields: 'اطلاعات جدول',
    columnFields: 'ستون های جدول',
    rowFields: 'سطر های جدول',
    excelExportFileName: 'گزارش',
    sortColumnBySummary: 'مرتب سازی ستون ها',
    sortRowBySummary: 'مرتب سازی سطرها',
    showFieldChooser: 'نمایش دیالوگ انتخاب فیلدها',
    exportToExcel: 'خروجی اکسل',
    expandAll: 'باز کردن همه',
    dataNotAvailable: 'دیتایی وجود ندارد',
    collapseAll: 'بستن همه',
    grandTotal: 'مجموع',
    max: 'ماکسیمم',
    min: 'مینیمم',
    average: 'میانگین',
    columnFieldArea: 'ستون های جدول را اینجا قرار دهید',
    dataFieldArea: 'اطلاعات جدول را اینجا قرار دهید',
    filterFieldArea: 'فیلد های جستجو را اینجا قرار دهید',
    rowFieldArea: 'سطر های جدول را اینجا قرار دهید',

    settingSavedSuccessfully: 'تنظیمات با موفقیت ذخیره شد',
  },
  dynamicInput: {
    emptyValue: 'خالی',
    gadgetDesign: 'طراحی گجت',
  },
  datePicker: {
    placeholder: 'روز/ماه/سال',
    dateTimePlaceholder: 'روز/ماه/سال دقیقه:ساعت',
  },
  permissions: {
    assignType: 'نوع انتساب مجوز',
    user: 'کاربر',
    role: 'نقش',
    charts: 'چارت ها',
    menuTitles: 'عنوان منو ها',
    permissionType: 'نوع مجوز',
    assigned: 'منتسب',
    applyPermission: 'اعمال مجوز',
    removePermission: 'حذف مجوز',
    selectAll: 'انتخاب همه',
    error:'کلید مجوز تنظیم نشده',
    advance:'تعداد مجوز انتخاب شده',
    selectPermissions: 'اعمال مجوز روی %{selectedForAddPermissions} مجوز انتخاب شده ',
    removePermissions: 'حذف مجوز روی %{selectedForAddPermissions} مجوز انتخاب شده ',
    advancePermission:'مجوز پیشرفته',
    permission:'مجوز '
  },
  gadget: {
    gadgetSettings: 'تنظیمات گجت',
    defaultValues: 'مقادیر پیشفرض',
    selectChartType: 'انتخاب نوع نمودار',
    selectReport: 'انتخاب گزارش',
    invalidChart: 'چارت نا معتبر',
    getReportInformationFailed: 'دریافت اطلاعات گزارش با خطا مواجه شد',
    pleaseChooseReport: 'لطفا گزارش مورد نظر را انتخاب کنید',
    mainSettings: 'تنظیمات اصلی',
    chartSettings: 'تنظیمات نمودار',
    idField: 'فیلد شناسه',
    valueField: 'فیلد مقدار',
    titleField: 'عنوان',
    iconField: 'ایکون',
    labelField: 'فیلد لیبل',
    axisBottomLabelField: 'عنوان محور  افقی',
    horizontal: 'فیلد محور افقی',
    vertical: 'فیلدر محور عمودی',
    axisLeftLabelField: 'عنوان محور عمودی',
    axisTopLabelField: 'عنوان محور افقی بالا',
    colorField: 'فیلد رنگ',
    chooseChart: 'انتخاب نمودار',
    pleaseFillAllRequiredFields: 'لطفا تمام فیلد های اجباری را تکمیل نمایید',
    saveChanges: 'ذخیره تغییرات',
    dashboardNotFound: 'داشبورد مورد نظر یافت نشد',
    autoRefreshReportData: 'دیتای گزارش به طور منظم بروز رسانی شود',
    autoRefreshReportDataTime: 'هر چند ثانیه دیتای گزارش بروزرسانی شود ؟',
    howManyRowsGetFromReport: 'چند ردیف از گزارش در نمودار لحاظ شود ؟',
    sortReportData: 'دیتای گزارش مرتب شود',
    ascending: 'صعودی',
    descending: 'نزولی',
    baseOfField: 'بر اساس فیلد',
    announcementsPerPage: 'تعداد اعلانات در هر صفحه',
    invalidCalender: 'تقویم نا معتبر',
    id: 'شناسه',
    title: 'عنوان',
    branchList: 'لیست شعب',
    selectAll: 'انتخاب همه',
    error:'کلید مجوز تنظیم نشده' ,
    settingsNotFound:'این نمودار تنظیمات اختصاصی ندارد' ,
    url:'آدرس ' ,
    attachSessionToUrl:'توکن کاربر به ادرس اضافه شود؟' ,
    displayNumber:'تعداد نمایش دیتاهای چارت'
  },
  map:{
    clusterMode:'نمایش نقاط به صورت تجمیعی',
    zoomMap:'برای بزرگنمایی نقشه از ctrl + scroll استفاده کنید'
  },
  rangePickerGrafana: {
    minute: 'دقیقه',
    hour: 'ساعت',
    day: 'روز',
    ago: 'اخیر',
    applyRange: 'اعمال بازه',
    from: 'از :',
    to: 'تا :',
    searchQuickRanges: 'جستجو در بازه های سریع',
    selectAbsoluteRange: 'انتخاب بازه دقیق',
    invalidRange: 'بازه انتخاب شده معتبر نمیباشد',
    howToFill: 'فرمت های قابل قبول به اشکال زیر میباشد :',
    nowExample: 'now',
    nowHint: 'این لحظه',
    minuteRangeExample: 'now - 20m',
    minuteRangeHint: 'از بیست دقیقه قبل',
    hourRangeExample: 'now - 20h',
    hourRangeHint: 'از بیست ساعت قبل',
    dayRangeExample: 'now - 20d',
    dayRangeHint: 'از بیست روز قبل',
    jalaliDateExample: '1400/01/02',
    jalaliDateHint: 'تاریخ شمسی',
    miladiDateExample: '2020/01/02',
    miladiDateHint: 'تاریخ میلادی',
    jalaliDateTimeExample: '10:12:00 1400/01/02',
    jalaliDateTimeHint: 'تاریخ و ساعت شمسی',
    miladiDateTimeExample: '10:12:00 2020/01/02',
    miladiDateTimeHint: 'تاریخ و ساعت میلادی',
    notFound: 'موردی یافت نشد',
    validationError: 'خطا در اعتبار سنجی ورودی ها',
    ComponentsRecognizeError: 'خطا در شناسایی اجزای فرم',
  },
  rangePickerJira: {
    applyRange: 'اعمال بازه',
    from : 'از',
    to : 'تا',
    now : 'الآن',
    between : 'بین دو تاریخ',
    range : 'در رنج',
    past : 'گذشته',
    future : 'آینده',
    second:'ثانیه',
    minute : 'دقیقه',
    hour : 'ساعت',
    day:'روز',
    week:'هفته',
    month:'ماه',
    year:'سال',
    invalidRange:'بازه انتخاب شده معتبر نمیباشد',
    invalidDate:'تاریخ انتخاب شده معتبر نمیباشد',
  }
};
