import { Button } from '@material-ui/core';
import { ReactElement, useRef } from 'react';
import { Toolbar, useTranslate } from 'react-admin';
import CustomFormButton from '../../form-component-old/CustomFormButton';

import { useStyles } from './quick-edit-button-toolbar.style';
import { QuickEditButtonToolbarViewInterface } from './quick-edit-button-toolbar.type';

const QuickEditButtonToolbarView = (
  props: QuickEditButtonToolbarViewInterface,
): ReactElement => {
  const { redirectWithData, closeDialog, handleSubmit, loading, ...rest } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Toolbar
      {...rest}
      className={classes.toolbar}
      classes={{
        spacer: classes.spacer,
        mobileToolbar: classes.mobileToolbar,
      }}
    >
      <CustomFormButton
        label={translate('ra.action.save')}
        onClick={handleSubmit}
        disabled={loading}
        variant="contained"
        id="quickEditToolbarSaveButton"
        buttonRef={buttonRef}
      />
      <Button
        id="quickEditFullForm"
        className={classes.dumbButton}
        onClick={redirectWithData}
        data-test-quick-edit-toolbar-button={'fullForm'}
        ref={buttonRef}
        disabled={loading}
      >
        {translate('quickCreate.fullForm')}
      </Button>
      <Button
        id="quickEditCancel"
        className={classes.dumbButton}
        onClick={closeDialog}
        data-test-quick-edit-toolbar-button={'cancel'}
        ref={buttonRef}
        disabled={loading}
      >
        {translate('ra.action.cancel')}
      </Button>
    </Toolbar>
  );
};

export default QuickEditButtonToolbarView;
