import lodashGet from 'lodash/get';
import { GET_MANY_REFERENCE } from 'react-admin';
import { getInputsInitialAppearanceCharacteristics } from '../../helper/meta-helper';
import { getMetaDataAndRecordForSpecificResource } from '../../api/global-api';
import {
  actorGetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
  actorDispatch,
} from '../../type/actor-setup';

import type {
  FieldType,
  GeneralMetaData,
  JsonConfigMetaData,
  MetaData,
} from '../../helper/Types';
import type {
  ApiDataInterface,
  ColumnsInterface,
  GetGridAdditionalData,
  GetGridColumnsInterface,
  PrepareGridInputsCharacteristics,
  WebSettingFixedColumnsInterface,
  GetGridColumnsForDataInterface,
  PrepareColumnsForGridFromDataInterface,
  RunReportEditServiceProps,
} from './grid.type';

import { getDashboardUniqueIdField } from '../dashboard/dashboard-page.helper';
import { COLOR_FIELD, getTypeByField, ICON_FIELD } from '../../helper/InputHelper';
import {
  clone,
  convertKeysToLowerCase,
  isEmpty,
  isEmptyObject,
} from '../../helper/data-helper';
import {
  CONFIG_CELL_WIDTH,
  CONFIG_FIXED_COLUMNS,
  getValue,
  parseJSON,
} from '../../core/configProvider';
import { setAppSettings } from '../../helper/settings-helper';
import dxDataGrid from 'devextreme/ui/data_grid';
import { requestListData } from '../list';
import { RUN_SERVICE } from '../../core/data-Provider.helper';
import { showNotificationForUnknownError } from '../../helper/general-function-helper';

/**
 * @function prepareGridInputsCharacteristics
 * @param {
 *  @param { string } resource
 *  @param { MetaData } metaData
 *  @param { () => void } successCallback
 *  @param { () => void } failureCallback
 * } entries
 * @returns {Record<string, InputAppearanceCharacteristics>} InputAppearanceCharacteristics
 */
export const prepareGridInputsCharacteristics: PrepareGridInputsCharacteristics =
  entries => {
    const { resource, metaData, parentResource } = entries;
    const currentRecord = actorGetActionValue('gridFormData') ?? {};
    const parentRoute =
      parentResource ??
      ((currentRecord?.['__parentInfo'] as Record<string, unknown>)?.['route'] as
        | string
        | undefined);

    if (parentRoute) {
      const [, moduleName, moduleTableName, id] = parentRoute.split('/');
      const parentMetaData = actorGetActionValue(
        'metaData',
        parentRoute,
      ) as MetaData | null;
      const parentRecord = actorGetActionValue(
        'record',
        `${parentRoute}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
      );
      if (parentMetaData && parentRecord) {
        return (
          getInputsInitialAppearanceCharacteristics({
            currentMetaData: metaData as GeneralMetaData,
            currentRecord: currentRecord,
            parentMetaData: parentMetaData as GeneralMetaData,
            parentRecord: parentRecord,
          }) ?? {}
        );
      } else {
        getMetaDataAndRecordForSpecificResource({
          resource: `${moduleName}/${moduleTableName}`,
          id,
        });

        return {};
      }
    }
    return {};
  };

/**
 * getColumnWidth
 * @param {FieldType} field
 * @param {Record<string, unknown> | null} columnWidthSetting
 * @returns {number}
 */
const getColumnWidth = (
  field: FieldType,
  columnWidthSetting?: Record<string, unknown> | null,
): number => {
  const defaultCellWidth = getValue(CONFIG_CELL_WIDTH);

  if (isEmptyObject(columnWidthSetting)) {
    return !isEmpty(field.width) ? field.width : defaultCellWidth;
  }

  if (!isEmpty(columnWidthSetting![field.id])) {
    return columnWidthSetting![field.id] ?? defaultCellWidth;
  }

  if (!isEmpty(columnWidthSetting![field.name])) {
    return columnWidthSetting![field.name] ?? defaultCellWidth;
  }

  return defaultCellWidth;
};

const cashReportForDynamicColumn = {};

/**
 * This function only works for reports, and for tables, it is the same old routine
 * checkGridColumnHasDynamic
 * @param {FieldType[]} fields
 * @param {Record<string, unknown>[] | GridDataInterface} gridRows
 * @param {GeneralMetaData} metaData
 * @returns {boolean}
 */
export const checkGridColumnHasDynamic = (
  gridRows: Record<string, unknown>[],
  metaData: GeneralMetaData,
): boolean => {
  if (
    metaData &&
    !isEmpty(metaData.id) &&
    gridRows.length > 0 &&
    !isEmpty(metaData.jsonConfig)
  ) {
    const jsonConfig = convertKeysToLowerCase(
      parseJSON(metaData.jsonConfig),
    ) as JsonConfigMetaData;

    return Boolean(jsonConfig?.isdynamicreport);
  }
  return false;
};

/**
 * @param { FieldType[] } field
 * @param { metaData } GeneralMetaData
 * @param { Record<string, unknown>[] } gridRows
 * @param { string } locale
 * @param { Record<string, unknown> | null } columnWidthSetting
 * @returns { ColumnsInterface[] }
 */
export const getFinalColumnsForGrid: GetGridColumnsForDataInterface = ({
  fields,
  metaData,
  gridRows,
  locale,
  columnWidthSetting,
}) => {
  if (checkGridColumnHasDynamic(gridRows, metaData)) {
    return prepareColumnsForGridFromData({
      gridRows,
      locale,
      metaData,
    });
  } else {
    return prepareColumnsForGridFromMeta({
      fields,
      metaData,
      locale,
      columnWidthSetting,
    });
  }
};

/**
 * @param { FieldType[] } field
 * @param { Record<string, unknown>[] } gridRows
 * @param { string } locale
 * @returns { ColumnsInterface[] }
 */
export const prepareColumnsForGridFromData: PrepareColumnsForGridFromDataInterface =
  ({ metaData, gridRows, locale }) => {
    const additionalColumns = getAdditionalColumns(metaData, gridRows);
    const allColumns = [...(metaData.columns ?? []), ...additionalColumns];
    const firstRow = clone(gridRows[0]);
    delete firstRow.id;
    const columnsData: ColumnsInterface[] = [];
    Object.keys(firstRow).map(key => {
      const field = allColumns.find(column => column?.relatedName == key);
      if (field) {
        field.width = field?.columnWidth ?? field?.width ?? 100;
        columnsData.push({
          field,
          name: field?.relatedName ?? key,
          title:
            lodashGet(field, ['translatedCaption', locale], field?.caption) ?? key,
        });
      }
    });
    return columnsData;
  };

/** prepareColumnsForGridFromMeta
 * @param { FieldType[] } field
 * @param { metaData } GeneralMetaData
 * @param { string } locale
 * @param { Record<string, unknown> | null } columnWidthSetting
 * @returns { ColumnsInterface[] }
 */
export const prepareColumnsForGridFromMeta: GetGridColumnsInterface = entries => {
  const { fields, metaData, locale, columnWidthSetting } = entries;

  const preparedColumns: ColumnsInterface[] = [];
  const statusFields: FieldType[] = [];

  const dashboardUniqueIdField = getDashboardUniqueIdField(metaData);

  fields.forEach(field => {
    // "rowstatecolor" contains only color info for row
    if (!field || (field && field.name === 'rowstatecolor')) {
      return;
    }
    if (
      getTypeByField(field) === COLOR_FIELD ||
      getTypeByField(field) === ICON_FIELD
    ) {
      statusFields.push(field);
      return;
    }

    field.width = getColumnWidth(field, columnWidthSetting);
    preparedColumns.push({
      field, // to not find field again from list of fields
      name: field.relatedName, // use relatedName for all fields
      title: lodashGet(field, ['translatedCaption', locale], field.caption),
    });
  });

  if (dashboardUniqueIdField) {
    statusFields.push(dashboardUniqueIdField as FieldType);
  }

  if (statusFields.length) {
    preparedColumns.unshift({
      name: 'statusFields',
      title: ' ',
      fields: statusFields,
    });
  }

  return preparedColumns;
};

/**
 * @function getAdditionalColumns
 * @param {string} resource
 * @param {MetaData} metaData
 * @param {Record<string, unknown>[]} gridData
 * @returns {ColumnsInterface[]}
 */
export const getAdditionalColumns = (
  metaData: GeneralMetaData,
  gridData: Record<string, unknown>[],
): FieldType[] => {
  try {
    if (
      isEmpty(metaData?.['reportId']) ||
      isEmptyObject(gridData) ||
      !checkGridColumnHasDynamic(gridData, metaData)
    )
      return [];

    const keysInData = Object.keys(gridData[0]);
    const reportColumns = Array.isArray(metaData?.columns) ? metaData.columns : [];
    const metaDataInitiatedColumnNames = reportColumns.map(col => String(col.name));

    const additionalFields = keysInData.reduce<FieldType[]>(
      (accumulator, current) => {
        if (
          current !== 'id' &&
          !current.includes('hasformulatoruncolumn') &&
          !metaDataInitiatedColumnNames.includes(String(current)) &&
          !current.startsWith('__') //don't show systemic columns
        ) {
          const fakeField = {
            caption: current,
            columnWidth: 100,
            dataType: {
              erp: 'string',
              sql: 'decimal',
              simple: 'string',
              id: 106,
              defaultOperator: 'GreaterThanOrEquals',
            },
            disabled: false,
            editable: false,
            format: 'n0',
            groupingPriority: 0,
            hasSummary: true,
            hidden: false,
            id: current,
            linkName: '',
            name: current,
            parameterKey: null,
            pivotInfo: { columnIndex: null, rowIndex: null },
            relatedName: current,
            required: false,
            resource: '',
            resourceType: '',
            sortType: null,
            translatedCaption: { fa: current, en: current, ar: current },
            visible: true,
            width: 200,
          } as unknown as FieldType;

          return [...accumulator, fakeField];
        }

        return accumulator;
      },
      [],
    );

    return additionalFields;
  } catch (error) {
    console.error('getAdditionalColumns error: ', error);
    return [];
  }
};

/**
 * @function setGridFixedColumnsSetting
 * @param {string} resource
 * @param { dxDataGrid } grid
 * @returns {void}
 */
export const setGridFixedColumnsSetting = (
  resource: string,
  grid: dxDataGrid,
): void => {
  const columnsOptions: WebSettingFixedColumnsInterface[] = [];
  const count = grid.columnCount();
  for (let index = 0; index < count; index++) {
    const columnOptions = grid.columnOption(index);
    if (columnOptions.allowFixing && columnOptions.fixed) {
      columnsOptions.push({
        name: columnOptions.name,
        fixedPosition: columnOptions.fixedPosition,
      });
    }
  }
  setAppSettings({
    key: `${CONFIG_FIXED_COLUMNS}_${resource}`,
    value: columnsOptions,
    forUser: true,
  });
};

/**
 * //TODO we don't need this function first api should fix their problem and send us additional data in put request or get request of one data then this function should be deleted
 * @function getGridAdditionalData
 * @param { (response: ApiDataInterface) => void} onSuccess
 * @returns {void}
 */
export const getGridAdditionalData = (entires: GetGridAdditionalData): void => {
  const {
    parentInfo,
    childFieldName,
    parentFieldName,
    relationResource,
    relationMetaData,
    onSuccess,
  } = entires;

  if (parentInfo && childFieldName) {
    requestListData(
      relationResource,
      'table',
      parentInfo?.parentId, // <= parentIdentifier
      childFieldName, // <= childFieldName
      parentFieldName,
      relationMetaData as GeneralMetaData,
      {},
      (response: ApiRequestResultInterface) => {
        //prettier-ignore
        const summaryInfo = response[GET_MANY_REFERENCE]?.relation?.data as ApiDataInterface;
        if (!isEmptyObject(summaryInfo)) {
          onSuccess(summaryInfo);
        }
      }, // <= onSuccess
      undefined, // <= onFailure
      false, // <= isListMode
    );
  }
};

/**
 * @function runReportEditService
 * @param {
 *   @param fieldName string
 *   @param metaData GeneralMetaData
 *   @param gridFormData Record<string, unknown>
 * } props
 */
export const runReportEditService = (props: RunReportEditServiceProps): void => {
  const { fieldName, metaData, resource, gridFormData, onSuccess } = props;

  const relatedServiceInformation = (
    metaData.reportEditableColumnServices ?? []
  ).find(
    serviceInfo =>
      String(serviceInfo.columnName).toLowerCase() ===
      String(fieldName).toLowerCase(),
  );

  const serviceParameterValues = {};

  relatedServiceInformation?.parameters.forEach(parameter => {
    const key = parameter.field.name;
    serviceParameterValues[key] = gridFormData[key];
  });
  actorDispatch('crudAction', {
    type: RUN_SERVICE,
    actionUniqueId: relatedServiceInformation?.action.uniqueId,
    data: {
      params: serviceParameterValues,
      items: [gridFormData],
    },
    onSuccess: () => {
      actorDispatch('refreshView', resource, {
        callerScopeName: 'grid.helper => runReportEditService',
        disableDebounce: true,
      });

      onSuccess();
    },
    onFailure: error => {
      showNotificationForUnknownError(error);
    },
  });
};

const cacheReportEditableColumnServices = {};
/**
 * @function getReportEditableColumnServices
 * @param metaData {GeneralMetaData}
 * @returns {Record<string, boolean>}
 */
export const getReportEditableColumnServices = (
  metaData: GeneralMetaData,
): Record<string, boolean> => {
  if (!metaData || !metaData.reportId || !metaData.reportEditableColumnServices)
    return {};

  if (cacheReportEditableColumnServices[metaData.reportId])
    return cacheReportEditableColumnServices[metaData.reportId];

  const list = Object.fromEntries(
    metaData.reportEditableColumnServices.map(item => {
      return [item.columnName.toLowerCase(), true];
    }) ?? [],
  );

  cacheReportEditableColumnServices[metaData.reportId] = list;
  return list;
};
