import { FC, Fragment, useState } from 'react';
import {
  Button,
  Icon,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { downloadFile, getBlobFile } from '../helper/FileHelper';
import { actorGetActionValue, SortOrderType } from '../type/actor-setup';
import { clone } from '../helper/data-helper';
import { GeneralMetaData } from '../helper/Types';
import { getAdditionalColumns } from './grid/grid.helper';

import type { FinalFiltersType } from './filter-form';

type DownloadType = 'excel' | 'encodingText' | 'asciiText';
interface ExportButtonInterfaceProps {
  filter?: FinalFiltersType | null;
  resource: string;
  sort?: {
    field: string | null;
    order: SortOrderType;
  };
  disabled: boolean;
  relationMode?: boolean;
  fields?: string;
  metaData: GeneralMetaData;
}

const useStyles = makeStyles(() => ({
  container: {
    display: (props: { relationMode: boolean }) =>
      props.relationMode ? 'flex' : 'unset',
  },

  menuItem: {
    fontSize: 12,
  },
}));

const ExportButton: FC<ExportButtonInterfaceProps> = props => {
  const {
    sort,
    filter,
    resource,
    disabled,
    fields,
    relationMode = false,
    metaData,
  } = props;

  const gridData = actorGetActionValue('gridData')?.[resource]?.data ?? [];
  const additionalColumns = getAdditionalColumns(metaData, gridData);

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles({ relationMode });
  const translate = useTranslate();

  /**
   * close menu
   * @function closeMenu
   * @returns {void}
   */
  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * open menu
   * @function onExportButtonClick
   * @param {Event} event
   * @returns {void}
   */
  const onExportButtonClick = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * prepare parameters base of download type then call downloadFile function
   * @function onDownloadFileClick
   * @param {DownloadType} downloadType
   * @returns {Function} download function
   */
  const onDownloadFileClick = (downloadType: DownloadType) => {
    let params;

    const isMultiResult = metaData?.['reportType'] === 'MultiResult';
    let finalResource = clone(resource);

    if (relationMode && isMultiResult) {
      const activeTab = actorGetActionValue('activeTab', resource);
      //maybe activeTab equals 0
      if (typeof activeTab !== undefined && activeTab !== null) {
        finalResource = `${finalResource}/${activeTab}`;
      }
    }

    let finalFields = clone(fields);
    if (relationMode) {
      finalFields =
        (actorGetActionValue('relationFieldsForDisplay', finalResource) as
          | string
          | null) ?? '';
    }

    if (additionalColumns?.length) {
      additionalColumns.forEach(column => {
        finalFields += `,${column.name}`;
      });
    }

    if (downloadType === 'excel') {
      params = {
        link: `/${finalResource}/export/excel`,
        queryParams: {
          sort,
          filter,
          fields: finalFields,
        },
      };
    } else {
      params = {
        link: `/${finalResource}/export/text`,
        queryParams: {
          sort,
          filter,
          fields: finalFields,
          encoding: downloadType === 'encodingText' ? 'encoding' : 'ascii',
          withheader: downloadType === 'encodingText',
        },
      };
    }

    return async () => {
      closeMenu();
      downloadFile(
        await getBlobFile(params),
        resource,
        downloadType !== 'excel' ? 'txt' : 'xlsx',
      );
    };
  };

  return (
    <Fragment>
      <Tooltip title={translate('ra.action.export')}>
        <Button onClick={onExportButtonClick} color="primary" disabled={disabled}>
          <Icon>get_app</Icon>
        </Button>
      </Tooltip>

      <Menu
        id="export-button-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        onContextMenu={e => e.preventDefault()}
        variant="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('excel')}
        >
          {translate('ra.action.downloadExcelFile')}
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('encodingText')}
        >
          {translate('ra.action.downloadEncodingTextFile')}
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          disabled={disabled}
          onClick={onDownloadFileClick('asciiText')}
        >
          {translate('ra.action.downloadAsciiTextFile')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default ExportButton;
