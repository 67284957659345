import { type FC, ReactElement } from 'react';
import { TreeView } from 'devextreme-react';
import {
  Button,
  Card,
  Checkbox,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useStyles } from './permission-menu.style';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Skeleton } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import type { PermissionMenuViewInterface } from './permission-menu.type';
const PermissionMenuView: FC<PermissionMenuViewInterface> = (
  props,
): ReactElement => {
  const {
    firstTreeMenuItems,
    onCheckboxSelect,
    onRowClick,
    isLoading,
    data,
    isAdvanced,
    handleClickPermissionAdvance,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const dynamicHeight = isAdvanced
    ? '55vh'
    : Math.min(firstTreeMenuItems.length * 6 + 80, 70) + 'vh';

  return (
    <>
      <Card className={classes.container}>
        <Typography className={classes.title}>
          {translate('permissions.menuTitles')}
        </Typography>

        <div className={classes.divider} />

        <div className={classes.treeContainer}>
          {isLoading ? (
            <div className={classes.skeletonContainer}>
              {Array.from(new Array(20)).map(() => {
                return (
                  <div className={classes.skeleton}>
                    <Skeleton variant="rect" width={18} height={18}></Skeleton>
                    <Skeleton variant="text" width={'90%'} height={25}></Skeleton>
                  </div>
                );
              })}
            </div>
          ) : (
            <TreeView
              searchTimeout={500}
              virtualModeEnabled={true}
              searchExpr={'label'}
              selectAllText={translate('permissions.selectAll')}
              showCheckBoxesMode={
                isAdvanced ? 'normal' : data ? 'none' : 'selectAll'
              }
              selectionMode="multiple"
              height={dynamicHeight}
              onSelectAllValueChanged={onCheckboxSelect}
              onItemClick={onRowClick}
              scrollDirection="vertical"
              selectNodesRecursive={true}
              selectByClick={false}
              id="firstTree"
              items={firstTreeMenuItems}
              itemRender={menuItem => (
                <>
                  <div className={classes.itemTree}>
                    <div>
                      <span>
                        {menuItem.allowView && !isAdvanced ? (
                          <CheckBoxIcon color="primary" fontSize="small" />
                        ) : (
                          data &&
                          !isAdvanced && (
                            <CheckBoxOutlineBlankIcon fontSize="small" />
                          )
                        )}
                      </span>
                      <span className={classes.labelTree}>{menuItem.label}</span>
                    </div>
                  </div>
                </>
              )}
              searchMode="contains"
              searchEnabled={true}
              rtlEnabled={true}
              noDataText={translate('tree.noDataToDisplay')}
              searchEditorOptions={{ placeholder: translate('ra.action.search') }}
              onItemSelectionChanged={
                isAdvanced ? handleClickPermissionAdvance : onCheckboxSelect
              }
              className={classes.tree}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default PermissionMenuView;
