import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import lodashDebounce from 'lodash/debounce';
import momentJalali from 'moment-jalaali';
import moment from 'moment';

import GridView from './grid.view';
import {
  DROPDOWN_FIELD,
  getTypeByField,
  inputTypes,
} from '../../helper/InputHelper';
import lodashGet from 'lodash/get';
import { useLocale, useTranslate } from 'react-admin';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  URLInfo,
} from '../../type/actor-setup';
import {
  checkGridColumnHasDynamic,
  getFinalColumnsForGrid,
  getReportEditableColumnServices,
  prepareColumnsForGridFromMeta,
  runReportEditService,
} from './grid.helper';

import { clone, isEmpty, isEmptyObject } from '../../helper/data-helper';
import LoadingBox from '../LoadingBox';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import {
  CONFIG_GROUP_COLUMN,
  CONFIG_VISIBLE_COLUMNS,
  CONFIG_LIST_COLUMN_WIDTH,
  CONFIG_FIXED_COLUMNS,
} from '../../core/configProvider';
import lodashMap from 'lodash/map';
import { DataGrid } from 'devextreme-react';
import { getServices, isGridInlineEditable } from '../../helper/MetaHelper';
import {
  extractAllFieldsFromTabList,
  getFormDefaultValues,
} from '../form/form.helper';
import lodashFilter from 'lodash/filter';
import { handleInlineEdit } from '../../api/grid-api';
import lodashFind from 'lodash/find';
import { findRowStateColorField, getColorById } from '../../helper/RowColorHelper';
import { useStyles } from './grid.style';
import { runServiceValidationClient } from '../../helper/validation-helper';
import { useFormSave } from '../form/hooks/use-form-save';
import lodashMerge from 'lodash/merge';
import { prepareViewFields } from '../show-record-with-relation/show-record-with-relation.helper';
import dxDataGrid from 'devextreme/ui/data_grid';

import { isRelationCellEditableWithoutTypeChecking } from '../relation-panel/relation-panel.helper';
import { getCalendarType } from '../../helper/DateHelper';
import { getGridAdditionalData } from './grid.helper';
import { replaceFarsiCharactersWithArabic } from '../../helper/TextHelper';
import { FilterValueStructureEnum } from '../filter-form/filter-form.helper';
import {
  removeOnDispatches,
  showNotification,
} from '../../helper/general-function-helper';
import {
  prepareGridInputsCharacteristics,
  setGridFixedColumnsSetting,
} from './grid.helper';
import {
  getInputsInitialAppearanceCharacteristics,
  getProcessTaskByMetaDataAndRecord,
} from '../../helper/meta-helper';
import {
  formatNumber,
  wrapNegativeNumberWithParentheses,
} from '../dynamic-input/number-input/number-text-field/number-text-field.helper';

import type { InputAppearanceCharacteristics } from '../../helper/meta-helper.type';
import type {
  FieldType,
  GeneralMetaData,
  GlobalParametersInterface,
  MetaData,
  MetaDataBase,
} from '../../helper/Types';
import type {
  ApiDataInterface,
  DevExpressContextMenuEvent,
  FormFieldInterface,
  GridPropsInterface,
  GroupColumnsInterface,
  InputsCustomFunctions,
  LastFocusCell,
  onChangeInputParameter,
  OnEditingStartInterface,
  TotalSummaryItemsInterface,
  WebSettingFixedColumnsInterface,
} from './grid.type';

let formAsyncActionList: Promise<unknown>[] = [];
const GridController: FC<GridPropsInterface> = memo(props => {
  const locale = useLocale();

  const {
    resource,
    onSelectCheckbox,
    parentInfo,
    isReport,
    relation,
    parentRecord,
    setSort,
    fields,
    hasEdit,
    metaData,
    onRowClick,
    relationMode = false,
    addToFilterRequestList,
    hasShow,
    basePath,
    redirect,
    hasCreate,
    ids,
    data,
    relationResource = '',
    quickEditButton = false,
    setFilters,
    enableSelection = true,
    isTopFilterOpen,
    filterValues,
    idDropDown = null,
    selectedRows,
    sort,
    enableClientExportExcel,
    isGroupingOpen = false,
    summaryData,
    hasColumnChooser,
    simpleGridData,
    isMap,
  } = props;

  const reportEditableColumnServices = getReportEditableColumnServices(metaData);

  const translate = useTranslate();
  const classes = useStyles();

  const serviceListRef = useRef([]);
  const inputsCustomFunctionRef = useRef<Record<string, InputsCustomFunctions>>({});

  useEffect(() => {
    serviceListRef.current = getServices(metaData)?.filter(
      service => service.related === 'SingleRecord',
    );
  }, []);

  const [columnWidthSetting, setColumnWidthSetting] = useState<Record<
    string,
    unknown
  > | null>(null);

  const groupColumns = getAppSettings(`${CONFIG_GROUP_COLUMN}_${resource}`, true)
    ?.value as Array<GroupColumnsInterface>;

  const visibleColumns = getAppSettings(
    `${CONFIG_VISIBLE_COLUMNS}_${resource}`,
    true,
  )?.value as string[];

  const [formMode, setFormMode] = useState<'edit' | 'add'>('edit');

  const gridRef = useRef<DataGrid>(null);

  const preparedFieldsRef = useRef<FormFieldInterface>({
    allFields: [],
  });

  const defaultFormValuesRef = useRef<Record<string, unknown>>({});
  const lastSearchedDropDownData = useRef<Record<string, unknown>[]>([{}]);
  const lastFocusCellRef = useRef<LastFocusCell>({});
  const [isGridGroupingEnable, setIsGridGroupingEnable] = useState(isGroupingOpen);
  const onDispatchRefs = useRef<{ [name: string]: symbol }>({});

  const [forceIsTopFilterOpen, setForceIsTopFilterOpen] = useState<boolean>(false);

  const formSave = useFormSave('gridForm');

  const preparedIdsFromData = data?.map?.(item => item?.id);
  const summaryDataRef = useRef<Record<string, unknown>>({});
  const updatedResourceRef = useRef<string>(resource);

  const fixedColumns = getAppSettings(`${CONFIG_FIXED_COLUMNS}_${resource}`, true)
    ?.value as WebSettingFixedColumnsInterface[];

  let hasDynamic;

  if (!isEmptyObject(data)) {
    hasDynamic = checkGridColumnHasDynamic(Object.values(data), metaData);
  }

  // prettier-ignore

  //Disable remote sort when we get all data from server
  const onlyClientSort = hasDynamic;
  // const gridDataInActor = actorGetActionValue('gridData', resource) as GridDataInterface | null;
  // const onlyClientSort = Boolean(
  //   gridDataInActor &&
  //     Number(gridDataInActor?.totalCount) <
  //       Number(gridDataInActor?.requestParameters?.pagination?.perPage),
  // );

  let inputsInitialAppearanceCharacteristics: Record<
    string,
    InputAppearanceCharacteristics
  > = {};

  useEffect(() => {
    actorOnDispatch('showColumnChooser', detail => {
      if (resource == Object.keys(detail)[0]) {
        gridRef?.current?.instance.showColumnChooser();
      }
    });
  }, []);

  useEffect(() => {
    actorDispatch('gridIDs', ids ?? preparedIdsFromData, {
      path: `${props.resource}.allIDs`,
    });
    selectRowsByDebounce(selectedRows);
  }, [ids, preparedIdsFromData]);

  useEffect(() => {
    if (isEmptyObject(summaryData)) return;

    actorSetActionValue('additionalData', data.additionalData, {
      path: resource,
    });

    summaryDataRef.current = summaryData!;

    if (gridRef.current) {
      gridRef.current.instance.repaint();
      gridRef.current.instance.endCustomLoading();
    }
  }, [summaryData]);

  useEffect(() => {
    gridRef.current?.instance.endCustomLoading();
  }, [data]);

  /**
   * @function selectRowsByDebounce
   * @returns { void }
   */
  const selectRowsByDebounce = useCallback(
    lodashDebounce((selectedIds: number[]) => {
      gridRef.current?.instance.selectRows(selectedIds, false);
    }, 1000),
    [],
  );

  /**
   * FIXME: It's a code that has so poor performance, check and fix it as soon as possible
   */
  useEffect(() => {
    const listenerId = actorOnDispatch('gridFormData', gridFormData => {
      if (
        gridFormData == null ||
        isEmptyObject(metaData) ||
        isEmpty(resource) ||
        isEmpty(parentInfo?.parentResource)
      ) {
        return;
      }

      inputsInitialAppearanceCharacteristics = prepareGridInputsCharacteristics({
        resource,
        metaData,
        parentResource: parentInfo?.parentResource,
      });
    });

    return () => {
      removeOnDispatches([
        {
          actionName: 'gridFormData',
          listenerId,
        },
      ]);
    };
  }, [resource, metaData, parentInfo]);

  useEffect(() => {
    //show loading
    updatedResourceRef.current = resource;
    const columnWidthSetting =
      getAppSettings<Record<string, unknown>>(getConfigColumnWidthKey(), true)
        .value ?? {};

    setColumnWidthSetting(columnWidthSetting);
  }, [resource, relationResource, idDropDown]);

  useEffect(() => {
    const onDispatchData: { actionName: keyof ActorActionList; id: symbol }[] = [];

    let id = actorOnDispatch(
      'additionalData',
      data => {
        summaryDataRef.current = data?.[updatedResourceRef.current]?.summary ?? {};
      },
      { preserve: false },
    );

    onDispatchData.push({
      actionName: 'additionalData',
      id,
    });

    id = actorOnDispatch(
      'isDrawerOpen',
      isDrawerOpen => {
        const scrollableDivElement = gridRef.current?.['_element'].querySelector(
          '.dx-scrollable-container',
        );
        if (!isDrawerOpen || scrollableDivElement == null) return;

        /**
         * Drawer and all its `ui` functionalities works with `transition`, so we can't use `addEventListener('transitioned', ...)`
         * so we have to do the following, unfortunately
         */
        setTimeout(() => {
          // prettier-ignore
          scrollableDivElement.scrollLeft = scrollableDivElement.scrollWidth;
        }, 300);
      },
      {
        preserve: false,
      },
    );

    onDispatchData.push({
      actionName: 'isDrawerOpen',
      id,
    });

    id = actorOnDispatch('changeScreenZoom', scale => {
      const gridElement = gridRef.current?.['_element'];
      if (gridElement) {
        gridElement.style.transform = `scale(${scale})`;
      }
    });

    onDispatchData.push({
      actionName: 'changeScreenZoom',
      id,
    });

    return () => {
      for (const { actionName, id: listenerId } of onDispatchData) {
        actorRemoveAction({ actionName, listenerId });
      }
    };
  }, []);

  useEffect(() => {
    //prettier-ignore
    summaryDataRef.current = actorGetActionValue('additionalData', resource)?.summary ?? {};

    prepareAllFields();

    onDispatchRefs.current['isTopFilterOpen'] = actorOnDispatch(
      'isTopFilterOpen',
      response => {
        const [module, moduleTable] = resource?.split('/');

        if (module && moduleTable) {
          setForceIsTopFilterOpen(
            response[`${module}/${moduleTable}`] ??
              response?.[relationResource as string],
          );
        }
      },
    );

    //click on process button should close grid form
    onDispatchRefs.current['loading'] = actorOnDispatch('loading', loadingList => {
      if (loadingList?.[resource]) {
        gridRef.current?.instance.beginCustomLoading(translate('form.showData'));
      } else {
        gridRef.current?.instance.endCustomLoading();
      }

      if (loadingList?.processChangeLineButtons) {
        prepareAllFields(); //get allFields again ,when process changed;
        setFormMode('edit');
      }
    });

    //click on process button should close grid form
    onDispatchRefs.current['quickDialog'] = actorOnDispatch('quickDialog', () => {
      setFormMode('edit');
    });

    actorOnDispatch('isGridGroupingEnable', groupingEnabled => {
      if (groupingEnabled[`${resource}`] !== undefined) {
        setIsGridGroupingEnable(groupingEnabled[`${resource}`]);
      }
    });

    return () => {
      Object.keys(onDispatchRefs.current).forEach(key => {
        actorRemoveAction({
          actionName: key as keyof ActorActionList,
          listenerId: onDispatchRefs.current[key],
        });
      });
    };
  }, []);

  /**
   * @function allowAddInGrid
   * @returns {boolean}
   */
  function allowAddInGrid(): boolean {
    //allow add in relation panel
    const allowAddInGrid = lodashGet(metaData, ['config', 'allowAddInGrid']);
    return (
      Boolean(hasCreate) &&
      !!allowAddInGrid &&
      !isReport &&
      !isEmptyObject(parentInfo)
    );
  }

  /**
   * get all fields from meta
   * @function prepareAllFields
   * @returns {void}
   */
  async function prepareAllFields(): Promise<void> {
    if (!allowAddInGrid() && !isGridInlineEditable(metaData)) {
      return;
    }

    const record = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}`,
    )! as {
      FORM?: Record<string, unknown>;
      PARENT_RECORD?: Record<string, unknown>;
      FULL?: Record<string, unknown>;
    };

    const tabList = prepareViewFields(
      record?.FORM ?? {},
      metaData as GeneralMetaData,
    );

    const allFields: Array<FieldType> = extractAllFieldsFromTabList(tabList);

    preparedFieldsRef.current = { allFields };
  }

  /**
   * @function allowEditInline
   * @param {FieldType} field
   * @returns {boolean}
   */
  function allowEditInline(field: FieldType): boolean {
    if (isEmptyObject(field)) return false;
    const fieldType = getTypeByField(field);

    //boolean field handled in handleInlineEdit
    if (
      reportEditableColumnServices[field.name] &&
      fieldType !== inputTypes.BOOLEAN_FIELD
    )
      return true;

    //show disable field value
    if (formMode == 'add') return true;

    //boolean field handled in handleInlineEdit
    //disable inline edit in dropdown popup
    if (
      !isEmpty(idDropDown) ||
      fieldType == inputTypes.BOOLEAN_FIELD ||
      isReport ||
      !hasEdit
    )
      return false;

    return (
      isRelationCellEditableWithoutTypeChecking(field, metaData, hasEdit) &&
      isEnableInput(field.name)
    );
  }

  /**
   * get default value for add in grid
   * @function prepareDefaultValues
   * @returns {Promise<void>}
   */
  async function prepareDefaultValues(): Promise<void> {
    if (!isEmptyObject(defaultFormValuesRef.current)) {
      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(defaultFormValuesRef.current)),
        {
          replaceAll: true,
          callerScopeName: 'GridController => prepareDefaultValues',
        },
      );
      return;
    }

    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    );
    const urlInfo = actorGetActionValue('urlInfo');
    const currentResource = { value: resource, type: FormKeyMode.RELATION };

    if (!parentInfo) return;

    const formDefaultValues = await getFormDefaultValues(
      urlInfo as URLInfo,
      parentInfo,
      preparedFieldsRef.current.allFields,
      globalParameters as GlobalParametersInterface,
      currentResource,
      true,
    );

    if (formDefaultValues) {
      defaultFormValuesRef.current = clone(formDefaultValues);

      const gridFormData = actorGetActionValue('gridFormData')!;
      actorDispatch(
        'gridFormData',
        lodashMerge(gridFormData, clone(formDefaultValues)),
        {
          replaceAll: true,
          callerScopeName: 'GridController => prepareDefaultValues 2',
        },
      );
    }
  }

  /**
   * @function onEditingStart
   * @param { OnEditingStartInterface } event
   * @returns { void } void
   */
  function onEditingStart(event: OnEditingStartInterface): void {
    if (formMode == 'add') return;

    if (!isEmptyObject(event.data)) {
      defaultFormValuesRef.current = {}; //dont use default value in edit mode

      actorDispatch('gridFormData', event.data, {
        replaceAll: true,
        callerScopeName: 'GridController => onEditingStart',
      });
    } else {
      actorDispatch(
        'gridFormData',
        {},
        {
          replaceAll: true,
          callerScopeName: 'GridController => onEditingStart 2',
        },
      );
      gridRef.current?.instance.cancelEditData();
      if (formMode != 'edit') {
        setFormMode('edit');
      }
    }
  }

  /**
   * @function onInitNewRow
   * @returns {void}
   */
  function onInitNewRow(): void {
    // fixme: this function will be called by DataGrid twice
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
        callerScopeName: 'GridController => onInitNewRow',
      },
    );

    prepareAllFields().then(() => {
      prepareDefaultValues().then(() => {
        if (formMode != 'add') {
          setFormMode('add');
          setTimeout(() => {
            if (!gridRef.current?.instance.hasEditData()) {
              gridRef.current?.instance.addRow();
            }
          }, 500);
        }
      });
    });
  }

  /**
   * @function onSuccessSaveForm
   * @returns {void}
   */
  const onSuccessSaveCreateForm = (): void => {
    actorDispatch('refreshView', 'record', {
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
      disableDebounce: true,
    });

    if (parentInfo && relation?.childFieldName) {
      getGridAdditionalData({
        parentInfo: parentInfo,
        childFieldName: relation?.childFieldName,
        parentFieldName: relation?.parentFieldName ?? '',
        relationMetaData: metaData,
        relationResource: resource,
        onSuccess: handleSuccessGetAdditionalData,
      });
    }

    actorDispatch('gridFormData', clone(defaultFormValuesRef.current), {
      replaceAll: true,
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
    });

    actorSetActionValue('loading', false, {
      path: resource,
      callerScopeName: 'GridController => onSuccessSaveCreateForm',
    });
  };

  /**
   * @function onFailureSaveForm
   * @param {string | null} error
   * @returns {void}
   */
  function onFailureSaveForm(error: string): void {
    actorSetActionValue('loading', false, { path: resource });

    //Don't set "error" type in showNotification, because focus on cell not working
    if (typeof error == 'string') {
      if (typeof error.split == 'function') showNotification(error.split('^')[0]);
      else showNotification(error);
    }

    gridRef.current?.instance.endCustomLoading();
  }

  /**
   * @function saveRow
   * @param {Record<string, any>} e
   * @returns {Promise<void>}
   */
  async function saveRow(e: Record<string, any>): Promise<void> {
    if (formMode != 'add' || actorGetActionValue('loading', resource)) {
      return;
    }
    actorSetActionValue('loading', true, { path: resource });
    e.cancel = true; //don't close form

    //run service validation
    const gridInstance = gridRef.current?.instance;
    if (gridInstance) {
      gridInstance.focus(gridInstance.getCellElement(0, 1) as Element);
    }

    const { parentFieldName, childFieldName } = relation ?? {};

    let quickCreateData = {};
    if (!isEmpty(childFieldName)) {
      quickCreateData = {
        [childFieldName!]: lodashGet(parentRecord, parentFieldName),
      };
    }

    try {
      await Promise.all(formAsyncActionList);
      formAsyncActionList = [];
    } catch (error) {
      console.error('saving error => ', error);
    }

    const gridFormData = actorGetActionValue('gridFormData')!;
    formSave(gridFormData, {
      id: null,
      isCreateMode: true,
      additionalFormData: quickCreateData,
      relationMode,
      formName: 'gridForm',
      resource: resource,
      onFailure: onFailureSaveForm,
      onSuccess: onSuccessSaveCreateForm,
    });
  }

  /**
   * editingOnChangesChange
   * @function editCellValue
   * @param { string } fieldName
   * @returns { void }void
   */
  function editCellValue(fieldName: string): void {
    const gridFormData = actorGetActionValue('gridFormData')!;
    if (!isEmptyObject(gridFormData)) {
      const changedData = gridFormData[fieldName];
      const filedObject = getFieldObjectByInputName(fieldName);
      if (!filedObject) return;

      const rowInfo = { id: gridFormData.id };
      const formData = { [filedObject.name]: changedData };

      if (filedObject) {
        if (getTypeByField(filedObject) === DROPDOWN_FIELD) {
          const selectedDropDownInfo = lodashFilter(
            lastSearchedDropDownData.current[filedObject.name]?.result,
            formData,
          );
          rowInfo[`__dropdownvalue_${filedObject.name}`] = selectedDropDownInfo[0];
        }
        handleInlineEdit(
          formData,
          rowInfo,
          resource,
          onFailureSaveForm,
          onSuccessInlineEditForm,
          false,
        );
      }
    }
  }

  /**
   * @function getConfigColumnWidthKey
   * @returns {string}
   */
  function getConfigColumnWidthKey(): string {
    let key = `${CONFIG_LIST_COLUMN_WIDTH}_`;
    key += relationMode ? relationResource : resource;

    if (idDropDown) {
      key += '_dropDown_' + idDropDown;
    }
    return key;
  }

  /**
   * @function getPreparedRows : prepare rows for grid
   * @returns { Record<string, unknown>[]}
   */
  function getPreparedRows(): Record<string, unknown>[] {
    if (isEmptyObject(data)) return [];

    const dataArray = Object.values(data);

    return ids && ids.filter(item => item).length
      ? ids.map(id => dataArray.find(item => item.id === id))
      : (Object.values(data) as Array<Record<string, unknown>>);
  }

  /**
   * getTotalSummaryItems
   * @returns {TotalSummaryItemsInterface[]}
   */
  function getTotalSummaryItems(): TotalSummaryItemsInterface[] {
    const tempTotalSummaryItems: TotalSummaryItemsInterface[] = [];
    fields.forEach((field: FieldType) => {
      if (!field) {
        return;
      }

      if (field.hasSummary) {
        tempTotalSummaryItems.push({
          columnName: String(field.name),
          type: 'sum',
          format: field.format,
        });
      }
    });
    return tempTotalSummaryItems;
  }

  /**
   * getFieldObjectByInputName
   * @param inputName
   * @returns any
   */
  function getFieldObjectByInputName(inputName: string): FieldType | null {
    const { allFields } = preparedFieldsRef.current; // should remove relationHiddenFields
    const field = lodashFilter(
      allFields,
      row => row.name == inputName || row.relatedName == inputName,
    )[0];

    return field ?? null;
  }

  /**
   * changeCheckboxSelection
   * @param checkBoxSelectedInfo
   * @returns {TotalSummaryItemsInterface[]}
   */
  function changeCheckboxSelection(checkBoxSelectedInfo): void {
    const { selectedRowKeys, currentDeselectedRowKeys } = checkBoxSelectedInfo;

    actorDispatch('gridIDs', selectedRowKeys, {
      path: `${resource}.selectedIDs`,
    });

    if (onSelectCheckbox && typeof onSelectCheckbox === 'function') {
      onSelectCheckbox(selectedRowKeys, currentDeselectedRowKeys);
    }
  }

  /**
   * setGridSetting
   * @param {Record<string, number>} columnWidthList
   * @returns {void}
   */
  function setGridSetting(columnWidthList: Record<string, number>): void {
    const key = getConfigColumnWidthKey();
    setAppSettings({
      key,
      value: columnWidthList,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  }

  /**
   * @function setGroupGridSetting
   * @param groupColumns : group columns
   * @returns {void}
   */
  function setGroupGridSetting(groupColumns: GroupColumnsInterface[]): void {
    gridRef.current?.instance.beginCustomLoading(translate('form.sendingData'));

    setAppSettings({
      key: `${CONFIG_GROUP_COLUMN}_${resource}`,
      value: groupColumns,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  }

  /**
   * getGridClass
   * @returns any
   */
  function getGridClass(): string {
    const rows = getPreparedRows();

    if (relationMode) {
      if (rows?.length == 0) {
        return classes.emptyRelationGrid;
      }

      return !!actorGetActionValue('fullScreenItem')
        ? classes.relationGridFullScreen
        : classes.relationGrid;
    } else {
      return classes.grid;
    }
  }

  /**
   * @function setRowColor
   * @param {any} e
   * @returns {void} void
   */
  function setRowColor(e: any): void {
    if (e.rowType != 'data') return;

    const rowStateColorField = findRowStateColorField(
      lodashGet(metaData, 'fields', lodashGet(metaData, 'columns')),
    );

    if (!rowStateColorField) return;

    const targetKey = rowStateColorField.relatedName ?? rowStateColorField.title;

    const colorValue = e.data?.[targetKey];
    const isHexColor = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/i.test(colorValue);
    const isValidColor =
      (!Number.isNaN(colorValue) && colorValue >= 0) || isHexColor;

    if (e.rowType === 'data' && isValidColor) {
      const customRowColor = isHexColor ? colorValue : getColorById(colorValue);

      if (customRowColor !== null) {
        e.rowElement.style.backgroundColor = customRowColor;
        const devextremeClass = relationMode
          ? 'dx-row dx-data-row dx-row-lines dx-column-lines'
          : 'dx-row dx-data-row';
        //if we dont set this, the row gets zebra style(rowAlternationEnabled property)
        e.rowElement.setAttribute('class', devextremeClass);
      }
    }

    e.rowElement.setAttribute('data-test-grid-row', `${e.data?.id}`);
    e.rowElement.setAttribute('data-style-grid-row', 'gridRow');
  }

  /**
   * @function onCellClick
   * @param {any} e
   * @returns {void} void
   */
  function onCellClick(e: any): void {
    if (!onlyClientSort && e.rowType == 'header' && e.column?.command !== 'select') {
      changeSorting(e);
    }
  }

  /**
   * @function setGridVisibleColumnsSetting
   * @param {string[]} visibleColumns
   * @returns {void}
   */
  function setGridVisibleColumnsSetting(visibleColumns: string[]): void {
    gridRef.current?.instance.beginCustomLoading(translate('form.sendingData'));

    setAppSettings({
      key: `${CONFIG_VISIBLE_COLUMNS}_${resource}`,
      value: visibleColumns,
      forUser: true,
      onSuccess: () => {
        gridRef.current?.instance.endCustomLoading();
      },
    });
  }

  /**
   * @function changeSorting
   * @param {Record<string, any>} element
   * @returns {void}
   */
  function changeSorting(element: Record<string, any>): void {
    let direction = element.column.sortOrder;
    if (direction == undefined || direction == 'undefined') {
      direction = 'desc';
    }
    const columnName = element.column.name;
    setSort?.(columnName, direction);
  }
  /**
   * @function getGridGroupItems
   * @param grid
   * @returns object[]
   * help: https://supportcenter.devexpress.com/ticket/details/t823647/datagrid-get-current-grouping-columns
   */
  function getGridGroupItems(grid: dxDataGrid): GroupColumnsInterface[] {
    const columnsOptions: GroupColumnsInterface[] = [];
    const count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      const columnOptions = grid.columnOption(index);
      if (columnOptions.groupIndex !== undefined) {
        columnsOptions.push({
          columnName: columnOptions.name,
          groupIndex: columnOptions.groupIndex,
        });
      }
    }

    return columnsOptions;
  }

  /**
   * @function getVisibleColumns
   * @param grid
   * @returns string[]
   */
  function getVisibleColumns(grid: dxDataGrid): string[] {
    const columnsOptions: string[] = [];
    const count = grid.columnCount();
    for (let index = 0; index < count; index++) {
      const columnOptions = grid.columnOption(index);
      if (columnOptions.visible !== undefined && columnOptions.visible) {
        columnsOptions.push(columnOptions.name);
      }
    }

    return columnsOptions;
  }

  /**
   * onOptionChanged
   * @param event
   * @returns {void}
   */

  const onOptionChanged = lodashDebounce((event): void => {
    if (!event || !event.fullName || hasDynamic) return;

    if (event.fullName.indexOf('width') != -1) {
      const columns = prepareColumnsForGridFromMeta({
        fields,
        metaData,
        locale,
        columnWidthSetting,
      });
      const visibleColumns = event.component?.getVisibleColumns();

      const columnWidthList: Record<string, number> = {};
      lodashMap(visibleColumns, gridColumn => {
        const columnInfo = lodashFind(columns, ['name', gridColumn.name]);
        if (!isEmptyObject(columnInfo)) {
          const columnKey = columnInfo.field?.id
            ? columnInfo.field.id
            : gridColumn.name;
          columnWidthList[columnKey] =
            gridColumn.width > 500 ? 500 : gridColumn.width;
        }
      });
      setGridSetting(columnWidthList);
    } else if (event.fullName.indexOf('visible') != -1) {
      setGridVisibleColumnsSetting(getVisibleColumns(event.component));
    }
    if (event && event.fullName && event.fullName.indexOf('groupIndex') != -1) {
      setGroupGridSetting(getGridGroupItems(event.component));
    }
    if (event && event.fullName && event.fullName.indexOf('filterValue') !== -1) {
      if (!isEmpty(event.value)) {
        //e.fullName is like "columns[2].filterValue"
        const colIndex = parseInt(
          event.fullName
            .match(/\[\d+\]/)[0]
            .replace('[', '')
            .replace(']', ''),
        );
        //replace Farsi character with arabic in filter value
        gridRef.current?.instance.columnOption(
          colIndex,
          'filterValue',
          replaceFarsiCharactersWithArabic(event.value),
        );
      }

      if (typeof setFilters == 'function') {
        let combinedFilter = event.component?.getCombinedFilter(true);
        const filters = {};

        if (combinedFilter?.length > 0) {
          combinedFilter = Array.isArray(combinedFilter[0])
            ? combinedFilter
            : [combinedFilter];

          lodashMap(combinedFilter, item => {
            if (Array.isArray(item)) {
              filters[item[FilterValueStructureEnum.KEY]] = [
                item[FilterValueStructureEnum.KEY],
                item[FilterValueStructureEnum.OPERATOR],
                replaceFarsiCharactersWithArabic(
                  item[FilterValueStructureEnum.VALUE],
                ),
              ];
            }
          });
        }
        // console.log(filters,'filters');

        setFilters(filters);
      }
    } else if (event.fullName.indexOf('fixed') != -1) {
      setGridFixedColumnsSetting(resource, event.component);
    }
  }, 500);

  /**
   * @function runServiceValidation
   * @param { string } fieldName
   * @returns { Promise<void> } Promise<void>
   */
  const runServiceValidation = async (fieldName: string): Promise<void> => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    await runServiceValidationClient(
      preparedFieldsRef.current.allFields,
      fieldName,
      gridFormData,
      locale,
      metaData as MetaDataBase,
      resource,
      relationResource,
      ({ fieldName, value }) => (gridFormData[fieldName] = value), //update form data
      {},
      translate,
      true,
      showNotification,
      false,
    ).then(() => {
      actorDispatch('gridFormData', gridFormData, {
        replaceAll: true,
        callerScopeName: 'GridController => runServiceValidation',
      });
    });
  };

  /**
   * @function focusOnLastInput
   * @return { void } void
   */
  function focusOnLastInput(): void {
    const gridInstance = gridRef.current?.instance;

    if (!gridInstance) return;

    const cellElement = gridInstance.getCellElement(
      lastFocusCellRef.current?.rowIndex as number,
      lastFocusCellRef.current?.columnIndex as number,
    );

    if (cellElement) {
      gridInstance.focus(cellElement);
    }
  }

  /**
   * @function onChangeInput
   * @param { string } fieldName string
   * @param { unknown } value Can be any type, boolean, string, array, etc...
   * @param { boolean } submitValue
   * @returns { Promise<void> } Promise<void>
   */
  const onChangeInput = async (
    parameters: onChangeInputParameter,
  ): Promise<void> => {
    const { fieldName, value, submitValue = true } = parameters;

    let gridFormData = actorGetActionValue('gridFormData')!;

    if (value == gridFormData[fieldName]) return; //if value not changed, return

    try {
      const field = getFieldObjectByInputName(fieldName);
      if (field) {
        gridFormData = { ...gridFormData, [fieldName]: value };

        let parentMetaData: MetaData | undefined;

        if (parentInfo?.parentResource) {
          parentMetaData =
            actorGetActionValue('metaData')?.[parentInfo.parentResource];
        }

        const formDataCharacteristics = getInputsInitialAppearanceCharacteristics({
          parentMetaData: parentMetaData as GeneralMetaData | undefined,
          currentMetaData: metaData,
          parentRecord: parentRecord,
          currentRecord: gridFormData,
          currentProcessInfo: getProcessTaskByMetaDataAndRecord(
            metaData as GeneralMetaData,
            gridFormData,
          ),
        });

        for (const name in inputsCustomFunctionRef.current) {
          if (
            inputsCustomFunctionRef.current[name] &&
            formDataCharacteristics?.[name]
          ) {
            inputsCustomFunctionRef.current[name].updateFormDataCharacteristics?.(
              formDataCharacteristics[name],
            );
          }
        }

        actorSetActionValue('gridFormData', gridFormData, {
          callerScopeName: 'GridController => onChangeInput',
        });

        if (submitValue) {
          formMode == 'add' &&
            formAsyncActionList.push(runServiceValidation(fieldName));
          formMode == 'edit' && editCellValue(fieldName); //don't need to run service in inline edit
        }
      } else if (reportEditableColumnServices[fieldName]) {
        if (submitValue) {
          runReportEditService({
            fieldName,
            metaData,
            resource,
            gridFormData: { ...gridFormData, [fieldName]: value },
            onSuccess: onSuccessSaveCreateForm,
          });
        }
      }
    } catch (error) {
      console.log('changeFormValueWithValidate failed: ', error);
    }
  };

  /**
   * @function isEnableInput
   * @param {string} fieldName string
   * @returns {boolean} boolean
   */
  function isEnableInput(fieldName: string): boolean {
    if (reportEditableColumnServices[fieldName]) return true;

    const activeRecord = actorGetActionValue('gridFormData');
    const field = getFieldObjectByInputName(fieldName);

    inputsInitialAppearanceCharacteristics = prepareGridInputsCharacteristics({
      resource,
      metaData,
      parentResource: parentInfo?.parentResource,
    });

    if (relationMode && field && resource && metaData && activeRecord) {
      if (
        !inputsInitialAppearanceCharacteristics[field.name]?.enable ||
        !inputsInitialAppearanceCharacteristics[field.name]?.visible
      ) {
        return false;
      }
    }

    //disable grid form in dropdown popup
    if (!field || !hasEdit || !isEmpty(idDropDown)) {
      return false;
    }

    return true;
  }

  /**
   * @function onEditCanceled
   * @returns {void} void
   */
  function onEditCanceled(): void {
    actorDispatch(
      'gridFormData',
      {},
      {
        replaceAll: true,
      },
    );
    if (formMode != 'edit') setFormMode('edit');
  }

  /**
   * onEditorPreparing
   * @param e
   * @returns {void}
   */
  function onEditorPreparing(e: Record<string, any>): void {
    //set default text field for filter row
    if (e.parentType === 'filterRow') {
      e.editorName = 'dxTextBox';
      return;
    }
  }

  /**
   * save grid data
   * @param data
   * @returns {void}
   */
  function onSaving(event: Record<string, any>): void {
    if (formMode == 'add') {
      event.cancel = true;
      saveRow(event);
      lastFocusCellRef.current = {};
    }
  }

  /**
   * @function handleSuccessGetAdditionalData
   * @param {Record<string, unknown>} editedData
   * @returns {void}
   */
  const handleSuccessGetAdditionalData = (data: ApiDataInterface): void => {
    summaryDataRef.current = data.additionalData.summary;
    actorSetActionValue('additionalData', data.additionalData, {
      path: resource,
    });
    updateGridDataSource();
  };

  /**
   * @function onSuccessInlineEditForm
   * @param {Record<string, unknown>} editedData
   * @returns {void}
   */
  const onSuccessInlineEditForm = (editedData: Record<string, unknown>): void => {
    const dataSource = gridRef.current?.instance.getDataSource();
    const store = dataSource?.store();
    store.update(editedData.id, editedData);
    if (parentInfo && relation?.childFieldName) {
      getGridAdditionalData({
        parentInfo: parentInfo,
        childFieldName: relation?.childFieldName,
        parentFieldName: relation?.parentFieldName ?? '',
        relationMetaData: metaData,
        relationResource: resource,
        onSuccess: handleSuccessGetAdditionalData,
      });
    } else {
      updateGridDataSource();
    }
  };

  /**
   * @function updateGridDataSource
   * @returns {void}
   */
  const updateGridDataSource = (): void => {
    const dataSource = gridRef.current?.instance.getDataSource();
    dataSource?.reload();
  };

  /**
   * @function formatTotalSummaryCell
   * @param {string} columnName
   * @returns {string}
   */
  function formatTotalSummaryCell(columnName: string): string {
    let summaryValue = lodashGet(summaryDataRef.current, columnName, 0); //typescript error
    summaryValue = formatNumber(summaryValue?.toFixed(2));
    return String(wrapNegativeNumberWithParentheses(summaryValue));
  }

  /**
   * @function formatTotalSummaryGroupItem
   * @param {string} columnName
   * @returns {string}
   */
  function formatTotalSummaryGroupItem(value: string): string {
    return String(wrapNegativeNumberWithParentheses(formatNumber(Number(value))));
  }

  /**
   * @function handleOnRowClick
   * @param {Record<string, any>} data
   * @param { string } rowType
   * @returns {void}
   */
  function handleOnRowClick(data: Record<string, unknown>, rowType?: string): void {
    if (rowType === 'group') return;
    if (typeof onRowClick == 'function') {
      return onRowClick(data);
    }
  }

  /**
   * onOptionChanged
   * @param e
   * @returns {void}
   */
  function onCellPrepared(event: Record<string, any>): void {
    if (event.data && event.rowType === 'data' && event.column.name) {
      const colorColumnName = `__${event.column.name}_backgroundcolor`;
      if (event.data[colorColumnName] && event.data[colorColumnName] != '')
        event.cellElement.style.backgroundColor = event.data[colorColumnName];
    }
  }

  /**
   * @function gridContextMenu
   * @param {DevExpressContextMenuEvent} event
   * @description https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onContextMenuPreparing
   */
  const gridContextMenu = useCallback((event: DevExpressContextMenuEvent) => {
    if (event.row?.rowType === 'data') {
      event.items = serviceListRef.current.map(serviceItem => {
        return {
          text: lodashGet(
            serviceItem,
            ['translatedTitle', locale],
            lodashGet(serviceItem, ['title']),
          ),
          onItemClick: () => {
            actorDispatch('runServiceDirectly', {
              service: serviceItem,
              targetSelectedId: event.row!.key,
            });
          },
        };
      });
    }
  }, []);

  /**
   * groupCellTemplate
   * @param {FieldType} field
   * @param {any} options
   * @returns {React.ReactNode}
   */
  const groupCellTemplate =
    (field: FieldType) =>
    (options: any): React.ReactNode => {
      const currentCalendar = getCalendarType(field?.fixCalendar);

      let finalValue = options.value;
      if (getTypeByField(field) == inputTypes.DATE_FIELD) {
        finalValue =
          currentCalendar === 'jalali'
            ? momentJalali(options.value).format('jYYYY-jMM-jDD')
            : moment(options.value).format('YYYY-MM-DD');
        finalValue = <div>{finalValue}</div>;
      }
      return (
        <>
          {field.translatedCaption?.[locale]}:{finalValue}
        </>
      );
    };

  const actorIsLoading = actorGetActionValue('loading')?.[resource];

  if (
    !simpleGridData &&
    (isEmptyObject(metaData) || columnWidthSetting == null || !!actorIsLoading)
  ) {
    return <LoadingBox />;
  }
  const gridRows = getPreparedRows();
  const gridColumns = getFinalColumnsForGrid({
    fields: clone(fields),
    metaData,
    locale,
    columnWidthSetting,
    gridRows,
  });

  return (
    <GridView
      changeCheckboxSelection={changeCheckboxSelection}
      formatTotalSummaryGroupItem={formatTotalSummaryGroupItem}
      addToFilterRequestList={addToFilterRequestList}
      formatTotalSummaryCell={formatTotalSummaryCell}
      totalSummaryItems={getTotalSummaryItems()}
      onFailureSaveForm={onFailureSaveForm}
      onEditorPreparing={onEditorPreparing}
      focusOnLastInput={focusOnLastInput}
      handleOnRowClick={handleOnRowClick}
      gridContextMenu={gridContextMenu}
      allowEditInline={allowEditInline}
      onEditCanceled={onEditCanceled}
      onEditingStart={onEditingStart}
      onCellPrepared={onCellPrepared}
      allowAddInGrid={allowAddInGrid}
      isEnableInput={isEnableInput}
      onChangeInput={onChangeInput}
      onInitNewRow={onInitNewRow}
      getGridClass={getGridClass}
      setRowColor={setRowColor}
      onCellClick={onCellClick}
      rows={gridRows}
      columns={gridColumns}
      onSaving={onSaving}
      groupCellTemplate={groupCellTemplate}
      inputsCustomFunctionRef={inputsCustomFunctionRef}
      isTopFilterOpen={isTopFilterOpen || forceIsTopFilterOpen} //all component should be move to actor
      enableClientExportExcel={enableClientExportExcel}
      hasColumnChooser={hasColumnChooser}
      relationResource={relationResource}
      quickEditButton={quickEditButton}
      onOptionChanged={onOptionChanged}
      enableSelection={enableSelection}
      lastFocusCell={lastFocusCellRef}
      isGroupingOpen={isGridGroupingEnable}
      relationMode={relationMode}
      filterValues={filterValues}
      visibleColumns={visibleColumns}
      fixedColumns={fixedColumns}
      groupColumns={groupColumns}
      parentInfo={parentInfo}
      formMode={formMode}
      metaData={metaData as GeneralMetaData}
      resource={resource}
      redirect={redirect}
      basePath={basePath}
      hasShow={hasShow}
      hasEdit={hasEdit}
      gridRef={gridRef}
      fields={fields}
      locale={locale}
      key={resource}
      sort={sort}
      isMap={isMap}
      onlyClientSort={onlyClientSort}
    />
  );
});

export default GridController;
