import { useCallback, useMemo } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import DatePicker from '../date-picker';
import moment from 'moment-jalaali';
import { useLocale } from 'react-admin';
import { Icon, IconButton, withStyles } from '@material-ui/core';
import '../JalaliDateInput.css';
import {
  getValue,
  CONFIG_CALENDAR,
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
} from '../../core/configProvider';
import {
  DATETIME_FIELD,
  DATE_FIELD,
  getTypeByField,
} from '../../helper/InputHelper';
import { isEmpty } from '../../helper/data-helper';
import { emptyFunction } from '../form/form.helper';

const styles = theme => ({
  closeIcon: {
    marginRight: '2px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },
});

const DateFilterInput = props => {
  const { value, onChange, field, getRef, classes, hastimepicker } = props;
  const calendarConfig = getValue(CONFIG_CALENDAR);
  const locale = useLocale();

  /**
   * value for date picker
   */
  const internalValue = useMemo(() => {
    if (!value) {
      return null;
    }

    if (typeof value !== 'object') {
      return moment(value, SERVER_DATE_FORMAT);
    } else {
      const checkDate = new Date(value);
      if (moment.isDate(checkDate)) {
        return value;
      }
    }

    return moment(value, SERVER_DATE_FORMAT);
  }, [value]);

  const handleChangeDate = useCallback(
    changedDate => {
      let newValue = changedDate
        ? changedDate.locale('en').format(SERVER_DATE_FORMAT)
        : '';
      if (getTypeByField(field) == DATETIME_FIELD) {
        newValue = changedDate
          ? changedDate.locale('en').format(SERVER_DATE_TIME_FORMAT)
          : '';
      }

      onChange(newValue);
    },
    [internalValue],
  );
  const clearHandler = () => {
    onChange('');
  };
  return (
    <div className="filterDatePickerContainer" data-test-field-name={field?.name}>
      <DatePicker
        onBlur={emptyFunction}
        inputFormat={calendarConfig === 'jalali' ? 'jYYYY-jMM-jDD' : 'YYYY-MM-DD'}
        onChange={handleChangeDate}
        value={internalValue}
        isGregorian={calendarConfig === 'gregorian'}
        timePicker={hastimepicker}
        showTodayButton={false}
        setTodayOnBlur={false}
        persianDigits={false}
        locale={locale}
        ref={getRef}
      />
      {!isEmpty(internalValue) && (
        <IconButton
          role={'clearable'}
          size="small"
          tabIndex={-1}
          onClick={clearHandler}
          data-test-input-name="auto-complete-search-button"
        >
          <Icon fontSize="small" className={classes.closeIcon}>
            close
          </Icon>
        </IconButton>
      )}
    </div>
  );
};

DateFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  hastimepicker: PropTypes.bool,
};

export default compose(withStyles(styles, { withTheme: true }))(DateFilterInput);
