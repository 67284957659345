import { getUrlSubdomain } from '../helper/UrlHelper';

export const API_URL = 'API_URL';
export const WEB_SOCKET_API_URL = 'WEB_SOCKET_API_URL';
export const API_NAME = 'API_NAME';
export const API_VERSION = 'API_VERSION';
export const SESSION_ID = 'SESSION_ID';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_WAREHOUSE_ID = 'USER_WAREHOUSE_ID';
export const USER_WAREHOUSE_TITLE = 'USER_WAREHOUSE_TITLE';
export const USER_ID = 'USER_ID';
export const USER_COMPANY_ID = 'USER_COMPANY_ID';
export const IS_ADMIN_USER = 'IS_ADMIN_USER';
export const SUB_DOMAIN = 'SUB_DOMAIN';

export const HEADER_HEIGHT = 'HEADER_HEIGHT';
export const HEADER_HEIGHT_XS = 'HEADER_HEIGHT_XS';
export const CONFIG_LOCALE = 'CONFIG_LOCALE';
export const CONFIG_THEME_DIR = 'CONFIG_THEME_DIR';
export const CONFIG_CALENDAR = 'CONFIG_CALENDAR';
export const CONFIG_PROFILE_SETTING = 'CONFIG_PROFILE_SETTING';
export const CONFIG_CACHED_MENU = 'CONFIG_CACHED_MENU';
export const CONFIG_CELL_WIDTH = 'CONFIG_CELL_WIDTH';
export const CONFIG_GROUP_COLUMN = 'CONFIG_GROUP_COLUMN';
export const CONFIG_VISIBLE_COLUMNS = 'CONFIG_VISIBLE_COLUMNS';
export const CONFIG_DRAWER_MENU_IS_OPEN = 'CONFIG_DRAWER_MENU_IS_OPEN';
export const CONFIG_FIXED_HEADER_PARAMS = 'CONFIG_FIXED_HEADER_PARAMS';
export const CONFIG_FIXED_MENU = 'CONFIG_FIXED_MENU';
export const CONFIG_ROUTE_PREFIX = 'CONFIG_ROUTE_PREFIX';
export const CONFIG_FIXED_RESOURCE = 'CONFIG_FIXED_RESOURCE';
export const CONFIG_DISABLE_FETCH_USER_SETTING = 'CONFIG_DISABLE_FETCH_USER_SETTING';
export const CONFIG_FIXED_COLUMNS = 'CONFIG_FIXED_COLUMNS';
export const CONFIG_DASHBOARD_ADVERTISEMENT_RESOURCE =
  'CONFIG_DASHBOARD_ADVERTISEMENT_RESOURCE';
export const CONFIG_PALETTE_COLORS = 'CONFIG_PALETTE_COLORS';
export const CONFIG_IS_BOTTOM_MENU_ENABLED = 'CONFIG_IS_BOTTOM_MENU_ENABLED';
export const CONFIG_CACHED_TODO_EXPANDED_GROUP = 'CONFIG_CACHED_TODO_EXPANDED_GROUP';
export const IN_ACTIVE_LOGIN_USER_TIMEOUT = 'IN_ACTIVE_LOGIN_USER_TIMEOUT';
export const DISABLE_CHAT_MODULE = 'DISABLE_CHAT_MODULE';

export const CONFIG_CURRENCY_SYMBOL = 'CONFIG_CURRENCY_SYMBOL';
export const CONFIG_CURRENCY_NAME = 'CONFIG_CURRENCY_NAME';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SERVER_TIME_FORMAT = 'HH:mm:ss';
export const USER_SETTING_VERSION = 'USER_SETTING_VERSION';
export const SERVICE_WORKER_CACHE_VERSION = 'SERVICE_WORKER_CACHE_VERSION';

export const DRAWER_OPEN_WIDTH = 'openWidth';
export const DRAWER_CLOSE_WIDTH = 'closeWidth';

// settings
export const GET_SETTING = 'getSetting';
export const GET_SETTING_FOR_USER = 'getSettingForUser';
export const SET_SETTING = 'setSetting';
export const SET_SETTING_FOR_USER = 'setSettingForUser';
export const DISABLE_MAIL_AND_TODO_MODULE = 'DISABLE_MAIL_AND_TODO_MODULE';

// relation panel sort
export const RELATION_PANEL_SORT = 'relationPanelSort';

export const DEFAULT = 'DEFAULT';
export const CONFIG_SAVED_FILTER = 'CONFIG_SAVED_FILTER';
export const CONFIG_LIST_COLUMN_CHOICE = 'CONFIG_LIST_COLUMN_CHOICE';
export const CONFIG_LIST_LAST_FILTER = 'CONFIG_LIST_LAST_FILTER';
export const CONFIG_LIST_SORT = 'CONFIG_LIST_SORT';
export const CONFIG_LIST_PER_PAGE = 'CONFIG_LIST_PER_PAGE';
export const CONFIG_LIST_COLUMN_WIDTH = 'CONFIG_LIST_COLUMN_WIDTH';
export const CONFIG_PIVOT_FIELD_CHOOSER_SETTING =
  'CONFIG_PIVOT_FIELD_CHOOSER_SETTING';
export const WEB_SETTING_ITEMS = 'webSettingItems';
export const RICH_TEXT_EDITOR_DEFAULT_FONT = 'RICH_TEXT_EDITOR_DEFAULT_FONT';
export const DASHBOARD_CALENDAR_REPORT_ADDRESS = 'DASHBOARD_CALENDAR_REPORT_ADDRESS';
export const USER_MOUSE_MOVE = 'mousemove';
export const USER_LOG_OUT = 'logout';
export const USER_LOG_IN = 'login';

export const ROW_STATE_COLOR_FILTER = {
  KEY: 'RowStateColor',
  OPERATOR: '=',
};

export const CARTABLE_COLLAPSE_STATE = 'CARTABLE_COLLAPSE_STATE';

export const CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER =
  'CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER';
export const LOGIN_LOGO_PATH_NAME = 'LOGIN_LOGO_PATH_NAME';
declare const window: Window &
  typeof globalThis & {
    configFile: any;
  };

const defaultValue = {
  [API_URL]: localStorage.getItem('customApiUrl')
    ? localStorage.getItem('customApiUrl')
    : window.configFile?.API_URL,
  [RICH_TEXT_EDITOR_DEFAULT_FONT]: window.configFile?.RICH_TEXT_EDITOR_DEFAULT_FONT,
  [DASHBOARD_CALENDAR_REPORT_ADDRESS]:
    window.configFile?.DASHBOARD_CALENDAR_REPORT_ADDRESS,
  [DISABLE_CHAT_MODULE]: window.configFile?.DISABLE_CHAT_MODULE,
  [DISABLE_MAIL_AND_TODO_MODULE]: window.configFile?.DISABLE_MAIL_AND_TODO_MODULE,
  [IN_ACTIVE_LOGIN_USER_TIMEOUT]: window.configFile?.IN_ACTIVE_LOGIN_USER_TIMEOUT,
  [LOGIN_LOGO_PATH_NAME]: window.configFile?.LOGIN_LOGO_PATH_NAME,
  [WEB_SOCKET_API_URL]: window.configFile?.WEB_SOCKET_URL,
  [API_NAME]: window.configFile?.REACT_APP_API_NAME,
  [USER_SETTING_VERSION]: 'v1',
  [CONFIG_ROUTE_PREFIX]: process.env.REACT_APP_ROUTE_PREFIX,
  [API_VERSION]: 'v2',
  [HEADER_HEIGHT]: 50,
  [HEADER_HEIGHT_XS]: 40,
  [CONFIG_CELL_WIDTH]: 200,
  [CONFIG_LOCALE]: 'fa',
  [CONFIG_THEME_DIR]: 'rtl',
  [CONFIG_CALENDAR]: 'jalali',
  [IS_ADMIN_USER]: false,
  [CONFIG_PROFILE_SETTING]: null,
  [USER_WAREHOUSE_ID]: 0,
  [CONFIG_DRAWER_MENU_IS_OPEN]: true,
  [CONFIG_FIXED_HEADER_PARAMS]: process.env.REACT_APP_FIXED_HEADER_PARAMS,
  [CONFIG_FIXED_MENU]: process.env.REACT_APP_FIXED_MENU,
  [CONFIG_FIXED_RESOURCE]: process.env.REACT_APP_FIXED_RESOURCE,
  [CONFIG_DISABLE_FETCH_USER_SETTING]:
    !!process.env.REACT_APP_DISABLE_FETCH_USER_SETTING,
  [CONFIG_DASHBOARD_ADVERTISEMENT_RESOURCE]:
    process.env.REACT_APP_DASHBOARD_ADVERTISEMENT_RESOURCE,
  [CONFIG_PALETTE_COLORS]: process.env.REACT_APP_PALETTE_COLORS
    ? process.env.REACT_APP_PALETTE_COLORS
    : {},
  [CONFIG_IS_BOTTOM_MENU_ENABLED]: !!process.env.REACT_APP_IS_BOTTOM_MENU_ENABLED,
};

/**
 *
 * @param {string} str a string to parse
 * @param {boolean} logError if equals `true`, displays an error in console
 * @returns {T | null} If the `str` arg be a valid json return a parsed value that has expected type(given type),
 *  Otherwise returns `null`
 */
export const parseJSON = <T>(str: string, logError = true): T | null => {
  let parsed: T | null = null;
  try {
    parsed = JSON.parse(str) as T;
  } catch (err) {
    if (logError) {
      console.error('parseJSON: %s', str);
    }
  }
  return parsed;
};

export const localStorageGetItem = <T>(key: string): T => {
  const item: string | null = localStorage.getItem(key);
  if (item == null) return defaultValue[key];
  const parsed: T | null = parseJSON<T>(item);
  if (parsed == null) return defaultValue[key];
  else return parsed;
};

/**
 * Because use case of `getValue` is repetitive in whole project, instead of rename `getValue` to `localStorageGetItem`
 *  we use `localStorageGetItem` inside `getValue`(forgive us!!!)
 */
export const getValue = <T = any>(name: string): T => {
  return localStorageGetItem<T>(name);
};

export const setValue = (name: string, value: any): void => {
  localStorage.setItem(name, JSON.stringify(value ?? ''));
};

export const removeValue = (name: string): void => {
  localStorage.removeItem(name);
};

export const clearAllConfig = (): void => {
  localStorage.clear();
  setValue(SUB_DOMAIN, getUrlSubdomain());
};
