import { FC } from 'react';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import { useTranslate, useLocale } from 'react-admin';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import FilterListIcon from '@material-ui/icons/FilterList';

import BulkDeleteButton from '../../../container/BulkDeleteButton';
import CreateWithProcessButtonContainer from '../../../container/CreateWithProcessButtonContainer';
import ListColumnSelectDialogButton from '../../../container/ListColumnSelectDialogButton';
import PrintButtonContainer from '../../../container/PrintButtonContainer';
import QuickCreateButton from '../../../container/QuickCreateButton';
import ServiceButtonContainer from '../../../container/ServiceButtonContainer';
import { AccessPath } from '../../access-path';
import ExportButton from '../../ExportButton';
import ImportButton from '../../ImportButton';
import { useStyles } from './action-bar.style';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import AdjustSharpIcon from '@material-ui/icons/AdjustSharp';

import type { ActionBarViewProps } from './action-bar.type';
import { isModuleTable } from '../../../helper/MetaHelper';
import { FilterButton } from '../../filter-form/filter-button';
import { SavedFiltersButton } from '../../filter-form/saved-filters-button';
import { FilterSaveButton } from '../../filter-form/filter-save-button';
import { TableColorFilter } from '../../table-color-filter';
import classNames from 'classnames';

const ActionBarView: FC<ActionBarViewProps> = props => {
  const {
    toggleShowFilters,
    onDeleteSuccessCallback,
    colorFilterChangeHandler,
    onShowGridToggleClick,
    onChangeClusterModeClick,
    hasAccessPath,
    isRefreshEnabled,
    resource,
    quickCreateMustRefresh,
    hasCreate,
    processList,
    metaData,
    listColumnChoiceMustRefresh,
    isColumnChoice,
    hasDelete,
    selectedIds,
    treeCondition,
    disableDelete,
    initialData,
    isSelectedItemsCount,
    relationToolbarProps,
    isRelationMode,
    fieldListToFilter,
    filterValues,
    rootResource,
    tableRowColors,
    hideGrid,
    clusterMode,
  } = props;

  const {
    toggleRefreshButton,
    quickCreateData,
    redirect,
    disabledFieldList,
    parentInfo,
    additionalProps,
    childFieldName,
    relationFieldsForDisplay,
    allowExport,
    columnChoiceKey,
    clearSelection,
  } = relationToolbarProps ?? {};

  const classes = useStyles({
    hasSelectedItems: !!(isSelectedItemsCount && selectedIds && selectedIds.length),
    hasAccessPath,
  });
  const translate = useTranslate();
  const locale = useLocale();

  const isMultiReport =
    metaData.reportType === 'ParentChild' || metaData.reportType === 'MultiResult';

  return (
    <>
      <div className={classes.container}>
        {hasAccessPath && <AccessPath />}

        <div className={classes.actionsContainer}>
          {isRefreshEnabled && toggleRefreshButton && (
            <Tooltip
              className={classes.refreshButton}
              title={translate('grid.refresh')}
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.IconButton}
                  onClick={toggleRefreshButton}
                  disabled={!isRefreshEnabled}
                  id="refreshButton"
                >
                  <Icon fontSize="small">refresh</Icon>
                </IconButton>
              </div>
            </Tooltip>
          )}

          {onShowGridToggleClick && (
            <Tooltip
              className={classes.refreshButton}
              title={
                hideGrid ? translate('grid.showGrid') : translate('grid.hideGrid')
              }
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.IconButton}
                  onClick={onShowGridToggleClick}
                  id="refreshButton"
                >
                  {hideGrid ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
            </Tooltip>
          )}

          {onChangeClusterModeClick && (
            <Tooltip
              className={classes.refreshButton}
              title={translate('map.clusterMode')}
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.IconButton}
                  onClick={onChangeClusterModeClick}
                  id="clusterModeButton"
                >
                  {clusterMode ? <AdjustSharpIcon /> : <BlurCircularIcon />}
                </IconButton>
              </div>
            </Tooltip>
          )}

          {hasCreate && !processList.all.length && (
            <QuickCreateButton
              data={quickCreateData}
              resource={resource}
              label={translate('grid.quickCreate')}
              mustRefresh={quickCreateMustRefresh}
              redirect={redirect}
              disabledFieldList={disabledFieldList}
              parentInfo={parentInfo}
              additionalProps={additionalProps}
              relationMode={isRelationMode}
              childFieldName={childFieldName}
              refreshRelation={toggleRefreshButton}
            />
          )}

          {hasCreate && !!processList.all.length && (
            <CreateWithProcessButtonContainer
              locale={locale}
              metaData={metaData}
              resource={resource}
              activeProcessList={processList.actives}
            />
          )}

          {isRelationMode && (
            <>
              <ExportButton
                resource={resource}
                filter={filterValues}
                fields={relationFieldsForDisplay!}
                relationMode={isRelationMode}
                disabled={!allowExport}
                metaData={metaData}
              />

              <ImportButton
                resource={resource}
                metaData={metaData}
                parentResource={parentInfo?.parentResource}
                parentId={
                  parentInfo?.parentId ? Number(parentInfo?.parentId) : undefined
                }
              />
            </>
          )}

          {isColumnChoice && columnChoiceKey && metaData && resource && (
            <ListColumnSelectDialogButton
              metaData={metaData}
              locale={locale}
              resource={columnChoiceKey}
              mustRefresh={listColumnChoiceMustRefresh}
              relationMode={isRelationMode}
            />
          )}

          {Array.isArray(fieldListToFilter) && fieldListToFilter.length > 0 && (
            <SavedFiltersButton
              className={classes.filterButton}
              resource={rootResource ?? resource}
              locale={locale}
              isRelationMode={isRelationMode}
              fields={fieldListToFilter}
            />
          )}

          {typeof toggleShowFilters == 'function' && (
            <Tooltip title={translate('grid.filter')}>
              <IconButton
                id={'searchPopUpFilterButton'}
                color="primary"
                onClick={toggleShowFilters}
              >
                <Icon fontSize="small" className={'fa fa-filter'} />
              </IconButton>
            </Tooltip>
          )}

          {Array.isArray(fieldListToFilter) && fieldListToFilter.length > 0 && (
            <FilterButton
              className={classes.filterButton}
              resource={isMultiReport ? rootResource! : resource}
              locale={locale}
              fieldListToFilter={fieldListToFilter}
              saveSettings={!isRelationMode}
            />
          )}

          {Array.isArray(tableRowColors) && tableRowColors.length > 0 && (
            <TableColorFilter
              tableRowColors={tableRowColors}
              tooltipText={translate('general.tableRowColorFilter')}
              defaultText={translate('general.defaultTableRowColorFilterText')}
              colorChangeHandler={colorFilterChangeHandler}
            />
          )}

          <ServiceButtonContainer
            locale={locale}
            resource={resource}
            rootResource={rootResource}
            metaData={metaData}
            selectedIds={selectedIds}
            initialData={initialData}
            relationMode={isRelationMode}
          />

          {/* trees have not print */}
          {!treeCondition && (
            <PrintButtonContainer
              isForceEnabled={!isModuleTable(metaData)}
              locale={locale}
              resource={resource}
              selectedIds={selectedIds}
              filterValues={filterValues && filterValues}
            />
          )}

          {hasDelete && (
            <BulkDeleteButton
              undoable={false}
              basePath={`/${resource}`}
              resource={resource}
              selectedIds={selectedIds}
              bulkDeleteButtonOnViewPage
              disabled={!selectedIds.length || treeCondition || disableDelete}
              additionalProps={additionalProps}
              clearSelection={clearSelection}
              onDeleteSuccessCallback={onDeleteSuccessCallback}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ActionBarView;
